.promotions-block {

  &__inner {
    padding-bottom: 0;
  }

  .filter-area {
    margin-top: 2em;
    padding-top: 2em;
  }

  .section-hed-sm {
    font-size: 2.125em; // 34px
    margin-bottom: 0;
    
    @media(min-width: 60em) {
      font-size: 4em; // 64px
    }
  }

 
}