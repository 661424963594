.cookbook-title {
	color: $eb-blue;
	line-height: 1;
	text-transform: uppercase;
	font-family: $sans-bold;
	font-size: 2em;
	margin-bottom: .35em;
}



.cookbooks {
	list-style-type: none;
	width: 80%;
	margin: 2em auto;
	
	img {
		box-shadow: 3px 3px 20px -7px #000000;
	}
		
	.cookbook {
		margin-bottom: 3em;
		list-style-type: none;
	}
	
	@media(min-width: 680px) {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: stretch;
		align-content: space-between;
		
		.cookbook {
			width: 45%;
		}
	}	
	
	@media(min-width: 960px) {
		
		.cookbook {
			width: 30%;
		}
	}	
		
		@media(min-width: 1280px) {
		
		.cookbook {
			width: 22.5%;
		}
		
	}
}

.page-intro {
	margin: 0 auto;
	max-width: 85%;
}