.step-slide {
	$this: &;
	border: 1px solid $lt-grey;
	width: calc(100% + 5.208333vw);
	margin-left: calc(0px - 2.60416665vw);

	+ #{ $this } {
		margin-top: 1em;
	}

	&__inner {
		padding: 1em;
	}
	
	&__detail {
		text-align: left;
	}
	
	&__media {

		img {
			max-width: 100%;
		}
	}

	&__meta {
		font-family: $sans;
		color: $lt-blue;
	}
	
	&__hed {
		text-transform: none;
		font-family: $serif;
		margin: 0;
	}

	&__copy {
		font-size: 1.25em;
	}

	@media(min-width: 60em) {

		border: none;

		+ #{ $this } {
			margin-top: 0;
		}

		&__inner {
			display: flex;
			padding: 2em;

			> * {
				width: 100%;
			}

			#{ $this }--vert & { 
				flex-direction: column;
			}
		}

		&__detail {
			padding-right: 2em;
			max-width: 50%;
			margin: 0 auto;

			#{ $this }--flip-horiz & {
				padding-right: 0;
				padding-left: 2em;
			}

			#{ $this }--no-media & {
				max-width: 75ch;
			}
		}
		
		&__media {
			order: 1;
			padding-top: 1em;
			flex-shrink: 1.5;

			#{ $this }--flip-horiz & {
				order: 0;
			}

			#{ $this }--no-media & {
				max-width: 70%;
			}

			// #{ $this }--vert & {
			// 	order: 0;
			// 	margin-bottom: 2em;
			// }
		}

		&--flip-horiz {

		}
		
		&--vert {}
		
		&--vert-flip {}
	}
}