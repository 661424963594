.content-break {
  margin: 2em 0;
  
  &__content {
    border-bottom: 2px solid $xlt-grey;
    padding-bottom: 2em;
  }

  @media(min-width: 60em) {
    margin: 4em 0;

    &__title {
      margin: 0.5em 0 .85em;
    }
    &__content {
      padding-bottom: 4em;
    }
  }
}