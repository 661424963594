@charset "UTF-8";
/* Global layout 
------------------------- */
.wrap-sm,
.wrap-xsm,
.wrap-med,
.wrap-lrg {
  margin: 0 auto;
  width: 100%;
  max-width: 89.75rem;
}

.wrap-xsm {
  max-width: 60rem;
}

.wrap-sm {
  max-width: 71.5rem;
}

.wrap-lrg {
  max-width: 108rem;
}

.flex {
  list-style: none;
}
@media (min-width: 60em) {
  .flex {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .flex.promo-grid {
    align-items: stretch;
    margin-bottom: 6.5em;
  }
  .flex.promo-grid > *:not(.scoop) {
    margin: 0 0.5em;
    flex-grow: 2;
  }
  .flex.promo-grid > *:not(.scoop).wrap-med {
    margin-left: auto;
    margin-right: auto;
  }
  .flex.promo-grid.grid-col-a, .flex.promo-grid.grid-col-b {
    padding: 10em;
    border-radius: 0.5em;
  }
  .flex.promo-grid.grid-col-a:not(.margin-bottom), .flex.promo-grid.grid-col-b:not(.margin-bottom) {
    margin-bottom: 0;
  }
  .flex.promo-grid.grid-col-a .promo-block, .flex.promo-grid.grid-col-b .promo-block {
    margin: auto 0;
    flex-grow: 0;
    max-width: 30em;
  }
  .flex.promo-grid.grid-col-a {
    justify-content: flex-start;
  }
  .flex.promo-grid.grid-col-b {
    justify-content: flex-end;
  }
}
@media (max-width: 59.9375em) {
  .flex.promo-grid.grid-col-a, .flex.promo-grid.grid-col-b {
    padding-bottom: 0;
    padding-top: 39%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .flex.promo-grid.grid-col-a.bg-upload, .flex.promo-grid.grid-col-b.bg-upload {
    background-size: 100%;
    background-position: center top;
    background-repeat: no-repeat;
  }
  .flex.promo-grid.grid-col-a .promo-block, .flex.promo-grid.grid-col-b .promo-block {
    margin-bottom: 0;
  }
  .flex.promo-grid.grid-col-a {
    border-radius: 0.5em 0.5em 0.6em 0.6em;
  }
  .flex.promo-grid.promo-collapse {
    margin: 2.5em 2.60416665%;
  }
  .flex.promo-grid.promo-collapse .scoop {
    display: none;
  }
}
@media (min-width: 60em) {
  .flex.two-up {
    display: flex;
    justify-content: space-between;
    /* Fallback for IE11 */
    justify-content: space-evenly;
    max-width: 100%;
  }
}
.flex.two-up > * {
  width: 48%;
  display: inline-block;
}
@media (min-width: 60em) {
  .flex.two-up > * {
    min-width: auto;
    width: 24%;
  }
}
@supports (display: grid) {
  .flex.two-up {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .flex.two-up > * {
    max-width: 100%;
    width: auto;
    margin: 0.75%;
  }
  @media (min-width: 60em) {
    .flex.two-up {
      display: flex;
    }
  }
}
.flex.flex-even {
  justify-content: space-between;
  /* Fallback for IE11 */
  justify-content: space-evenly;
}
.flex.flex-center {
  justify-content: center;
}
.flex.flex-stretch {
  justify-content: space-between;
}
.flex.flex-start {
  justify-content: flex-start;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.section {
  padding: 2.5em 2.60416665%;
  margin: 0 auto;
  position: relative;
}
.section .section {
  padding-left: 0;
  padding-right: 0;
}
@media (min-width: 60em) {
  .section {
    padding: 2.5em 5.2083333%;
  }
}
.section.inverted {
  background: #1b3687;
}
.section.inverted .promo-block:not(.isolated) {
  padding-top: 1.5em;
}
.section.inverted .promo-block:not(.isolated) p,
.section.inverted .promo-block:not(.isolated) [class*=-hed] {
  color: #fff;
}
.section.inverted:not(.scooped-inv) .scoop {
  fill: #1b3687;
}
.section.grey {
  background-color: #f5f2f3;
}
.section.grey:not(.scooped-inv) .scoop {
  fill: #f5f2f3;
}

.inset {
  margin: 2.5em auto 4.5em auto;
  max-width: 1400px;
}
@media (min-width: 60em) {
  .inset {
    padding: 0 5.625%;
  }
}

.central {
  text-align: left;
}
.central .section-hed,
.central .content-hed {
  margin: 0;
}
@media (min-width: 60em) {
  .central {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: stretch;
  }
}
.central .central-secondary {
  padding: 2.5em 3.84615385% 0.5em 3.84615385%;
}
@media (min-width: 60em) {
  .central .central-secondary {
    min-width: 28em;
    padding: 5em 3.84615385%;
  }
}
.central .central-primary {
  padding: 2.5em 3.84615385%;
}
.central .central-primary .post-body {
  max-width: 100%;
}
@media (min-width: 60em) {
  .central .central-primary {
    padding: 5em 6.94444445%;
    width: 100%;
    max-width: 100em;
  }
}
.central.faq-central .central-secondary {
  background: #f9f7f8;
  padding: 2.5em 3.84615385% 0 3.84615385%;
  border-bottom: 1px solid #b8b0b6;
}

.video-wrap {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  height: 0;
}
.video-wrap object, .video-wrap embed, .video-wrap iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.bg-video-wrap {
  background-position: 50%;
}
@media (min-width: 60em) {
  .bg-video-wrap {
    position: relative;
  }
  .bg-video-wrap > * {
    z-index: 4;
    position: relative;
  }
  .bg-video-wrap.fade-btm {
    position: relative;
  }
  .bg-video-wrap.fade-btm::after {
    content: "";
    height: 30px;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgb(255, 255, 255));
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.bg-video {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  object-fit: cover;
  z-index: 0;
  display: none;
}
@media (min-width: 60em) {
  .bg-video {
    display: block;
  }
}

.video-page .content-forte {
  padding-top: 0 !important;
}

img[data-src] {
  opacity: 0;
  transition: opacity 0.25s ease-out;
}

img[data-src][src] {
  opacity: 1;
}

.no-js img[data-src] {
  display: none;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

a img {
  border: none;
}

body {
  width: 100%;
  overflow-x: hidden;
}
body > img {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

main {
  display: block;
}

ul,
ol {
  padding: 0;
  margin: 0;
}

img {
  max-width: 100%;
  height: auto;
}

section {
  display: block;
}

figure {
  display: block;
  margin: 0;
}

nav ul {
  list-style: none;
}

.overflow {
  overflow: hidden;
}

/* Global Micropatterns 
------------------------- */
.promo-grid {
  padding: 1.25em 0 2.5em 0;
}
@media (min-width: 60em) {
  .promo-grid {
    padding: 5em 0 0 0;
  }
}

.promo-double {
  flex-grow: 2;
  margin-left: 5%;
  align-self: flex-end;
}
@media (min-width: 60em) {
  .promo-double {
    max-width: 66%;
  }
}

.promo-block {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5em;
  margin: 0.5em 0;
  padding: 3.5em;
  width: 100%;
}
.promo-block .sub-hed {
  color: #1b3687;
  font-size: 2em;
}
.promo-block .sub-hed--lrg {
  font-size: 2.5em;
}
.promo-block.bg-blue * {
  color: #fff;
}
.promo-block .btn {
  min-width: 225px;
}
.promo-block .btn + .btn {
  margin-left: 0;
}
@media (min-width: 34.375em) {
  .promo-block .btn {
    min-width: 0;
  }
  .promo-block .btn + .btn {
    margin-left: 0.75em;
  }
}
@media (min-width: 60em) {
  .promo-block {
    border-radius: 0.425em;
  }
  .promo-block--sm {
    max-width: 31.25rem;
  }
}
.promo-block .lede {
  padding-top: 0.35em;
  margin-left: auto;
  margin-right: auto;
  width: 85%;
  max-width: 620px;
}
.promo-block p {
  line-height: 1.4;
  font-size: 1.125em;
  margin: 0.25em 0;
  padding: 0 1.5em;
  color: #1d1d1d;
}
.promo-block p + * {
  margin-top: 1em;
}
@media (min-width: 60em) {
  .promo-block p {
    font-size: 1.25em;
    line-height: 1.3;
    margin-bottom: 0;
  }
}
@media (min-width: 60em) and (max-width: 80em) {
  .promo-grid-triple .promo-block p {
    padding: 0;
  }
}
.promo-block--small-copy p {
  font-size: 18px;
}
@media (min-width: 60em) {
  .promo-block--small-copy p {
    font-size: 22px;
  }
}
.promo-block p.lede {
  font-size: 1.125em;
}
.promo-block p.lede--lrg {
  font-size: 1.5em;
}
@media (min-width: 60em) {
  .promo-block p.lede {
    font-size: 1.5em;
  }
  .promo-block p.lede--lrg {
    font-size: 1.75em;
  }
}
.promo-block > .btn {
  margin-top: 1.25em;
}
.promo-block.isolated {
  background: #f9f7f8;
  padding: 3.5em 1.25em 3.5em 1.25em;
}
.promo-block.isolated .btn-sm {
  margin-top: 1.7em;
}
.promo-block.isolated .sub-hed {
  margin-bottom: 0.3125em;
}
.promo-block.isolated a:not([class]) {
  font-weight: 700;
}
.promo-block.isolated.flex {
  max-width: 1700px;
  padding: 1.75em 1.25em 1.75em 1.25em;
}
.promo-block.isolated.revert {
  background: #1b3687;
  color: white;
}
.promo-block.isolated.revert .sub-hed {
  color: white;
  font-size: 2.5em;
}
.promo-block.isolated.revert p {
  color: white;
}
.promo-block.promo-highlight {
  margin: 1em 0 2.5em 0;
  font-size: 1.2em;
  border-radius: 0 0 0.5em 0.5em;
}
@media (min-width: 60em) {
  .promo-block.promo-highlight {
    border-radius: 0.5em;
  }
}
.promo-block.promo-highlight .callout-hed {
  font-size: 1.770833em;
}
@media (min-width: 60em) {
  .promo-block.promo-highlight .callout-hed {
    font-size: 3.333333em;
  }
}
.promo-block.promo-highlight .callout-hed-sm {
  font-size: 28px;
}
@media (min-width: 60em) {
  .promo-block.promo-highlight .callout-hed-sm {
    font-size: 48px;
  }
}
.promo-block > * {
  justify-content: space-evenly;
  padding: 0 0.5em;
  width: 100%;
}
.promo-block.inline-promo {
  padding: 2em 0;
  flex-direction: column;
}
.promo-block.inline-promo p + * {
  margin-top: 0;
}
.promo-block.inline-promo .sub-hed {
  font-size: 1.75em;
}
@media (min-width: 60em) {
  .promo-block.inline-promo .sub-hed {
    font-size: 3em;
  }
  .promo-block.inline-promo .lede {
    font-size: 2rem;
    margin-bottom: 1.75em;
  }
}
@media (min-width: 80em) {
  .promo-block.inline-promo {
    padding: 4.5em 0;
    flex-direction: row;
    justify-content: space-between;
  }
  .promo-block.inline-promo > * {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
    width: auto;
  }
  .promo-block.inline-promo .sub-hed {
    flex-shrink: 0;
  }
  .promo-block.inline-promo .lede {
    max-width: 35rem;
    margin: 0 1.5rem;
  }
}
.promo-block .join-newsletter {
  background: #f9f7f8;
}
.promo-block .join-newsletter .flex-component__item input {
  margin: 0.5em;
}

.btn,
.btn-naked,
button#addtocartbutton {
  height: unset;
  cursor: pointer;
  appearance: none;
  display: inline-block;
  text-decoration: none;
  font-weight: 700;
  margin-top: 0.75em;
}
.btn + .btn,
.btn-naked + .btn,
button#addtocartbutton + .btn {
  margin-left: 0.75em;
}
.btn:focus,
.btn-naked:focus,
button#addtocartbutton:focus {
  outline: none;
  border: 1px solid transparent;
  box-shadow: 0 0 0 4px rgba(0, 116, 179, 0.35);
}
@media (min-width: 60em) {
  .btn,
.btn-naked,
button#addtocartbutton {
    margin-top: 0;
  }
}

.btn,
button#addtocartbutton {
  color: #fff;
  background: #E42525;
  font-family: "Bureau Grot Cond Bd", sans-serif;
  text-transform: uppercase;
  border-radius: 0.25em;
  letter-spacing: 0.04em;
  border: 1px solid rgba(255, 255, 255, 0.25);
  font-size: 18px;
  padding: 0.825em 1.2em 0.65em 1.2em;
}
.btn:hover,
button#addtocartbutton:hover {
  background: #7a0505;
  color: #fff;
}
.btn:visited, .btn:focus,
button#addtocartbutton:visited,
button#addtocartbutton:focus {
  color: #fff;
}
.btn[disabled],
button#addtocartbutton[disabled] {
  background: #f9f7f8;
}
.btn-none,
button#addtocartbutton-none {
  cursor: pointer;
  border: none;
  background: transparent;
}
.btn.btn-grey,
button#addtocartbutton.btn-grey {
  background: #e0d7d9;
  color: #5a5666;
  transition: background 0.3s ease;
}
.btn.btn-grey:hover,
button#addtocartbutton.btn-grey:hover {
  background: #b8b0b6;
}
.btn svg,
button#addtocartbutton svg {
  fill: #fff;
  margin-bottom: -1px;
}
.btn.btn-sm,
button#addtocartbutton.btn-sm {
  font-size: 14px;
  padding: 0.714286em 1em;
  letter-spacing: 0.05em;
}
.btn.btn-sm.invert,
button#addtocartbutton.btn-sm.invert {
  color: #1b3687;
  border: 1px solid #1b3687;
  transition: background 0.3s ease;
}
.btn.btn-sm.invert:hover, .btn.btn-sm.invert:focus,
button#addtocartbutton.btn-sm.invert:hover,
button#addtocartbutton.btn-sm.invert:focus {
  background: #E42525;
  border: 1px solid #E42525;
  color: white;
}
.btn.btn-lrg,
button#addtocartbutton.btn-lrg {
  font-size: 22px;
}
@media (min-width: 60em) {
  .btn.btn-lrg,
button#addtocartbutton.btn-lrg {
    font-size: 24px;
  }
}
.btn.btn-icon,
button#addtocartbutton.btn-icon {
  padding-left: 2.4em;
  position: relative;
}
.btn.btn-icon .icon,
button#addtocartbutton.btn-icon .icon {
  position: absolute;
  left: 0.71em;
  top: 50%;
  transform: translateY(-50%);
}
.btn.btn-secondary,
button#addtocartbutton.btn-secondary {
  border: 1px solid rgba(255, 255, 255, 0.75);
  background: none;
}
.btn.btn-secondary:hover,
button#addtocartbutton.btn-secondary:hover {
  background: #fff;
}
.btn.btn-secondary[disabled],
button#addtocartbutton.btn-secondary[disabled] {
  background: rgba(255, 255, 255, 0.25);
}

.btn-naked {
  font-family: freight-text-pro, serif;
  font-size: 1em;
  color: #E42525;
  border: none;
}
.btn-naked:hover {
  color: #a31414;
}

#Botsonic-wrapper {
  max-height: 500px !important;
}

.tagged,
.more {
  text-decoration: none;
  font-family: "Bureau Grot Cond Bk", sans-serif;
  text-transform: uppercase;
  margin: 0.5em 0;
  font-size: 1.2em;
}

.tagged {
  color: #0074B3;
  font-family: "Bureau Grot Cond Bk", sans-serif;
}
.section.inverted .tagged {
  color: #217DAB;
}

.more {
  color: #E42525;
  font-size: 1.4em;
  font-weight: bold;
}

.detail-cta {
  color: #E42525;
  font-size: 1.1em;
  font-weight: bold;
  margin: 0;
  text-decoration: none;
}

.scooped {
  position: relative;
  padding-bottom: 3em;
  display: block;
  border-top: 1px solid transparent;
}
.scooped .scoop {
  display: block;
  overflow: hidden;
  fill: #fff;
  height: 4vw;
  /* IE11 can’t handle `height: auto` properly here. */
  max-height: 4em;
  left: 0;
  position: absolute;
  right: 0;
  top: 99.9%;
  width: 100%;
  z-index: 2;
}

.scooped-inv {
  padding-bottom: 2.5%;
}
.scooped-inv .scoop {
  top: auto;
  bottom: -1px;
  height: 4vw;
  /* See above. */
  max-height: 4em;
  left: 0;
  transform: rotate(180deg);
}

.scooped-grey .scoop {
  fill: #f5f2f3;
}

.bg-upload {
  background-size: cover;
  background-position: center center;
}

.post-date,
.post-sample {
  font-size: 1.125em;
  line-height: 1.5;
  color: #5a5666;
}

@media (min-width: 60em) {
  .post-date {
    font-size: 1.5em;
    line-height: 1.08;
  }
}

.post-sample {
  letter-spacing: 0.005em;
}
@media (min-width: 60em) {
  .post-sample {
    font-size: 1.25em;
  }
}

.faq-list {
  list-style: none;
}
.faq-list .faq {
  margin: 2em 0;
  padding: 2.25em 0 0 0;
  border-top: 1px solid #b8b0b6;
}
.faq-list .faq .faq-q {
  position: relative;
  text-align: left;
  width: 100%;
  color: #5a5666;
  font-size: 1.3em;
  padding: 0 2em 0 0;
  background: none;
  border: none;
  font-family: serif;
}
.faq-list .faq .faq-q:focus {
  outline: none;
}
.faq-list .faq .faq-q:focus .icon-toggle {
  fill: #1b3687;
  transform: rotate(45deg);
}
.faq-list .faq .faq-q .icon-toggle {
  position: absolute;
  right: 0;
  top: 0;
  width: 0.75em;
  height: 0.75em;
  display: block;
  fill: #E42525;
  transform: rotate(45deg);
  transition: transform 0.2s ease-in-out;
}
.faq-list .faq .faq-q[aria-expanded=true] {
  color: #1b3687;
}
.faq-list .faq .faq-q[aria-expanded=true] .icon-toggle {
  fill: #E42525;
  transform: rotate(0);
}
.faq-list .faq .post-body {
  max-width: 100%;
}

.cooking {
  background: url(../img/home-bg.jpg) 0 0;
  background-size: 100%;
  background-repeat: no-repeat;
  padding-top: 8.5em;
}
.cooking .scoop {
  fill: #fff;
}
.cooking .inner {
  max-width: 53.25rem;
  margin: 0 auto;
}
.cooking .cooking .recipe-thumbs {
  padding: 2.5em 0;
}

.eggland-news {
  background-color: #f5f2f3;
}
.eggland-news .scoop {
  fill: #f5f2f3;
}

.result-teaser {
  margin: 2em 0;
  padding: 2em 0 0 0;
}
.result-teaser img {
  border-radius: 0.35em;
  box-shadow: inset 0 0 0px 1px #e0d7d9;
}
@media (min-width: 60em) {
  .result-teaser {
    display: block;
  }
  .result-teaser + .result-teaser {
    border-top: 1px solid #e0d7d9;
  }
}
@media (min-width: 80em) {
  .result-teaser {
    display: flex;
  }
}
.result-teaser .teaser-copy {
  margin-top: 1em;
}
@media (min-width: 80em) {
  .result-teaser .teaser-copy {
    width: 100%;
    margin-top: 0;
  }
}
.result-teaser > * + * {
  margin: 0 2.5em;
}
@media (min-width: 60em) {
  .result-teaser > * + * {
    margin: 0 0 0 2.5em;
  }
}
.result-teaser .teaser-title {
  text-transform: none;
  letter-spacing: normal;
  font-family: freight-text-pro, serif;
  font-size: 1.25em;
  line-height: 1.2;
  margin: 0.2em auto 0.5em auto;
  max-width: 47.25rem;
}
.result-teaser .teaser-title a {
  text-decoration: none;
  color: #1b3687;
}
@media (min-width: 60em) {
  .result-teaser .teaser-title {
    font-size: 2.5em;
  }
}
.section.inverted .result-teaser {
  margin: 3.7em 0 5em;
}
.section.inverted .result-teaser .teaser-title a {
  color: #fff;
}
.section.inverted .result-teaser .post-date {
  color: #b8b0b6;
}

.grid-list {
  list-style: none;
  font-style: italic;
  line-height: 1.1;
  margin: 0 auto 17.5em auto;
  padding-top: 1em;
  text-transform: capitalize;
  position: relative;
}
.grid-list > * {
  border: 2px solid #E42525;
  color: #fff;
  font-size: 20px;
  background: rgba(228, 37, 37, 0.7);
  border-radius: 0.35em;
  padding: 1em 0 0.75em 0;
  font-family: freight-text-pro, serif;
}
@media (min-width: 60em) {
  .grid-list > * {
    font-size: 22px;
  }
}
@media (min-width: 80em) {
  .grid-list > * {
    font-size: 32px;
  }
}
.grid-list strong {
  font-family: "Bureau Grot Cond Bk", sans-serif;
  text-transform: uppercase;
  font-weight: normal;
  display: block;
  font-style: normal;
}

.home-header {
  padding: 45vh 0 40vh 0;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}
.home-header:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  background: radial-gradient(circle, rgba(0, 0, 0, 0) 15%, rgba(0, 0, 0, 0.5046219171) 82%, rgba(0, 0, 0, 0.6502801804) 100%);
  z-index: 2;
}
@media (min-width: 80em) {
  .home-header:after {
    content: "";
    position: absolute;
    top: -29%;
    right: -20%;
    background: rgba(0, 0, 0, 0);
    background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 70%);
    z-index: 2;
    height: 610px;
    width: 1500px;
    overflow: visible;
  }
}
.home-header .hero-hed {
  color: #1b3687;
  max-width: 800px;
}
@media (min-width: 66em) {
  .home-header .hero-hed {
    width: 59.1145833%;
    padding: 1em 0 0.065em 0;
  }
}
.home-header .home-hed {
  color: #fff;
  max-width: 960px;
  width: 90%;
  position: relative;
  z-index: 3;
}
@media (min-width: 60em) {
  .home-header .home-hed {
    width: 80%;
    padding: 0;
    width: 73.893229125%;
  }
}
.home-header > * {
  z-index: 2;
}
.home-header .home-bg-video {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  object-fit: cover;
  z-index: 0;
  display: none;
}
@media (min-width: 60em) {
  .home-header .home-bg-video {
    display: block;
  }
}
@media screen and (prefers-reduced-motion: reduce), (update: slow) {
  .home-header .home-bg-video {
    display: none !important;
  }
}
.home-header .scroll-trigger {
  width: 3.375rem;
  bottom: 11.644%;
  z-index: 5;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.home-header .scroll-trigger svg {
  width: 100%;
  height: auto;
}
@media (min-width: 60em) {
  .home-header .scroll-trigger {
    width: 5.375rem;
    bottom: 18.1209%;
  }
}
.home-header .btn-pause {
  display: none;
}
@media (min-width: 60em) {
  .home-header .btn-pause {
    display: block;
    position: absolute;
    bottom: 10vw;
    left: 24px;
    z-index: 4;
    width: 16px;
    height: 16px;
    border: none;
    background: none;
    overflow: hidden;
  }
  .home-header .btn-pause::before, .home-header .btn-pause::after {
    content: "";
    width: 6px;
    border-radius: 2px;
    position: absolute;
    top: 0;
    bottom: 0;
    background-color: #fff;
  }
  .home-header .btn-pause::before {
    left: 0;
  }
  .home-header .btn-pause::after {
    right: 0;
  }
  .home-header .btn-pause.paused::before {
    width: 0;
    height: 0;
    left: 4px;
    border: 8px solid transparent;
    border-left-color: #fff;
    background-color: transparent;
  }
  .home-header .btn-pause.paused::after {
    content: none;
  }
}
.home .home-header {
  padding: 39vh 0 29vh 0;
}
.home .home-header .scroll-trigger {
  bottom: 17.644%;
}
@media (min-width: 45em) {
  .home .home-header {
    padding: 36vh 0 45vh 0;
  }
  .home .home-header .scroll-trigger {
    bottom: 11.644%;
  }
}
@media (min-width: 60em) {
  .home .home-header .home-hed {
    font-size: 4.4em;
  }
  .home .home-header .scroll-trigger {
    width: 3.5rem;
    bottom: 20%;
  }
}

.hero {
  padding-bottom: 1px;
  padding-bottom: 0;
  position: relative;
  background-position: center top;
  background-repeat: no-repeat;
}

.hero .note {
  color: black;
  padding-top: 0.75em;
  opacity: 0.85;
  font-size: 16px;
  letter-spacing: 0.0125em;
  display: block;
  font-style: italic;
  position: relative;
}
.hero .note::before {
  content: "*";
}
@media (min-width: 60em) {
  .hero .note {
    text-align: right;
  }
}

@media (min-width: 960px) {
  .superior-nutrition-page .hero .note {
    top: -70px;
  }
}

.hero-lede {
  padding: 0.25em 1em 0 1em;
  position: relative;
  z-index: 2;
}
@media (min-width: 60em) {
  .hero-lede {
    padding-top: 30vh;
  }
}

.grid-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.grid-list > * {
  width: 49%;
  margin: 0.5%;
}
@media (min-width: 60em) {
  .grid-list > * {
    width: 24%;
    margin: 0.5%;
  }
}

@supports (display: grid) {
  .grid-shuffle {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    margin-bottom: 7.5em;
    margin: 1em auto 2.5em auto;
  }
  .grid-shuffle > * {
    width: auto;
  }
  .grid-shuffle > *:nth-of-type(1) {
    grid-row: 1;
    grid-column: 1;
  }
  .grid-shuffle > *:nth-of-type(2) {
    grid-row: 1;
    grid-column: 2;
  }
  .grid-shuffle > *:nth-of-type(3) {
    grid-row: 2;
    grid-column: 1;
  }
  .grid-shuffle > *:nth-of-type(4) {
    grid-row: 2;
    grid-column: 2;
  }

  @media (min-width: 60em) {
    .grid-shuffle {
      grid-gap: 1.25%;
      grid-template-columns: repeat(5, 1fr);
      grid-template-rows: repeat(5, 1fr);
    }

    .grid-shuffle > *:nth-of-type(1) {
      grid-row: 3/span 2;
      grid-column: 1;
    }

    .grid-shuffle > *:nth-of-type(2) {
      grid-row: 1/span 2;
      grid-column: 2;
    }

    .grid-shuffle > *:nth-of-type(3) {
      grid-row: 4/span 2;
      grid-column: 4;
    }

    .grid-shuffle > *:nth-of-type(4) {
      grid-row: 2/span 2;
      grid-column: 5;
    }
  }
}
@media (min-width: 60em) {
  .low-bp-only {
    display: none;
  }
}
@media (max-width: 59.9375em) {
  .high-bp-only {
    display: none;
  }
}
.page-intro .lede {
  font-size: 1.125em;
}
@media (min-width: 60em) {
  .page-intro .lede {
    font-size: 1.5em;
  }
}

.results-header {
  text-align: center;
}

.inverted .recipe-teasers {
  padding: 90px 0;
}
.recipe-teasers ul li img {
  border-radius: 0.75em;
}
.recipe-teasers .btn-secondary:hover {
  color: #1b3687;
}

[data-scrollscale] {
  transform: scale(1);
  will-change: transform;
}

.masthead {
  z-index: 9;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 5;
}
@media (max-width: 79.9375em) {
  .masthead.masthead-open {
    background: #f9f7f8;
  }
  .masthead.masthead-open ~ * {
    display: none;
  }
}
@media (min-width: 65em) {
  .masthead {
    top: -15px;
  }
}
@media (min-width: 80em) {
  .masthead.masthead-detail {
    border-bottom: 1px solid #e0d7d9;
  }
  .masthead.masthead-detail.masthead-no-border {
    border-bottom: none;
  }
}
@media (min-width: 80em) {
  .masthead {
    background: transparent;
  }
}
.masthead .masthead-inner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0 auto;
}
@media (min-width: 80em) {
  .masthead .masthead-inner {
    padding: 0.5em 5.2083333% 0 5.2083333%;
  }
}
.masthead .sitehead {
  padding: 0;
  top: 0;
  position: absolute;
  bottom: 0;
  min-height: 100%;
  display: none;
  z-index: 9999;
  background: #f9f7f8;
  width: 100%;
}
@media (min-width: 80em) {
  .masthead .sitehead {
    position: relative;
  }
}
.masthead .sitehead .sitehead-top {
  padding-bottom: 5em;
  position: relative;
  background: #fff;
}
@media (min-width: 80em) {
  .masthead .sitehead .sitehead-top {
    display: none;
    background: none;
  }
}
.masthead .sitehead .small-bp-search {
  position: absolute;
  bottom: 0.25em;
  left: 0;
  right: 0;
}
@media (min-width: 50em) {
  .masthead .sitehead .sitehead-top {
    padding-bottom: 1.5em;
  }
  .masthead .sitehead .small-bp-search {
    position: static;
    flex-grow: 2;
    z-index: 3;
  }
}
@media (min-width: 80em) {
  .masthead .sitehead {
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
}
.masthead .sitehead-top {
  display: flex;
  left: 0;
  top: 0;
  width: 100%;
  padding-top: 1em;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
}
@media (min-width: 80em) {
  .masthead .sitehead-top {
    position: static;
    flex-direction: row;
    width: auto;
    margin: 0;
  }
}
.masthead .sitehead-top .menu-toggle {
  color: #716873;
  margin-right: 1em;
  border: 1px solid #b8b0b6;
  border-radius: 0.25em;
}
@media (min-width: 80em) {
  .masthead .sitehead-top .menu-toggle {
    display: none;
  }
}
.masthead .sitehead-top .menu-toggle svg {
  margin-left: 0.75em;
}
.masthead .sitehead-top .menu-toggle .open-text,
.masthead .sitehead-top .menu-toggle .closed-text {
  align-items: center;
  display: flex;
}
.masthead .sitehead-top .menu-toggle .open-text {
  color: #716873;
}
.masthead .sitehead-top .menu-toggle .open-text svg {
  margin-left: 0.75em;
  fill: #716873;
}
.masthead .sitehead-top .menu-toggle .closed-text {
  color: #f5f2f3;
}
.masthead .sitehead-top .menu-toggle .closed-text svg {
  fill: #f5f2f3;
}
.masthead .sitehead-top .menu-toggle[aria-expanded=false] .open-text {
  display: none;
}
.masthead .sitehead-top .menu-toggle[aria-expanded=true] .closed-text {
  display: none;
}
.masthead .flyout.search-flyout {
  position: absolute;
  background: #fff;
  top: 100%;
  right: 5%;
  border: 1em solid rgb(255, 255, 255);
  border-radius: 0.5em;
}
.masthead .flyout.search-flyout input {
  border-radius: 0.25em 0 0 0.25em;
}
.masthead .flyout.search-flyout button {
  border-radius: 0 0.25em 0.25em 0;
}
.masthead .flyout.search-flyout button svg.icon-search {
  margin-bottom: -2px;
  fill: #ccc;
}
.masthead .flyout.search-flyout[aria-hidden=false] {
  display: flex;
}
@media (min-width: 80em) {
  .masthead .sitehead {
    display: flex;
    min-height: 7.5em;
    align-items: center;
    padding: 1.5em 0 0 0;
    flex-direction: row;
    justify-content: space-between;
  }
}
.masthead .eb-logo {
  display: flex;
  margin: 0;
}
.masthead .eb-logo a:focus {
  outline: none;
}
.masthead .eb-logo img,
.masthead .eb-logo svg {
  width: 100%;
  max-width: 200px;
  align-items: center;
}
@media (min-width: 80em) {
  .masthead .eb-logo img,
.masthead .eb-logo svg {
    max-width: 250px;
  }
}
.masthead a,
.masthead button {
  font-family: "Bureau Grot Cond Bk", sans-serif;
  font-size: 16px;
  color: #fff;
  text-decoration: none;
  letter-spacing: 0.0125em;
  padding: 0.75em 1em 0.65em 1em;
  text-transform: uppercase;
}
.masthead a .icon,
.masthead button .icon {
  fill: #fff;
}
.masthead a:hover, .masthead a:visited, .masthead a:focus, .masthead a:active,
.masthead button:hover,
.masthead button:visited,
.masthead button:focus,
.masthead button:active {
  color: #fff;
}
.masthead a.btn-search,
.masthead button.btn-search {
  transition: background 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0);
}
.masthead a.btn-search:hover, .masthead a.btn-search:focus,
.masthead button.btn-search:hover,
.masthead button.btn-search:focus {
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 0 10px 0 rgb(0, 0, 0);
}
.masthead button {
  background: none;
  border: none;
  appearance: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.masthead button svg {
  margin-left: 0.35em;
  margin-bottom: 0.125em;
}
.masthead .top-nav {
  width: 100%;
}
@media (min-width: 80em) {
  .masthead .top-nav {
    width: auto;
    display: flex;
    flex-direction: row;
  }
  .masthead .top-nav button svg {
    pointer-events: none;
  }
}
.masthead .masthead-nav {
  display: flex;
  flex-direction: column;
}
.masthead .nav-primary {
  order: 1;
  background: #1b3687;
}
@media (min-width: 80em) {
  .masthead .nav-primary {
    background: none;
  }
}
.masthead .nav-secondary {
  order: 2;
  display: flex;
  flex-direction: column;
  padding-bottom: 1em;
}
@media (max-width: 79.9375em) {
  .masthead .nav-secondary .search-high-bp {
    display: none;
  }
}
.masthead .nav-secondary .header-promo {
  order: 2;
  margin: 0.25em 2.5em 2em 2.5em;
}
.masthead .nav-secondary .header-promo p {
  margin: 1em 0;
}
@media (min-width: 80em) {
  .masthead .nav-secondary .header-promo {
    display: none;
  }
}
.masthead .nav-secondary .subnav {
  order: 3;
  display: flex;
}
.masthead .nav-secondary .subnav li {
  margin: 0;
}
.masthead .nav-secondary .subnav.meta-nav {
  display: block;
  padding: 0.5em 0 1.5em 0;
  justify-content: center;
  text-align: left;
}
@media (min-width: 60em) {
  .masthead .nav-secondary .subnav.meta-nav {
    display: flex;
  }
}
@media (min-width: 80em) {
  .masthead .nav-secondary .subnav.meta-nav {
    padding: 0;
  }
  .masthead .nav-secondary .subnav.meta-nav li {
    margin: 0 1em 0 0;
  }
}
.masthead .nav-secondary .subnav.meta-nav a {
  color: #E42525;
  font-size: 1.2em;
  display: inline-block;
  padding: 0.25em 1em 0.25em 1em;
}
@media (min-width: 60em) {
  .masthead .nav-secondary .subnav.meta-nav a {
    display: inline;
    padding: 0.75em 1em 0.65em 1em;
  }
}
@media (min-width: 80em) {
  .masthead .nav-secondary .subnav.meta-nav a {
    color: #fff;
    font-size: 1em;
    position: relative;
  }
  .masthead .nav-secondary .subnav.meta-nav a:after {
    content: "";
    display: block;
    opacity: 0;
    height: 2px;
    width: 0;
    background: #fff;
    position: absolute;
    bottom: 0.15em;
    left: 50%;
    right: 0;
    transform: translateX(-50%);
    transition: width 0.3s ease, opacity 0.3s ease;
  }
  .masthead .nav-secondary .subnav.meta-nav a:hover, .masthead .nav-secondary .subnav.meta-nav a:focus {
    outline: none;
  }
  .masthead .nav-secondary .subnav.meta-nav a:hover:after, .masthead .nav-secondary .subnav.meta-nav a:focus:after {
    opacity: 1;
    width: 90%;
  }
}
.masthead .nav-secondary .subnav.social-nav {
  align-items: center;
  justify-content: center;
}
@media (max-width: 79.9375em) {
  .masthead .nav-secondary .subnav.social-nav {
    order: 1;
  }
}
.masthead .nav-secondary .subnav.social-nav a {
  transition: opacity 0.1s ease;
  opacity: 1;
}
.masthead .nav-secondary .subnav.social-nav a:hover, .masthead .nav-secondary .subnav.social-nav a:focus {
  opacity: 0.7;
}
.masthead .nav-secondary .subnav.social-nav svg {
  width: 1.25em;
  fill: #fff;
}
@media (max-width: 79.9375em) {
  .masthead .nav-secondary .subnav.social-nav svg {
    fill: #1b3687;
    width: 2em;
  }
}
.masthead .masthead-inner .subnav.meta-nav a::after {
  background: #01144d;
}
@media (min-width: 80em) {
  .masthead .masthead-nav {
    display: block;
  }
  .masthead .nav-secondary,
.masthead .nav-primary {
    flex-direction: row;
    justify-content: flex-end;
  }
}
@media (min-width: 80em) {
  .masthead .nav-primary a,
.masthead .nav-primary button,
.masthead .nav-secondary a,
.masthead .nav-secondary button {
    padding: 0.25em 0.5em 0.2em 0.5em;
  }
}
.masthead .nav-primary a,
.masthead .nav-primary button {
  font-size: 19px;
  font-family: "Bureau Grot Cond Bd", sans-serif;
  display: block;
  width: 100%;
  text-align: left;
  border-top: 1px solid #01144d;
  position: relative;
}
@media (max-width: 79.9375em) {
  .masthead .nav-primary a[aria-expanded=true],
.masthead .nav-primary button[aria-expanded=true] {
    background: #01144d;
  }
}
@media (max-width: 79.9375em) {
  .masthead .nav-primary a[aria-expanded=true] svg,
.masthead .nav-primary button[aria-expanded=true] svg {
    transform: rotate(180deg);
    margin-bottom: 0.16em;
  }
}
.masthead .nav-primary a:hover, .masthead .nav-primary a:focus,
.masthead .nav-primary button:hover,
.masthead .nav-primary button:focus {
  outline: none;
}
@media (min-width: 80em) {
  .masthead .nav-primary a,
.masthead .nav-primary button {
    border-top: none;
  }
  .masthead .nav-primary a:after,
.masthead .nav-primary button:after {
    content: "";
    display: block;
    opacity: 0;
    height: 2px;
    width: 0;
    background: #E42525;
    position: absolute;
    bottom: -0.2em;
    left: 50%;
    right: 0;
    transform: translateX(-50%);
    transition: width 0.375s ease, opacity 0.375s ease;
  }
  .masthead .nav-primary a.is-hovered:after, .masthead .nav-primary a:hover:after, .masthead .nav-primary a:focus:after,
.masthead .nav-primary button.is-hovered:after,
.masthead .nav-primary button:hover:after,
.masthead .nav-primary button:focus:after {
    opacity: 1;
    width: 90%;
  }
}
.masthead .btn-search {
  margin-left: 0.65em;
}
.masthead .has-flyout {
  position: relative;
}
@media (min-width: 80em) {
  .masthead .has-flyout {
    cursor: pointer;
  }
}
.masthead .has-flyout button {
  cursor: pointer;
}
.masthead .has-flyout button::before {
  content: "";
  position: absolute;
  top: 175%;
  height: 0.5em;
  left: 0.5em;
  right: 0.5em;
  background: #E42525;
  z-index: 3;
  opacity: 0;
  transition: opacity 0.25s linear;
}
@media (min-width: 80em) {
  .masthead .has-flyout button.toggle-explanded {
    background: #01144d;
  }
  .masthead .has-flyout button.toggle-explanded::before {
    opacity: 1;
  }
}
.masthead .has-flyout .nav-flyout {
  text-align: left;
  overflow: hidden;
}
@media (min-width: 80em) {
  .masthead .has-flyout .nav-flyout {
    border-radius: 1em;
    position: absolute;
    box-shadow: 0 0 1em rgba(0, 0, 0, 0.25);
    top: 175%;
    left: 50%;
    width: 600px;
    margin-left: -300px;
  }
}
@media (min-width: 80em) {
  .masthead .has-flyout .nav-flyout .flyout-subwrap {
    align-items: stretch;
    justify-content: flex-start;
  }
  .masthead .has-flyout .nav-flyout .flyout-subwrap > * {
    width: 50%;
    padding: 1.5em 2.25em;
  }
}
.masthead .has-flyout .nav-flyout .flyout-subwrap .flyout-subnav {
  font-size: 1.2em;
  background: #01144d;
  width: 100%;
}
@media (min-width: 80em) {
  .masthead .has-flyout .nav-flyout .flyout-subwrap .flyout-subnav {
    width: 50%;
    padding: 1.5em 1em 1em 1em;
    background: #fff;
  }
}
.masthead .has-flyout .nav-flyout .flyout-subwrap .flyout-subnav a {
  font-family: "Bureau Grot Cond Bk", sans-serif;
  font-weight: normal;
  display: block;
  font-size: 1em;
  padding: 0.75em 1.15em;
}
.masthead .has-flyout .nav-flyout .flyout-subwrap .flyout-subnav a:after {
  content: none;
}
@media (min-width: 80em) {
  .masthead .has-flyout .nav-flyout .flyout-subwrap .flyout-subnav a {
    font-size: 0.95em;
    margin: 0.75em 0;
    padding: 0.25em 1.5em;
    color: #E42525;
  }
  .masthead .has-flyout .nav-flyout .flyout-subwrap .flyout-subnav a:hover, .masthead .has-flyout .nav-flyout .flyout-subwrap .flyout-subnav a:focus {
    color: #a31414;
  }
}
@media (max-width: 79.9375em) {
  .masthead .has-flyout .nav-flyout .flyout-subwrap .flyout-detail {
    display: none;
  }
}
@media (min-width: 80em) {
  .masthead .has-flyout .nav-flyout .flyout-subwrap .flyout-detail {
    background: #f5f2f3;
  }
  .masthead .has-flyout .nav-flyout .flyout-subwrap .flyout-detail .nav-hed {
    font-size: 1.4em;
    margin-bottom: 0.25em;
    margin-top: 1em;
  }
  .masthead .has-flyout .nav-flyout .flyout-subwrap .flyout-detail .nav-hed + p {
    margin-top: 0.5em;
  }
}
.masthead .has-flyout .nav-flyout .flyout-subwrap .flyout-detail p {
  color: #5a5666;
  font-size: 1.2em;
}
.masthead.masthead-detail .menu-toggle .closed-text {
  color: #1b3687;
}
.masthead.masthead-detail .menu-toggle .closed-text svg {
  fill: #1b3687;
}
@media (min-width: 80em) {
  .masthead.masthead-detail .nav-primary a,
.masthead.masthead-detail .nav-primary button {
    color: #E42525;
  }
  .masthead.masthead-detail .nav-primary a svg.icon,
.masthead.masthead-detail .nav-primary button svg.icon {
    fill: #E42525;
  }
  .masthead.masthead-detail .nav-primary a.invert,
.masthead.masthead-detail .nav-primary button.invert {
    color: #fff;
    background: #E42525;
    border-radius: 0.25em;
    margin-left: 4px;
  }
  .masthead.masthead-detail .nav-primary a.invert::after,
.masthead.masthead-detail .nav-primary button.invert::after {
    content: none;
  }
  .masthead.masthead-detail .nav-primary a.invert:hover, .masthead.masthead-detail .nav-primary a.invert:focus,
.masthead.masthead-detail .nav-primary button.invert:hover,
.masthead.masthead-detail .nav-primary button.invert:focus {
    background: #7a0505;
  }
  .masthead.masthead-detail .nav-secondary a,
.masthead.masthead-detail .nav-secondary button {
    color: #1b3687;
    border-color: #1b3687;
  }
  .masthead.masthead-detail .nav-secondary a svg.icon,
.masthead.masthead-detail .nav-secondary button svg.icon {
    fill: #1b3687;
  }
  .masthead.masthead-detail .nav-secondary a.invert span,
.masthead.masthead-detail .nav-secondary button.invert span {
    color: #fff;
    background: #1b3687;
    border-radius: 0.25em;
    padding: 0.25em 0.5em 0.2em 0.5em;
  }
  .masthead.masthead-detail .nav-secondary a.invert::after,
.masthead.masthead-detail .nav-secondary button.invert::after {
    content: none;
  }
  .masthead.masthead-detail .nav-secondary a.invert:hover span, .masthead.masthead-detail .nav-secondary a.invert:focus span,
.masthead.masthead-detail .nav-secondary button.invert:hover span,
.masthead.masthead-detail .nav-secondary button.invert:focus span {
    background: #01144d;
  }
  .masthead.masthead-detail .nav-secondary .meta-nav a,
.masthead.masthead-detail .nav-secondary .meta-nav button {
    color: #1b3687;
  }
  .masthead.masthead-detail .inner-header {
    padding-top: 17.5em;
  }
}
.masthead .small-bp-search {
  display: flex;
}
.masthead .small-bp-search form {
  flex-grow: 2;
}
.masthead .small-bp-search .seamless-input {
  width: 100%;
  padding: 1em;
}
.masthead .small-bp-search .seamless-input input {
  height: 100%;
  width: 100%;
}
.masthead .small-bp-search label {
  flex-grow: 2;
}
@media (min-width: 80em) {
  .masthead .small-bp-search {
    display: none;
  }
}

.inner-header {
  padding: 10em 0 4em;
  overflow: hidden;
  z-index: 1;
  position: relative;
  width: 100%;
}
.general-detail-page .inner-header {
  padding: 10em 0 0;
}
.inner-header [class*=-hed] {
  margin: 0 auto;
}
.inner-header.inverted {
  background: #1b3687;
}
.inner-header.inverted [class*=-hed], .inner-header.inverted p {
  color: rgba(255, 255, 255, 0.975);
  text-shadow: 0 0 25px rgba(0, 0, 0, 0.4);
}
.inner-header.inverted .breadcrumb a {
  color: rgba(255, 255, 255, 0.95);
}
@media (min-width: 80em) {
  .inner-header {
    padding: 14em 0 6em 0;
  }
}

.site-foot {
  position: relative;
  font-size: 0.8em;
  padding-top: 3.5em;
  padding-bottom: 3em;
  background: #f5f2f3;
}
@media (min-width: 60em) {
  .site-foot {
    font-size: 0.9em;
  }
}
.site-foot .eb-logo {
  max-width: 17.5em;
}
.site-foot .sub-hed {
  font-size: 3.2em;
  margin: 0 1em 0.75em 1em;
}
.site-foot > * {
  margin: 16px 0;
}
.site-foot > .foot-lede {
  margin: 24px 0;
}
.site-foot .social-nav {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding-left: 1em;
  padding-right: 1em;
  margin: 0 auto;
  row-gap: 32px;
}
@media (min-width: 60em) {
  .site-foot .social-nav {
    margin: 0 0 0 2.5em;
    row-gap: 0;
    display: flex;
  }
}
.site-foot .social-nav li {
  width: 100%;
  display: flex;
  justify-content: center;
}
@media (min-width: 60em) {
  .site-foot .social-nav li {
    margin: 0 1em;
    max-width: none;
    width: auto;
    display: block;
  }
}
.site-foot .social-nav li svg {
  width: 3.25em;
  max-height: 100%;
}
.site-foot .primary-footnav a,
.site-foot .secondary-footnav a {
  text-decoration: none;
  color: #DA1B1B;
  transition: color 0.125s linear;
}
.site-foot .primary-footnav a:hover,
.site-foot .secondary-footnav a:hover {
  color: #7a0505;
}
.site-foot .primary-footnav a.invert,
.site-foot .secondary-footnav a.invert {
  color: #fff;
  background: #E42525;
  border-radius: 0.25em;
  padding: 0.25em 0.5em 0.2em 0.5em;
}
.site-foot .primary-footnav a.invert:hover, .site-foot .primary-footnav a.invert:focus,
.site-foot .secondary-footnav a.invert:hover,
.site-foot .secondary-footnav a.invert:focus {
  color: #fff;
  background: #7a0505;
}
.site-foot .primary-footnav {
  font-size: 1.2em;
  font-weight: bold;
}
.site-foot .primary-footnav ul {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 500px;
  margin-inline: auto;
}
@media (min-width: 60em) {
  .site-foot .primary-footnav ul {
    max-width: none;
    margin-inline: unset;
  }
}
.site-foot .primary-footnav li {
  margin: 1em 0;
  max-width: 50%;
  width: 100%;
}
@media (min-width: 60em) {
  .site-foot .primary-footnav li {
    margin: 0 1.5em;
    max-width: none;
    width: auto;
  }
}
.site-foot .primary-footnav li a {
  font-size: 1.4em;
  text-transform: uppercase;
  font-family: "Bureau Grot Cond Bd", sans-serif;
}
.site-foot .secondary-footnav ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 1.5em;
  max-width: 35em;
  margin: 0 auto;
}
@media (min-width: 60em) {
  .site-foot .secondary-footnav ul {
    flex-wrap: no-wrap;
    margin: 0;
    max-width: 100%;
  }
}
.site-foot .secondary-footnav ul li {
  width: 50%;
}
@media (min-width: 60em) {
  .site-foot .secondary-footnav ul li {
    width: auto;
  }
}
.site-foot .secondary-footnav li,
.site-foot .site-meta li {
  margin: 1em 0;
}
@media (min-width: 60em) {
  .site-foot .secondary-footnav li,
.site-foot .site-meta li {
    margin: 1em 1em 0 1em;
  }
}
.site-foot .secondary-footnav li a,
.site-foot .site-meta li a {
  font-family: freight-text-pro, serif;
  font-size: 16px;
}
@media (min-width: 60em) {
  .site-foot .secondary-footnav li a,
.site-foot .site-meta li a {
    font-size: 18px;
  }
}
.site-foot .subfoot {
  display: flex;
  flex-direction: column;
  margin-top: 0;
  padding: 0 2.5em 0 2.5em;
}
@media (min-width: 60em) {
  .site-foot .subfoot {
    padding-top: 0.75em;
    margin: 2.5em auto 0 auto;
    border-top: 1px solid #f5f2f3;
  }
}
.site-foot .subfoot .subfoot-about {
  color: #1b3687;
  font-family: "Bureau Grot Cond Bk", sans-serif;
  text-transform: uppercase;
  font-size: 1.4em;
  max-width: 45em;
  width: 100%;
  margin: 0 auto;
}
.site-foot .subfoot .subfoot-about h3, .site-foot .subfoot .subfoot-about p.h3 {
  font-size: 1em;
  font-weight: bold;
}
.site-foot .subfoot .legal {
  color: #716873;
  font-size: 1em;
}
.site-foot .subfoot .legal p {
  margin: 0.25em 0;
  display: inline;
}
@media (min-width: 60em) {
  .site-foot .subfoot .legal p {
    display: block;
  }
}
.site-foot .subfoot .subfoot-phone {
  color: #DA1B1B;
  font-weight: normal;
}
.site-foot .subfoot .site-meta {
  list-style: none;
  font-size: 1.2em;
  margin: 0 1em;
}
.site-foot .subfoot .site-meta a {
  color: #DA1B1B;
  text-decoration: none;
  transition: color 0.125s linear;
}
.site-foot .subfoot .site-meta a:hover {
  color: #7a0505;
}
.site-foot .top-jumplink {
  background: #716873;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5em;
  font-family: "Bureau Grot Cond Bk", sans-serif;
  text-transform: uppercase;
  color: #fff;
  text-decoration: none;
  width: 2.5em;
  font-size: 1.2em;
  border-radius: 0.25em;
  margin: 0 auto;
}
@media (min-width: 60em) {
  .site-foot .top-jumplink {
    position: absolute;
    bottom: 0;
    right: 1em;
  }
}

[aria-hidden=false].flyout {
  display: block;
}

[aria-hidden=true].flyout {
  display: none;
}

.flyout.fadeup {
  animation: reveal 0.5s ease-in-out;
  animation-fill-mode: forwards;
}

.flyout.fadeup-reverse {
  animation: unreveal 0.5s ease-in-out;
  animation-fill-mode: forwards;
}

[data-shufflein] > * {
  opacity: 0;
}

[data-shufflein].fadeup-fallbk > * {
  opacity: 1;
}

.fadeup {
  animation: fadeup 1s ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 0.25s;
}

@media (max-width: 79.9375em) {
  .masthead .flyout.fadeup {
    animation: none;
  }
  .masthead .flyout.fadeup-reverse {
    animation: none;
  }
}

@keyframes fadeup {
  0% {
    opacity: 0;
    transform: translateY(3.5em);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes fadedown {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(3.5em);
  }
}
@keyframes reveal {
  0% {
    opacity: 0;
    margin-top: 15px;
  }
  100% {
    opacity: 1;
    margin-top: 0;
  }
}
@keyframes unreveal {
  0% {
    opacity: 1;
    margin-top: 0;
  }
  100% {
    opacity: 0;
    margin-top: 15px;
  }
}
/* Typography Styles
 ------------------------------------------------------------------------------
*/
html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
html {
  text-rendering: optimizeLegibility;
  font-family: freight-text-pro, serif;
  font-weight: 400;
  font-style: normal;
  margin: 0;
  padding: 0;
}

body {
  text-align: center;
}

/* default header styles */
h1, h2, h3, h4, h5, .product-teaser p.h5, h6 {
  padding: 0;
  margin: 0;
  color: #1b3687;
  line-height: 1;
  text-transform: uppercase;
  font-family: "Bureau Grot Cond Bd", sans-serif;
  line-height: 1;
}
.inverted h1, .inverted h2, .inverted h3, .inverted h4, .inverted h5, .inverted .product-teaser p.h5, .product-teaser .inverted p.h5, .inverted h6 {
  color: #fff;
}

h1, .h1 {
  font-size: 2.5em;
  letter-spacing: -0.01em;
}
@media (min-width: 60em) {
  h1, .h1 {
    font-size: 6em;
  }
}

h2, .h2, .title {
  font-size: 2.125em;
  letter-spacing: -0.01em;
}
@media (min-width: 60em) {
  h2, .h2, .title {
    font-size: 4em;
  }
}

h3, .h3 {
  font-size: 1.75em;
  letter-spacing: -0.005em;
}
@media (min-width: 60em) {
  h3, .h3 {
    font-size: 3em;
  }
}

h4, .h4 {
  font-size: 1.5em;
  letter-spacing: -0.000417em;
}
@media (min-width: 60em) {
  h4, .h4 {
    font-size: 2em;
    letter-spacing: -0.000625em;
  }
}

h5, .product-teaser p.h5, .h5 {
  font-size: 1.25em;
  line-height: 1.2;
}
@media (min-width: 60em) {
  h5, .product-teaser p.h5, .h5 {
    font-size: 1.5em;
    line-height: 1.166667;
  }
}

h6, .h6 {
  font-size: 1.125em;
  line-height: 1.222222;
}
@media (min-width: 60em) {
  h6, .h6 {
    font-size: 1.25em;
    line-height: 1.2;
  }
}

a {
  text-decoration: none;
  cursor: pointer;
  color: #E42525;
}
a:visited {
  color: #7a0505;
}
a:active, a:focus, a:hover {
  color: #a31414;
  text-decoration: none;
  cursor: pointer;
}

b, strong {
  font-weight: 700;
}

blockquote {
  color: #1b3687;
  line-height: 1.1;
  text-align: center;
  border: solid #f5f2f3;
  border-width: 4px 0;
  padding: 1em 0;
  margin: 1.5em 0;
  text-transform: uppercase;
  font-family: "Bureau Grot Cond Bd", sans-serif;
  font-size: 28px;
}
@media (min-width: 60em) {
  blockquote {
    font-size: 32px;
  }
}
@media (min-width: 80em) {
  .post-body blockquote {
    margin-left: -9.125rem;
    margin-right: -9.125rem;
  }
}

hr {
  margin: 3em 0;
  height: 2px;
  background: #f5f2f3;
  border: 0;
}

p.important-text {
  margin-top: 0;
  color: #E42525;
  font-family: "Bureau Grot Cond Bd", sans-serif;
  font-size: 2.4em;
}

.blue-text, .promo-block strong, .promo-block b {
  color: #1b3687;
  font-family: "Bureau Grot Cond Bd", sans-serif;
  text-transform: uppercase;
}

.title {
  font-family: "Bureau Grot Cond Bd", sans-serif;
  color: #1b3687;
}

/*
 * ------------------
 * START POST BODY 
 */
.content,
.post-body {
  /* wysiwyg styles here */
  color: #5a5666;
  line-height: 1.5;
  text-align: left;
  max-width: 53.25rem;
  margin: 0 auto;
}
.content p, .content ul, .content ol,
.content .post-lede,
.post-body p,
.post-body ul,
.post-body ol,
.post-body .post-lede {
  font-size: 1.125em;
}
@media (min-width: 60em) {
  .content p, .content ul, .content ol,
.content .post-lede,
.post-body p,
.post-body ul,
.post-body ol,
.post-body .post-lede {
    font-size: 1.25em;
  }
}
.content p, .content ul, .content ol,
.content .post-lede,
.content .content-lede,
.post-body p,
.post-body ul,
.post-body ol,
.post-body .post-lede,
.post-body .content-lede {
  margin: 1.25rem auto;
}
@media (min-width: 60em) {
  .content p + h1, .content p + h2, .content p + h3, .content p + h4, .content p + h5, .content .product-teaser p + p.h5, .product-teaser .content p + p.h5, .content p + h6, .content ul + h1, .content ul + h2, .content ul + h3, .content ul + h4, .content ul + h5, .content .product-teaser ul + p.h5, .product-teaser .content ul + p.h5, .content ul + h6, .content ol + h1, .content ol + h2, .content ol + h3, .content ol + h4, .content ol + h5, .content .product-teaser ol + p.h5, .product-teaser .content ol + p.h5, .content ol + h6,
.content .post-lede + h1,
.content .post-lede + h2,
.content .post-lede + h3,
.content .post-lede + h4,
.content .post-lede + h5,
.content .product-teaser .post-lede + p.h5,
.product-teaser .content .post-lede + p.h5,
.content .post-lede + h6,
.content .content-lede + h1,
.content .content-lede + h2,
.content .content-lede + h3,
.content .content-lede + h4,
.content .content-lede + h5,
.content .product-teaser .content-lede + p.h5,
.product-teaser .content .content-lede + p.h5,
.content .content-lede + h6,
.post-body p + h1,
.post-body p + h2,
.post-body p + h3,
.post-body p + h4,
.post-body p + h5,
.post-body .product-teaser p + p.h5,
.product-teaser .post-body p + p.h5,
.post-body p + h6,
.post-body ul + h1,
.post-body ul + h2,
.post-body ul + h3,
.post-body ul + h4,
.post-body ul + h5,
.post-body .product-teaser ul + p.h5,
.product-teaser .post-body ul + p.h5,
.post-body ul + h6,
.post-body ol + h1,
.post-body ol + h2,
.post-body ol + h3,
.post-body ol + h4,
.post-body ol + h5,
.post-body .product-teaser ol + p.h5,
.product-teaser .post-body ol + p.h5,
.post-body ol + h6,
.post-body .post-lede + h1,
.post-body .post-lede + h2,
.post-body .post-lede + h3,
.post-body .post-lede + h4,
.post-body .post-lede + h5,
.post-body .product-teaser .post-lede + p.h5,
.product-teaser .post-body .post-lede + p.h5,
.post-body .post-lede + h6,
.post-body .content-lede + h1,
.post-body .content-lede + h2,
.post-body .content-lede + h3,
.post-body .content-lede + h4,
.post-body .content-lede + h5,
.post-body .product-teaser .content-lede + p.h5,
.product-teaser .post-body .content-lede + p.h5,
.post-body .content-lede + h6 {
    margin-top: 4rem;
  }
}
.content .content-lede p,
.content .post-lede p,
.post-body .content-lede p,
.post-body .post-lede p {
  font-size: 1.166667em;
  line-height: 1.4;
  margin: 1.5em 0;
}
@media (min-width: 60em) {
  .content .content-lede p,
.content .post-lede p,
.post-body .content-lede p,
.post-body .post-lede p {
    font-size: 1.2em;
  }
}
.content ul,
.content ol,
.post-body ul,
.post-body ol {
  list-style: none;
  line-height: 1.3;
}
.content ul li,
.content ol li,
.post-body ul li,
.post-body ol li {
  margin: 1.25em 0;
  position: relative;
  padding-left: 0.85em;
}
.content ul li::before,
.content ol li::before,
.post-body ul li::before,
.post-body ol li::before {
  content: "•";
  color: #E42525;
  display: inline-block;
  position: absolute;
  top: 1px;
  left: 0;
  margin-right: 0.5em;
  font-size: 0.8em;
}
.content ul li li,
.content ol li li,
.post-body ul li li,
.post-body ol li li {
  font-size: 0.75em;
}
.content a:not([class]),
.post-body a:not([class]) {
  font-weight: 700;
}

/*
 * END POST BODY 
 * ------------------
 */
.content-area img {
  border-radius: 0.625em;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 71.5rem) {
  .content-area img {
    width: 100%;
    max-width: 71.5rem;
  }
}
.content-area * + * {
  margin-top: 1em;
}
.content-area > hr {
  margin: 2em auto;
  max-width: 1144px;
  height: 8px;
}
@media (min-width: 60em) {
  .content-area > hr {
    margin: 4em auto;
  }
}

.content img,
.round-corners {
  border-radius: 0.625em;
}

/* Global type classes
------------------------- */
.hero-hed,
.home-hed {
  font-size: 39px;
  margin: -100px auto 0;
  line-height: 1;
}
@media (min-width: 45em) {
  .hero-hed,
.home-hed {
    font-size: 82px;
    margin: 0 auto;
  }
}
@media (min-width: 70em) {
  .hero-hed,
.home-hed {
    font-size: 128px;
    margin: 0 auto;
  }
}

/*
.hero-hed,
.home-hed {
	font-size: 39px;

	@media(min-width: 45em) {
		font-size: 71px;
		margin: -1.3em auto 0;
	}

	@media(min-width: 70em) {
		font-size: 102px;
		margin: -.75em auto;
	}

	margin: -100px auto 0;
	line-height: 1;
}
*/
.section-hed,
.lede {
  margin: 0 5%;
}

.section-hed {
  font-size: 2.5em;
  margin-top: 1.25em;
  line-height: 1.1;
  margin-bottom: 0.175em;
  letter-spacing: -0.01em;
}
.content-header .section-hed {
  margin-top: 0;
}
@media (min-width: 60em) {
  .section-hed {
    font-size: 6em;
    max-width: 87.5rem;
    margin-left: auto;
    margin-right: auto;
  }
  .content-header .section-hed {
    font-size: 4em;
  }
}
@media (min-width: 80em) {
  .content-header .section-hed {
    font-size: 6em;
  }
}
.section-hed sup {
  position: relative;
  top: -1.8em;
  font-size: 0.3em;
  line-height: 0;
  vertical-align: baseline;
}

.hero-hed {
  color: #1b3687;
}

.callout-hed,
.content-hed,
.section-hed-sm {
  font-size: 2.125em;
  letter-spacing: -0.01em;
  margin-bottom: 0.5em;
}
@media (min-width: 60em) {
  .callout-hed,
.content-hed,
.section-hed-sm {
    font-size: 4em;
    margin-bottom: 0.25em;
  }
}

.callout-hed,
.content-hed {
  margin-top: 0;
}

.section-hed-sm {
  margin: 0;
}
@media (min-width: 60em) {
  .section-hed-sm {
    margin-top: 1.65em;
    margin-bottom: 1em;
  }
}

.sub-hed {
  font-size: 1.75em;
  font-weight: 700;
  letter-spacing: -0.005em;
  margin: 0.5em 0 0 0;
}
@media (min-width: 60em) {
  .sub-hed {
    font-size: 3em;
  }
}
.sub-hed sup {
  position: relative;
  top: -1.2em;
  font-size: 0.4em;
  line-height: 0;
  vertical-align: baseline;
}

.article-hed,
.product-hed,
.recipe-hed {
  letter-spacing: -0.01em;
  max-width: 22em;
  margin: 0.275em auto;
}

.article-hed {
  font-size: 2.125em;
  letter-spacing: -0.01em;
  max-width: 22em;
  margin: 0.275em auto;
}
@media (min-width: 60em) {
  .article-hed {
    font-size: 4em;
  }
}

.product-hed,
.recipe-hed {
  font-size: 2.125em;
}
@media (min-width: 60em) {
  .product-hed,
.recipe-hed {
    font-size: 4em;
  }
}

.innernav-hed,
.result-hed,
.results-hed {
  font-size: 1.5em;
  letter-spacing: -0.000417em;
  text-align: center;
  margin: 1em 0;
}
@media (min-width: 60em) {
  .innernav-hed,
.result-hed,
.results-hed {
    font-size: 2em;
    letter-spacing: -0.000625em;
    text-align: left;
    margin-right: -0.75em;
  }
}

@media (min-width: 60em) {
  .result-hed,
.results-hed {
    text-align: center;
    margin-right: 0;
  }
}

@media (min-width: 60em) {
  .result-hed {
    margin: 0.45em 0 0.5625em;
  }
}

@media (min-width: 60em) {
  .results-hed {
    margin: 1.5em 0 0.5625em;
  }
}

.label-hed,
.legend-hed {
  font-size: 1.125em;
  font-weight: bold;
}
@media (min-width: 60em) {
  .label-hed,
.legend-hed {
    font-size: 1.25em;
  }
}

.title-label {
  color: #217DAB;
  text-transform: uppercase;
  font-family: "Bureau Grot Cond Bk", sans-serif;
  font-size: 1.2em;
  font-weight: 400;
  margin-bottom: 0.45em;
}
@media (min-width: 60em) {
  .title-label {
    font-size: 1.5em;
  }
}

.lede {
  font-size: 1.5em;
  line-height: 1.3;
  color: #5a5666;
}
@media (min-width: 60em) {
  .lede {
    font-size: 2em;
  }
  .lede.lede-sm {
    font-size: 1.5em;
  }
}

.hero {
  background-color: #fff;
}
.hero svg.scoop {
  fill: #fff;
}
.hero .lede {
  margin-bottom: 20px;
  margin: 0 auto 0.8em auto;
}
@media (min-width: 60em) {
  .hero .lede {
    max-width: 750px;
  }
}

.product-info .lede {
  font-size: 1.125em;
  line-height: 1.5;
}
@media (min-width: 60em) {
  .product-info .lede {
    font-size: 1.5em;
  }
}

[class*=-hed],
[class*=-hed] a,
.lede strong {
  color: #1b3687;
  line-height: 1;
  text-transform: uppercase;
  font-family: "Bureau Grot Cond Bd", sans-serif;
}

[class*=-hed] em,
[class*=-hed] a em {
  font-style: normal;
  text-transform: none;
}

.lede strong {
  font-size: 0.875em;
}

/* Utility classes for paragraph type sizes */
/* (not sure if these will be used, they're here for reference) */
p.copy-xl, .copy-xl p {
  font-size: 1.5em;
}
@media (min-width: 60em) {
  p.copy-xl, .copy-xl p {
    font-size: 2em;
  }
}

p.copy-lrg, .copy-lrg p {
  font-size: 1.3125em;
}
@media (min-width: 60em) {
  p.copy-lrg, .copy-lrg p {
    font-size: 1.5em;
  }
}

.copy-normal {
  font-size: 1.125em;
}
@media (min-width: 60em) {
  .copy-normal {
    font-size: 1.25em;
  }
}

.copy-sm {
  font-size: 0.9375em;
}
@media (min-width: 60em) {
  .copy-sm {
    font-size: 1em;
  }
}

input, textarea {
  font-family: freight-text-pro, serif;
  font-size: 1.125em;
  padding: 0.625em 1em 0.6em 0.888em;
  border: 1px solid #716873;
  border-radius: 0.25em;
}
input:focus, textarea:focus {
  border: 2px solid #0074B3;
  box-shadow: 0 0 8px 1px rgba(0, 130, 202, 0.25);
}

input::placeholder {
  color: #716873;
  font-size: 0.85em;
}

input[type=search],
input[type=email],
input[type=text] {
  min-width: 250px;
  appearance: none;
}

label + input[type=submit] {
  margin-left: 0.5em;
}

.freeform-row input[type=checkbox],
.freeform-row input[type=radio] {
  position: relative;
  left: auto;
}

input[type=checkbox],
input[type=radio] {
  position: absolute;
  left: -99999px;
}
input[type=checkbox] + label,
input[type=radio] + label {
  padding-left: 1.4em;
  position: relative;
  color: #5a5666;
  color: #1d1d1d;
  display: inline-block;
  margin-bottom: 1em;
}
@media (min-width: 60em) {
  input[type=checkbox] + label,
input[type=radio] + label {
    font-size: 1.125em;
  }
}
input[type=checkbox] + label::before,
input[type=radio] + label::before {
  content: "";
  position: absolute;
  top: 0.2em;
  left: 0;
  width: 12px;
  height: 12px;
  border: 2px solid #716873;
}
@media (min-width: 60em) {
  input[type=checkbox] + label::before,
input[type=radio] + label::before {
    width: 14px;
    height: 14px;
  }
}
input[type=checkbox]:checked + label,
input[type=radio]:checked + label {
  color: #1b3687;
}
input[type=checkbox]:checked + label::before,
input[type=radio]:checked + label::before {
  background-color: #1b3687;
}
input[type=checkbox]:focus + label,
input[type=radio]:focus + label {
  outline: -webkit-focus-ring-color auto 5px;
}

input[type=checkbox] + label::before {
  border-radius: 4px;
}

input[type=radio] + label::before {
  border-radius: 50%;
}

input[type=checkbox]:checked + label::before {
  border-color: #1b3687;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2211%22%20height%3D%2210%22%20viewBox%3D%220%200%2011%2010%22%3E%0A%20%20%3Cpath%20id%3D%22Path_630%22%20data-name%3D%22Path%20630%22%20d%3D%22M1554.352%2C76a1%2C1%2C0%2C0%2C1-.707-.293l-3-3a1%2C1%2C0%2C0%2C1%2C1.414-1.414l2.185%2C2.184%2C5.308-7.077a1%2C1%2C0%2C1%2C1%2C1.6%2C1.2l-6%2C8a1%2C1%2C0%2C0%2C1-.729.4C1554.4%2C76%2C1554.376%2C76%2C1554.352%2C76Z%22%20transform%3D%22translate(-1550.353%20-66)%22%20fill%3D%22%23fff%22%2F%3E%0A%3C%2Fsvg%3E%0A");
  background-repeat: no-repeat;
  background-size: 11px 11px;
  background-position: 50% 50%;
}

input[type=radio]:checked + label::before {
  box-shadow: inset 0 0 0 2px #fff;
}

textarea {
  width: 100%;
}

.label-hed {
  display: block;
  margin-bottom: 0.25rem;
}

label {
  display: block;
  font-size: 1em;
  font-weight: 700;
  line-height: 1.5;
  color: #1b3687;
  margin-bottom: 0.375em;
  text-align: left;
}

label.label-reset {
  margin-bottom: 0;
  font-weight: 400;
  line-height: normal;
}

.seamless-input {
  display: flex;
}
.seamless-input label:first-of-type {
  border-right-width: 0;
}
.seamless-input label:first-of-type input {
  border-radius: 0.25em 0 0 0.25em;
}
.seamless-input label:first-of-type input:focus {
  box-shadow: inset 0 0 3px #716873;
  border: 1px solid #716873;
  outline: none;
}
.seamless-input label:first-of-type + input[type=submit], .seamless-input label:first-of-type:first-of-type + button {
  border: solid #716873;
  border-width: 1px 1px 1px 0;
  margin: 0 0 0 -1px;
  background: #fff;
}

.seamed-input {
  display: flex;
  justify-content: stretch;
  align-self: flex-end;
}
.seamed-input input {
  margin-top: auto;
  flex-grow: 2;
  margin-right: 0.5em;
}

fieldset.formshift {
  border: none;
  padding: 0 5%;
  margin: 1.5em auto;
  text-align: left;
}
fieldset.formshift legend {
  margin-bottom: 0.75em;
}
fieldset.formshift > *:not(legend) {
  display: block;
  padding-right: 1em;
  margin: 0.25em 0;
  text-align: left;
}
@media (min-width: 60em) {
  fieldset.formshift {
    padding-left: 310px;
    position: relative;
    display: inline-block;
  }
  fieldset.formshift legend {
    width: 300px;
    margin-bottom: 0.35em;
    position: absolute;
    left: 0;
    top: 0.35em;
  }
  fieldset.formshift label {
    margin-bottom: 0.25em;
  }
  fieldset.formshift > *:not(legend) {
    display: inline-block;
  }
}

/*
 * Custom Select Element
 * https://github.com/filamentgroup/select-css
 */
/* class applies to select element itself, not a wrapper element */
.select-css {
  display: block;
  font-size: 1.125em;
  font-family: freight-text-pro, serif;
  color: #01144d;
  line-height: 1.3;
  padding: 0.625em 2em 0.6em 0.888em;
  width: 100%;
  max-width: 100%;
  /* useful when width is set to anything other than 100% */
  box-sizing: border-box;
  margin: 0;
  border: 1px solid #716873;
  border-radius: 0.25em;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%227.09%22%20viewBox%3D%220%200%2012%207.09%22%3E%0A%20%20%3Cpath%20id%3D%22arrow%22%20d%3D%22M12%2C1.09%2C10.91%2C0%2C6%2C4.91%2C1.09%2C0%2C0%2C1.09l6%2C6Z%22%20fill%3D%22%235a5666%22%2F%3E%0A%3C%2Fsvg%3E%0A");
  background-repeat: no-repeat;
  /* arrow icon position (1em from the right, 50% vertical) */
  background-position: right 0.7em top 50%;
  /* icon size */
  background-size: 0.65em auto;
}

/* Hide arrow icon in IE browsers */
.select-css::-ms-expand {
  display: none;
}

/* Hover style */
.select-css:hover {
  border-color: #888;
}

/* Focus style */
.select-css:focus {
  border-color: #aaa;
  /* It'd be nice to use -webkit-focus-ring-color here but it doesn't work on box-shadow */
  box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  color: #222;
  outline: none;
}

/* Disabled styles */
.select-css:disabled, .select-css[aria-disabled=true] {
  color: #716873;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%227.09%22%20viewBox%3D%220%200%2012%207.09%22%3E%0A%20%20%3Cpath%20id%3D%22arrow%22%20d%3D%22M12%2C1.09%2C10.91%2C0%2C6%2C4.91%2C1.09%2C0%2C0%2C1.09l6%2C6Z%22%20fill%3D%22%235a5666%22%2F%3E%0A%3C%2Fsvg%3E%0A");
}

.select-css:disabled:hover, .select-css[aria-disabled=true] {
  border-color: #716873;
}

/*
 * Multi-Select Dropdown
 */
.multiselect-dropdown {
  position: relative;
  z-index: 10;
}
.multiselect-dropdown.multiselect-open {
  filter: drop-shadow(0 4px 8px rgba(0, 31, 96, 0.15));
}

.multiselect-toggle {
  appearance: none;
  border: none;
  display: block;
  border: 1px solid #716873;
  border-radius: 6px;
  font-family: freight-text-pro, serif;
  font-size: 1.125em;
  padding: 0.625em 1em 0.6em 0.888em;
  width: 100%;
  text-align: left;
  background-color: #fff;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%227.09%22%3E%3Cpath%20d%3D%22M12%201.09L10.91%200%206%204.91%201.09%200%200%201.09l6%206z%22%20fill%3D%22%235a5666%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  /* arrow icon position (1em from the right, 50% vertical) */
  background-position: right 0.7em top 50%;
  /* icon size */
  background-size: 0.65em auto;
}
.multiselect-toggle.toggle-open {
  border-radius: 6px 6px 0 0;
  border-bottom-color: #f5f2f3;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2012%207.09%22%3E%3Cpath%20d%3D%22M0%206l1.09%201.09L6%202.18l4.91%204.91L12%206%206%200z%22%20fill%3D%22%235a5666%22%2F%3E%3C%2Fsvg%3E");
}
.multiselect-toggle.toggle-open + .multiselect-options {
  height: auto;
}

.multiselect-inner {
  background-color: #fff;
  border: 1px solid #716873;
  border-top: none;
  border-radius: 0 0 6px 6px;
  overflow: hidden;
}
@media (min-width: 60em) {
  .multiselect-inner {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
  }
}

.multiselect-options {
  height: 0;
}

.multiselect-option input + label {
  margin-bottom: 0;
  display: block;
  padding: 1em 1em 1em 2.8em;
  font-weight: 400;
  line-height: normal;
}
.multiselect-option input + label::before {
  top: 1.1875em;
  left: 1em;
}
.multiselect-option input:focus + label {
  background-color: #f5f2f3;
}
.multiselect-option + .multiselect-option {
  border-top: 1px solid #f5f2f3;
}
.multiselect-option:hover {
  background-color: #f5f2f3;
}

.a11y-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.a11y-only.focusable:active,
.a11y-only.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

.text-center {
  text-align: center;
}

/* Spacing Utility Classes - (uncomment and use as needed for overrides) */
.marg-top-0 {
  margin-top: 0 !important;
}

.marg-btm-0 {
  margin-bottom: 0 !important;
}

.marg-top-8 {
  margin-top: 0.5rem !important;
}

.marg-btm-8 {
  margin-bottom: 0.5rem !important;
}

.marg-btm-24 {
  margin-bottom: 1.5rem !important;
}

.marg-top-48 {
  margin-top: 3rem !important;
}

.marg-btm-32 {
  margin-bottom: 2rem !important;
}

.marg-top-32 {
  margin-top: 2rem !important;
}

.marg-btm-48 {
  margin-bottom: 3rem !important;
}

.marg-btm-64 {
  margin-bottom: 4rem !important;
}

.marg-btm-96 {
  margin-bottom: 6rem !important;
}

.marg-top-200 {
  margin-top: 4rem;
}
@media (min-width: 60em) {
  .marg-top-200 {
    margin-top: 12.5rem;
  }
}

.marg-40-72 {
  margin-top: 2.5rem !important;
  margin-bottom: 4.5rem !important;
}

.pad-top-0 {
  padding-top: 0 !important;
}

.pad-top-16 {
  padding-top: 1rem !important;
}

.pad-rt-0 {
  padding-right: 0 !important;
}

.pad-btm-0 {
  padding-bottom: 0 !important;
}

/* Visibility Classes Based on Screen Width */
/* (Should not be used if content needs to be available to assistive technology) */
@media screen and (max-width: 0em), screen and (min-width: 60em) {
  .sm-screen-only {
    display: none !important;
  }
}
@media screen and (max-width: 60em) {
  .lrg-screen-only {
    display: none !important;
  }
}
.center {
  text-align: center;
}

.about-page main {
  padding-top: 142px;
}
@media (min-width: 65em) {
  .about-page main {
    padding-top: 98px;
  }
}
@media (min-width: 80em) {
  .about-page main {
    padding-top: 112px;
  }
}

.contact-page .promo-block {
  margin-top: 3em;
}
@media (min-width: 60em) {
  .contact-page .promo-block {
    margin-top: 6em;
  }
}
.contact-page main {
  padding-top: 142px;
}
@media (min-width: 65em) {
  .contact-page main {
    padding-top: 98px;
  }
}
@media (min-width: 80em) {
  .contact-page main {
    padding-top: 112px;
  }
}

.contact-form {
  padding: 2em;
}
.contact-form .title {
  padding: 1em 1em 0.9em 1em;
  width: 100%;
}
.contact-form hr {
  margin: 0 0 2em 0;
  height: 4px;
}
.contact-form .row {
  padding: 0 1em;
  width: 100%;
}
@media (min-width: 60em) {
  .contact-form .row {
    padding: 0 4em;
  }
}
.contact-form.search-filters .col {
  max-width: none;
}
.contact-form.search-filters .submit-wrap {
  border-top: none;
}
.contact-form__checkboxes input[type=checkbox] + label {
  display: block;
}
@media (min-width: 60em) {
  .contact-form {
    padding: 5em;
  }
}

.engage-and-save-page main {
  padding-top: 142px;
}
@media (min-width: 65em) {
  .engage-and-save-page main {
    padding-top: 98px;
  }
}
@media (min-width: 80em) {
  .engage-and-save-page main {
    padding-top: 112px;
  }
}

.exceptional-quality-page main {
  padding-top: 142px;
}
@media (min-width: 65em) {
  .exceptional-quality-page main {
    padding-top: 98px;
  }
}
@media (min-width: 80em) {
  .exceptional-quality-page main {
    padding-top: 112px;
  }
}

.food-service-page main {
  padding-top: 142px;
}
@media (min-width: 65em) {
  .food-service-page main {
    padding-top: 98px;
  }
}
@media (min-width: 80em) {
  .food-service-page main {
    padding-top: 112px;
  }
}

.general-detail-page .inner-header {
  padding-top: 9em;
}

.home main {
  padding-top: 142px;
}
@media (min-width: 65em) {
  .home main {
    padding-top: 98px;
  }
}
@media (min-width: 80em) {
  .home main {
    padding-top: 112px;
  }
}

.list-template main {
  padding-top: 142px;
}
@media (min-width: 65em) {
  .list-template main {
    padding-top: 98px;
  }
}
@media (min-width: 80em) {
  .list-template main {
    padding-top: 112px;
  }
}

.product-detail-page img,
.recipe-detail-page img {
  border-radius: 0.75em;
}
.product-detail-page .inner-header,
.recipe-detail-page .inner-header {
  padding-top: 124px;
  padding-bottom: 0;
}
.product-detail-page .inner-header .section,
.recipe-detail-page .inner-header .section {
  padding-bottom: 20px;
}
.product-detail-page .inner-header + .section.scooped,
.recipe-detail-page .inner-header + .section.scooped {
  padding-top: 0;
}
@media (min-width: 60em) {
  .product-detail-page .inner-header,
.recipe-detail-page .inner-header {
    padding-top: 174px;
  }
}

.products-list-page main {
  padding-top: 142px;
}
@media (min-width: 65em) {
  .products-list-page main {
    padding-top: 98px;
  }
}
@media (min-width: 80em) {
  .products-list-page main {
    padding-top: 112px;
  }
}

.recipes-list-page main {
  padding-top: 142px;
}
@media (min-width: 65em) {
  .recipes-list-page main {
    padding-top: 98px;
  }
}
@media (min-width: 80em) {
  .recipes-list-page main {
    padding-top: 112px;
  }
}

.superior-nutrition-page main {
  padding-top: 142px;
}
@media (min-width: 65em) {
  .superior-nutrition-page main {
    padding-top: 98px;
  }
}
@media (min-width: 80em) {
  .superior-nutrition-page main {
    padding-top: 112px;
  }
}

.video-library-page main {
  padding-top: 142px;
}
@media (min-width: 65em) {
  .video-library-page main {
    padding-top: 98px;
  }
}
@media (min-width: 80em) {
  .video-library-page main {
    padding-top: 112px;
  }
}

.frame {
  --w: 16;
  --h: 9;
  padding-bottom: calc(var(--h) / var(--w) * 100%);
  position: relative;
}

.frame > *,
.frame > picture > * {
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.frame > img,
.frame > video,
.frame > iframe,
.frame > picture > * {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/*! Flickity v2.2.1
https://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus {
  outline: none;
}

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* draggable */
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* ---- flickity-button ---- */
.flickity-button {
  position: absolute;
  background: hsla(0deg, 0%, 100%, 0.75);
  border: none;
  color: #333;
}

.flickity-button:hover {
  background: white;
  cursor: pointer;
}

.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19F;
}

.flickity-button:active {
  opacity: 0.6;
}

.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none;
}

.flickity-button-icon {
  fill: currentColor;
}

/* ---- previous/next buttons ---- */
.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  /* vertically center */
  transform: translateY(-50%);
}

.flickity-prev-next-button.previous {
  left: 10px;
}

.flickity-prev-next-button.next {
  right: 10px;
}

/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}

.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px;
}

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
}

/* ---- page dots ---- */
.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}

.flickity-rtl .flickity-page-dots {
  direction: rtl;
}

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer;
}

.flickity-page-dots .dot.is-selected {
  opacity: 1;
}

.awards {
  margin-top: 4em;
}
@media (min-width: 36em) {
  .awards {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: center;
    align-items: baseline;
  }
  .awards .award {
    width: 50%;
  }
}
@media (min-width: 80em) {
  .awards .award {
    width: 29%;
  }
}

.award {
  padding: 0 2% 4em 2%;
}
.award img {
  max-width: 100%;
  width: auto;
}
.award time {
  font-size: 1.4em;
  color: #E42525;
  font-family: "Bureau Grot Cond Bd", sans-serif;
}

.bg-white {
  background: #fff;
}

.bg-grey {
  background: #f5f2f3;
}

.bg-blue {
  background: #1b3687;
  color: #fff;
}

.breadcrumb {
  list-style: none;
  margin: 1.5em;
  display: none;
}
@media (min-width: 60em) {
  .breadcrumb {
    display: flex;
  }
}
.breadcrumb li + li {
  border-left: 2px solid rgba(245, 242, 243, 0.65);
  margin-left: 1em;
  padding-left: 1em;
}
.breadcrumb a {
  color: #5a5666;
  text-transform: uppercase;
  font-family: "Bureau Grot Cond Bk", sans-serif;
  font-size: 1.15em;
  text-decoration: none;
  letter-spacing: 0.05em;
}
.breadcrumb a[aria-current=page] {
  color: #0074B3;
}

.carousel-filters {
  position: relative;
  background-image: linear-gradient(to top, #e0d7d9 2px, transparent 2px);
  height: 9.75rem;
}
@media (min-width: 60em) {
  .carousel-filters {
    height: 13.625rem;
  }
}
.section .carousel-filters {
  margin: 0 -2.60416665% 0 0;
}
@media (min-width: 60em) {
  .section .carousel-filters {
    margin: 0 -5.2083333% 0 0;
  }
}
.carousel-filters input[type=radio] + label {
  padding: 0;
  margin: 0;
  display: block;
}
.carousel-filters input[type=radio] + label::before, .carousel-filters input[type=radio] + label::after {
  content: none;
}
.carousel-filters a:focus,
.carousel-filters input:focus,
.carousel-filters input[type=radio]:focus + label {
  outline: none;
}
.carousel-filters [data-slide-indicator] {
  width: 9rem;
  transition: transform 0.45s cubic-bezier(0.17, 0.67, 0.72, 1.15);
}
@media (min-width: 60em) {
  .carousel-filters [data-slide-indicator] {
    width: 15rem;
  }
}
.carousel-filters .carousel-filter {
  display: inline-block;
  width: 9rem;
  height: 100%;
}
@media (min-width: 60em) {
  .carousel-filters .carousel-filter {
    width: 15rem;
  }
}
.carousel-filters .carousel-filter .filter-hed {
  font-weight: 700;
  display: block;
  line-height: 1.222222;
  text-align: center;
}
@media (min-width: 60em) {
  .carousel-filters .carousel-filter .filter-hed {
    font-size: 1.111111em;
    line-height: 1.4;
  }
}
.carousel-filters .flickity-button {
  width: 2.5rem;
  height: calc(100% - 1.2rem);
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
  border-radius: 0;
  color: #E42525;
}
.carousel-filters .flickity-button:disabled {
  display: none;
}
.carousel-filters .flickity-button:active {
  opacity: 1;
}
.carousel-filters .flickity-button.next {
  right: 0;
  border-left: 2px solid #e0d7d9;
}
.carousel-filters .flickity-button.previous {
  left: 0;
  border-right: 2px solid #e0d7d9;
}

.compare-table {
  width: 100%;
  border-collapse: collapse;
  margin: 5em 0;
}
.compare-table tr td, .compare-table tr th {
  position: relative;
  padding: 2em 1em 1.5em 1em;
  font-family: "Bureau Grot Cond Bd", sans-serif;
  font-size: 2em;
  color: #7E7277;
  text-transform: uppercase;
}
.compare-table tr td:first-child, .compare-table tr th:first-child {
  width: 0;
  padding: 0;
  font-size: 1em;
}
.compare-table tr td:first-child span, .compare-table tr th:first-child span {
  font-family: freight-text-pro, serif;
  text-align: left;
  color: #1d1d1d;
  font-style: italic;
  text-transform: none;
  font-weight: normal;
  position: absolute;
  bottom: 96px;
  left: 22vw;
  right: 0;
  text-align: center;
  width: 300px;
}
.compare-table tr td:nth-child(2), .compare-table tr th:nth-child(2) {
  color: #E42525;
}
.compare-table > tbody > tr {
  border-bottom: 1px solid #f5f2f3;
}
.compare-table > thead {
  border-bottom: 1px solid #b8b0b6;
}
.compare-table > thead > tr > th {
  font-size: 1.4em;
  padding: 0.75em 1em;
  width: 50%;
}
.compare-table > thead > tr > th:first-child {
  font-weight: normal;
}
.compare-table > thead > tr > th:first-child span {
  top: -2em;
}
.compare-table > thead > tr > th:nth-child(2) {
  color: #1b3687;
}
@media (min-width: 36em) {
  .compare-table tr td, .compare-table tr th {
    padding: 0.6em 1em 0.6em 1em;
    width: auto;
  }
  .compare-table tr td:first-child, .compare-table tr th:first-child {
    width: 40%;
    padding: 0.6em 1em 0.6em 1em;
    text-align: left;
    font-size: 1.35em;
  }
  .compare-table tr td:first-child span, .compare-table tr th:first-child span {
    position: static;
  }
  .compare-table > tbody > tr {
    border-bottom: none;
  }
  .compare-table > tbody > tr:hover {
    background: #f9f7f8;
  }
  .compare-table > thead > tr > th {
    width: 30%;
  }
}

.filter-area-title {
  margin: 4rem 0 4.5rem;
}

.content-card {
  margin-bottom: 2rem;
}
.content-card > * {
  width: 100%;
  display: block;
  text-align: center;
  margin-bottom: 0.5em;
}
.content-card--article .content-card, .content-card--article h3, .content-card--article h4, .content-card--article p {
  text-align: left;
}
.content-card--article .content-card-title {
  font-family: freight-text-pro, serif;
  text-transform: none;
  margin-top: 0.5em;
  margin-bottom: 0.25em;
  padding-inline: 0;
}
.content-card--article p {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
.content-card--article .more {
  font-size: 1em;
  text-align: left;
}
@media (min-width: 60em) {
  .content-card--article {
    margin-bottom: 0;
  }
}

.content-card-media {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  height: 44vw;
}
.content-card-media:before {
  content: "";
  background: #1d1d1d;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  transition: opacity 0.25s ease-out;
}
.content-card-media:hover:before {
  opacity: 0.25;
}
.content-card-media img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
.content-card-media .icon {
  width: 96px;
  height: 96px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.content-card--article .content-card-media {
  height: unset;
  border: 1px solid #0074B3;
}
.content-card--article .content-card-media .frame {
  --w: 1120;
  --h: 666;
}

.content-card-title {
  padding: 0 0.78rem;
}

@media (min-width: 60em) {
  .filter-area {
    max-width: 1728px;
    margin: 0 auto;
  }
  .filter-area__results {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-around;
  }
  .filter-area__results > * {
    margin: 0 0 3em;
    width: calc(33.3333333333% - 16px);
  }

  .content-card-media {
    height: 325px;
  }
}
@media (min-width: 80em) {
  .filter-area__results--4-up {
    justify-content: space-around;
  }
  .filter-area__results--4-up > * {
    width: calc(25% - 18px);
  }
  .filter-area__results--4-up .content-card-media {
    height: 240px;
  }
}
.content-break {
  margin: 2em 0;
}
.content-break__content {
  border-bottom: 2px solid #f5f2f3;
  padding-bottom: 2em;
}
@media (min-width: 60em) {
  .content-break {
    margin: 4em 0;
  }
  .content-break__title {
    margin: 0.5em 0 0.85em;
  }
  .content-break__content {
    padding-bottom: 4em;
  }
}

.content-forte {
  padding: 8em 0 6em;
  margin: 0 auto;
}
.inverted .content-forte {
  color: #fff;
}
.content-forte > *:not(.flex) {
  display: block;
  text-align: center;
  margin-bottom: 1.25rem;
}
.content-forte .social-nav a {
  width: 31px;
}

.content-header {
  padding-top: 0.75rem;
  padding-bottom: 1.5625rem;
}
.content-header .lede {
  max-width: 71.5rem;
  margin: 0 auto;
}
.content-header .lede.narrow {
  max-width: 53.25rem;
}
.content-header .page-intro {
  font-size: 21px;
}
@media (min-width: 960px) {
  .content-header .page-intro {
    font-size: 24px;
  }
}
@media (min-width: 60em) {
  .content-header {
    padding-top: 5.625rem;
  }
}

.cookbook-title {
  color: #1b3687;
  line-height: 1;
  text-transform: uppercase;
  font-family: "Bureau Grot Cond Bd", sans-serif;
  font-size: 2em;
  margin-bottom: 0.35em;
}

.cookbooks {
  list-style-type: none;
  width: 80%;
  margin: 2em auto;
}
.cookbooks img {
  box-shadow: 3px 3px 20px -7px #000000;
}
.cookbooks .cookbook {
  margin-bottom: 3em;
  list-style-type: none;
}
@media (min-width: 680px) {
  .cookbooks {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
    align-content: space-between;
  }
  .cookbooks .cookbook {
    width: 45%;
  }
}
@media (min-width: 960px) {
  .cookbooks .cookbook {
    width: 30%;
  }
}
@media (min-width: 1280px) {
  .cookbooks .cookbook {
    width: 22.5%;
  }
}

.page-intro {
  margin: 0 auto;
  max-width: 85%;
}

.disqus__intro {
  margin-bottom: 1rem;
}
.disqus__intro p {
  margin-top: 0;
}
.disqus .recipe-hed {
  margin-top: 1.25em;
}
.disqus #disqus_thread a {
  color: #1b3687;
}
@media (min-width: 60em) {
  .disqus__intro {
    font-size: 18px;
  }
  .disqus__inner {
    display: flex;
    max-width: 960px;
  }
  .disqus__inner > * {
    width: 100%;
  }
}

.mc-modal-bg {
  opacity: 0.65 !important;
}

.filter-results {
  padding-top: 5.625em;
  position: relative;
}
.filter-results .panel-enter,
.filter-results .panel-exit {
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}
.filter-results .panel-enter {
  animation-name: fadeup;
  animation-duration: 0.35s;
  opacity: 0;
  transform: translateY(3.5em);
}
.filter-results .panel-exit {
  animation-name: fadedown;
  animation-duration: 0.3s;
  position: absolute;
  left: 0;
  right: 0;
  top: 5.625em;
}
@media (min-width: 60em) {
  .filter-results {
    margin: 0 -1.5rem;
  }
}

.filter-results-title {
  margin-bottom: 1.09375em;
}

.filter-results-list {
  flex-wrap: wrap;
  align-items: stretch;
}

.filter-result {
  margin: 0 0 0.5rem;
  display: block;
}
.filter-result.hide {
  display: none;
}
@media (min-width: 60em) {
  .filter-result {
    flex-basis: 33.3333333333%;
    max-width: calc(33.3333333333% - 1.5rem);
    margin: 0 0 1.5rem 1.5rem;
  }
}

/*
 * this is the flex box holy albatross: http://www.heydonworks.com/article/the-flexbox-holy-albatross-reincarnated
 */
.flex-component {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  --margin: 1rem;
  --modifier: calc(60rem - 100%);
  margin: calc(var(--margin) * -1);
}
.flex-component.join-newsletter {
  margin: 0;
}
@media (min-width: 60em) {
  .flex-component.join-newsletter {
    margin: calc(var(--margin) * -1);
  }
}
.flex-component > * {
  flex-grow: 1;
  flex-basis: calc(var(--modifier) * 999);
  margin: var(--margin);
}
.flex-component .sub-hed {
  margin-top: 0;
}
.flex-component .formshift {
  display: block;
  padding: 1em 1em 0;
  margin: 0;
  text-align: center;
}
.flex-component .formshift .legend-hed {
  width: 100%;
  position: static;
  display: block;
  margin-bottom: 0;
  white-space: nowrap;
}
@media (min-width: 60em) {
  .flex-component .formshift {
    padding: 1em;
  }
  .flex-component .formshift > :not(legend) {
    text-align: center;
    margin: 0;
  }
  .flex-component .formshift > div {
    display: inline-block;
  }
}
.flex-component label {
  display: inline-block;
}

.find-a-recipe .inset {
  padding: 0 5.625% 0 5.625%;
}
.find-a-recipe .inset div > * {
  margin: 1em auto 0;
}
.find-a-recipe label {
  margin: 0 0.5em 0 auto;
  color: #fff;
}
.find-a-recipe form {
  display: block;
}
.find-a-recipe form input {
  width: 100%;
}
@media (min-width: 60em) {
  .find-a-recipe .inset {
    padding: 3em 5.625% 1em 5.625%;
  }
  .find-a-recipe .inset div > * {
    margin: 0;
  }
  .find-a-recipe form {
    display: flex;
  }
  .find-a-recipe form input {
    width: auto;
  }
  .find-a-recipe input {
    margin: 0 0.5em 0 0;
  }
}

.footer-social {
  max-width: 500px;
  margin-inline: auto;
  margin-top: 3rem;
}
.footer-social__hed {
  margin-bottom: 1.5rem;
  font-size: 32px;
}
@media (min-width: 60em) {
  .footer-social {
    max-width: 600px;
  }
}

.hero-art {
  position: absolute;
  bottom: 0;
  z-index: 0;
  top: 0;
  right: 0;
  left: 0;
  overflow: hidden;
  background-size: cover;
}
.inner-header .hero-art:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0) 35%, rgba(0, 0, 0, 0) 65%, rgba(0, 0, 0, 0.5));
}
.hero-art--texture {
  display: none;
}
.inner-header .hero-art--texture:before {
  background-image: linear-gradient(to top, rgb(255, 255, 255), rgba(255, 255, 255, 0));
}
.hero-art div {
  background-position: center top;
  background-size: cover;
}
.hero-art video {
  min-width: 100%;
}
.hero-art img {
  object-fit: contain;
  object-position: center;
  height: 100%;
  width: 100%;
}
.inner-header .hero-art {
  z-index: 0;
  overflow: hidden;
}
.inner-header .hero-art div {
  height: 100%;
  width: 100%;
}
.inner-header .hero-art + div {
  position: relative;
  z-index: 1;
}
.inner-header .hero-art img {
  object-fit: cover;
}

.hero-slide {
  --hed-size: 34px;
  --copy-size: 16px;
  --slide-height: 100vw;
  --bottom-padding: 3rem;
  position: relative;
  height: var(--slide-height);
  width: 100%;
}
.hero-slide__media {
  position: relative;
}
.hero-slide__media .frame {
  --w: 6;
  --h: 6;
}
.hero-slide__media:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0) 35%, rgba(0, 0, 0, 0.5)), linear-gradient(to right, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0) 75%);
}
.hero-slide__detail {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 4;
  padding-bottom: var(--bottom-padding);
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
}
.hero-slide__detail__copy {
  font-size: var(--copy-size);
}
.hero-slide__wrap {
  padding: 0.5em 5.2083333% 0 5.2083333%;
  margin-inline: auto;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}
.hero-slide__content {
  width: 100%;
  border-radius: 0.5rem;
  z-index: 5;
  text-align: center;
  padding: 0.5em 5.2083333% 0 5.2083333%;
}
.hero-slide__hed {
  font-size: var(--hed-size);
  color: #fff;
  text-transform: none;
}
.hero-slide__hed sup {
  position: relative;
  top: -1.2em;
  font-size: 0.4em;
  line-height: 0;
  vertical-align: baseline;
}
.hero-slide__actions {
  margin-top: 0px;
}
.hero-slide .btn {
  font-size: 16px;
}
@media (min-width: 60em) {
  .hero-slide {
    --hed-size: 48px;
    --slide-height: min(43.75vw, 1920px);
    --bottom-padding: 4rem;
  }
  .hero-slide__media .frame {
    --w: 16;
    --h: 7;
  }
  .hero-slide__content {
    max-width: 375px;
    padding: 0;
    text-align: left;
  }
  .hero-slide__actions {
    margin-top: 32px;
  }
}
@media (min-width: 70em) {
  .hero-slide {
    --hed-size: 64px;
  }
  .hero-slide .btn {
    font-size: 24px;
  }
  .hero-slide__content {
    max-width: 475px;
  }
}
@media (min-width: 85em) {
  .hero-slide {
    --hed-size: 72px;
  }
  .hero-slide__content {
    max-width: 650px;
  }
}

.hero-slider {
  --btn-size: 3rem;
}
.hero-slider.scooped {
  padding-bottom: 0;
}
.hero-slider.scooped .scoop {
  transform: rotate(180deg);
  bottom: -1px;
  top: auto;
}
.hero-slider .flickity-button {
  display: none;
}
.hero-slider .flickity-button:disabled {
  display: none;
}
@media (min-width: 60em) {
  .hero-slider .flickity-button {
    display: block;
  }
}
.hero-slider .flickity-button {
  background: transparent;
  border-radius: 0;
  width: var(--btn-size);
  height: var(--btn-size);
}
.hero-slider .flickity-button .flickity-button-icon {
  fill: #fff;
  width: var(--btn-size);
  height: var(--btn-size);
  top: 0;
}
.hero-slider .flickity-button.flickity-prev-next-button {
  transform: none;
}
.hero-slider .flickity-button.flickity-prev-next-button.next, .hero-slider .flickity-button.flickity-prev-next-button.previous {
  top: auto;
  right: auto;
  bottom: calc(var(--btn-size) / 1.5);
  left: auto;
}
.hero-slider .flickity-button.flickity-prev-next-button.next {
  right: 50%;
  transform: translateX(100%);
}
.hero-slider .flickity-button.flickity-prev-next-button.previous {
  left: 50%;
  transform: translateX(-100%);
}
.hero-slider .flickity-button.flickity-prev-next-button .flickity-button-icon {
  left: 0;
}
@media (min-width: 31.25em) {
  .hero-slider {
    --btn-size: 5rem;
  }
}

.home-hero {
  height: 0;
  padding-bottom: 150%;
  position: relative;
  z-index: 4;
}
.home-hero figure {
  height: 0;
  padding-bottom: 100%;
  position: absolute;
  right: 0;
  left: 0;
  top: -12.1909%;
}
.home-hero figure .carton-btm,
.home-hero figure .carton-top {
  display: block;
  width: 100%;
}
.home-hero figure .carton-btm {
  top: 9.3189964158%;
}
.home-hero figure figcaption {
  position: absolute;
  top: 10%;
  left: 0;
  right: 0;
  padding-left: 6.6667%;
  padding-right: 6.6667%;
}
.home-hero figure .grid-list {
  grid-gap: 0.75rem;
}
@media (min-width: 60em) {
  .home-hero figure .carton-btm {
    top: 14.772253539%;
  }
}
.home-hero .detail {
  position: relative;
  z-index: 5;
  position: absolute;
  top: 42.5%;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}
.home-hero .detail .inner {
  max-width: 60rem;
  margin: 0 auto;
}
.home-hero .detail .section-hed {
  margin-top: 0;
}
.home-hero .detail .lede {
  margin-bottom: 2rem;
}
.home-hero .detail .note {
  position: absolute;
  top: -3em;
  right: 50%;
  transform: translateX(50%);
  font-style: italic;
}
@media (max-width: 22.5em) {
  .home-hero .detail .section-hed {
    font-size: 2em;
  }
  .home-hero .detail .lede {
    font-size: 1.2em;
  }
}
@media (min-width: 30em) {
  .home-hero {
    padding-bottom: 100%;
  }
  .home-hero .detail {
    top: 50%;
  }
}
@media (min-width: 42.5em) {
  .home-hero {
    padding-bottom: 80%;
  }
  .home-hero .detail .section-hed {
    font-size: 4em;
  }
}
@media (min-width: 60em) {
  .home-hero figure {
    padding-bottom: 63.125%;
  }
  .home-hero figure figcaption {
    top: 10.7656%;
    padding-left: 0;
    padding-right: 0;
  }
  .home-hero figure .grid-list {
    grid-gap: 1.5rem;
  }
  .home-hero .detail {
    top: 55%;
  }
  .home-hero .detail .section-hed {
    font-size: 6em;
  }
  .home-hero .detail .note {
    right: 5%;
    transform: none;
  }
}
@media (min-width: 80em) {
  .home-hero {
    padding-bottom: 63.125%;
  }
  .home-hero figure .grid-list {
    padding-left: 0;
    padding-right: 0;
  }
  .home-hero .detail {
    top: 63.125%;
  }
}
.home-hero .section-hed::before {
  content: "*when compared to ordinary eggs.";
  display: block;
  font-family: freight-text-pro, serif;
  text-transform: none;
  color: black;
  font-weight: normal;
  font-size: 15px;
  position: relative;
  top: -35px;
}
@media (min-width: 60em) {
  .home-hero .section-hed::before {
    top: -35px;
    font-size: 17px;
  }
}

html[lang|=es] .home-hero .section-hed::before {
  content: "*Cuando se compara con huevos ordinarios.";
}

.innernav {
  font-size: 1em;
  background: #1b3687;
  position: relative;
  z-index: 1;
  margin-top: -3px;
}
@media (min-width: 60em) {
  .innernav {
    margin-top: 0;
    padding: 0.5em;
  }
  .innernav .a11y-only.a11y-override {
    clip: auto;
    height: auto;
    overflow: visible;
    position: static;
    width: auto;
  }
}
@media (max-width: 59.9375em) {
  .innernav .inset {
    margin-top: 0;
    margin-bottom: 0;
  }
}
@media (min-width: 60em) {
  .innernav .innernav-toggle {
    display: none;
  }
}
.innernav ul,
.innernav ol {
  list-style: none;
  margin: 0-auto !important;
}
.innernav .inset {
  margin: 0 auto;
}
.innernav li {
  padding: 0;
}
@media (min-width: 60em) {
  .innernav li {
    padding: 0 0.5em;
  }
}
.innernav a,
.innernav button {
  padding: 1em 0 0.7em 1em;
  text-align: left;
  display: block;
}
@media (min-width: 60em) {
  .innernav a,
.innernav button {
    padding: 18px 0;
  }
}
.innernav.faq-innernav {
  background: none;
}
@media (min-width: 60em) {
  .innernav.faq-innernav {
    border-top: 2px solid #e0d7d9;
  }
}
.innernav.faq-innernav li + li {
  border-top: 1px solid #e0d7d9;
}
.innernav.faq-innernav a,
.innernav.faq-innernav button {
  color: #1b3687;
  text-align: left;
  padding: 1em 1.5em 1em 0;
}
.innernav .innernav-toggle {
  font-size: 1.2em;
}
.innernav a,
.innernav button {
  color: #fff;
  letter-spacing: 0.0125em;
  text-decoration: none;
  font-size: 1.2em;
  font-family: "Bureau Grot Cond Bd", sans-serif;
  text-transform: uppercase;
}
@media (min-width: 60em) {
  .innernav a,
.innernav button {
    font-size: 1.4em;
  }
}
.innernav button {
  background: none;
  border: none;
  position: relative;
  appearance: none;
  width: 100%;
}
.innernav button svg {
  fill: #fff;
  position: absolute;
  right: 1em;
  margin-top: -3px;
  top: 50%;
}
@media (min-width: 60em) {
  .innernav button svg {
    display: none;
  }
}
.innernav button.toggle-open svg {
  transform: rotate(180deg);
}
.innernav .nav-active a {
  position: relative;
}
.innernav .nav-active a::after {
  position: absolute;
  right: 0;
  bottom: -8px;
  left: 0;
  height: 8px;
  background: #E42525;
}
@media (min-width: 60em) {
  .innernav .nav-active a::after {
    content: "";
  }
}
.innernav.faq-innernav button {
  color: #E42525;
}
.innernav.faq-innernav button svg {
  right: 0;
  fill: #E42525;
}
@media (min-width: 60em) {
  .innernav.faq-innernav button {
    display: none;
  }
}
.innernav.faq-innernav .nav-active,
.innernav.faq-innernav .nav-active a {
  color: #E42525;
}
.innernav.bg-grey {
  background: #f5f2f3;
}
.innernav.bg-grey a {
  color: #1b3687;
}
.innernav.bg-grey button.innernav-toggle {
  color: #E42525;
}
.innernav.bg-grey button.innernav-toggle svg {
  fill: #E42525;
}

.news-alert {
  order: 0;
  position: relative;
  z-index: 10;
  color: #fff;
  display: block;
  background: #E42525;
}
@media (min-width: 65em) {
  .news-alert {
    padding: 0.5em 0;
  }
}
.news-alert .news-hed {
  color: #fff;
  display: block;
  margin: 0;
  padding-top: 1em;
  font-size: 1.1em;
}
@media (min-width: 65em) {
  .news-alert .news-hed {
    font-size: 1.4em;
    padding-top: 0;
  }
}
.news-alert p {
  margin: 0.25em 1.5em;
  font-weight: 600;
}
@media (min-width: 65em) {
  .news-alert p {
    font-size: 1.15em;
  }
}
.news-alert .btn {
  font-size: 14px;
  margin: 0.3em 0 0.75em 0;
}
@media (min-width: 65em) {
  .news-alert .btn {
    margin: 0 100px 0 0;
  }
}
.news-alert .news-close {
  position: absolute;
  right: 0.5em;
  top: 0.5em;
  height: 22px;
  width: 22px;
  background: none;
  padding: 0;
  border: none;
}
.news-alert .news-close svg {
  width: 100%;
  fill: #fff;
}
.news-alert + .masthead {
  top: 125px;
}
@media (min-width: 65em) {
  .news-alert {
    display: flex;
  }
  .news-alert .news-close {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 7.5em;
    background: none;
    border: none;
  }
  .news-alert + .masthead {
    top: 40px;
  }
}

.nutrition-info {
  max-width: 768px;
  margin-inline: auto;
}

.nutrition-facts-panel {
  background-color: #fff;
  border-radius: 0.375rem;
  margin-top: 3.375rem;
  padding: 0 1.5rem;
  color: #5a5666;
  text-align: left;
}
.nutrition-facts-panel .sub-hed {
  text-align: center;
  margin: 0 -1.5rem;
  padding: 0;
  font-size: 1.5em;
}
.nutrition-facts-panel .toggle-hed {
  position: relative;
  background: transparent;
  display: block;
  padding: 1.5rem;
  margin: 0;
  width: 100%;
  font-size: 1em;
  letter-spacing: 0;
  text-align: left;
}
.nutrition-facts-panel .toggle-hed:hover, .nutrition-facts-panel .toggle-hed:active, .nutrition-facts-panel .toggle-hed:focus {
  color: currentColor;
}
.nutrition-facts-panel .toggle-hed svg {
  fill: #E42525;
  transition: transform 0.2s ease-in-out;
  transform: rotateZ(0deg);
  position: absolute;
  right: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
}
.nutrition-facts-panel .toggle-hed.toggle-open svg {
  transform: rotateZ(180deg);
}
@media (min-width: 60em) {
  .nutrition-facts-panel .sub-hed {
    font-size: 3em;
  }
}

.nutrition-facts-detail {
  position: relative;
}
.nutrition-facts-detail::after {
  content: "";
  height: 2px;
  background-color: #e0d7d9;
  position: absolute;
  top: 0;
  right: -1.5rem;
  left: -1.5rem;
}
.nutrition-facts-detail > :first-child {
  padding-top: 2.375rem;
}
@media (min-width: 60em) {
  .nutrition-facts-detail::after {
    content: none;
  }
}

.serving-details {
  font-size: 1.125em;
  line-height: 1.5;
  margin-bottom: 1.375rem;
}
.serving-details p {
  margin: 0;
}
@media (min-width: 60em) {
  .serving-details {
    font-size: 1.25em;
  }
}

.nutrition-facts {
  border-collapse: collapse;
  width: 100%;
}
.nutrition-facts caption,
.nutrition-facts thead th {
  text-align: left;
  margin: 0;
  padding-bottom: 0.55em;
  text-transform: uppercase;
  border-bottom: 0.25rem solid #1b3687;
}
.nutrition-facts caption,
.nutrition-facts thead th,
.nutrition-facts tr td {
  font-family: "Bureau Grot Cond Bd", sans-serif;
  color: #1b3687;
  font-size: 1rem;
}
@media (min-width: 60em) {
  .nutrition-facts caption {
    font-size: 1.25rem;
  }
}
.nutrition-facts tr th,
.nutrition-facts tr td {
  padding: 0.5em 0;
}
.nutrition-facts tr td {
  text-align: right;
}
.nutrition-facts tr th {
  text-align: left;
}
.nutrition-facts tr:not(:first-child) td, .nutrition-facts tr:not(:first-child) th {
  border-top: 1px solid #e0d7d9;
}
.nutrition-facts thead th {
  font-size: 1.25rem;
  font-weight: 700;
}
.nutrition-facts thead th[scope=col] {
  text-align: right;
}
.nutrition-facts tbody th {
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 1.111111;
}
@media (min-width: 60em) {
  .nutrition-facts tbody th {
    font-size: 1.25rem;
    line-height: 1.5;
  }
}
.nutrition-facts tbody td {
  line-height: 1.5;
  font-weight: 400;
}
@media (min-width: 60em) {
  .nutrition-facts tbody td {
    font-size: 1.125rem;
  }
}
.nutrition-facts tfoot td {
  padding: 0.75rem 0;
  color: #5a5666;
  font-size: 1em;
  line-height: 1.5;
  font-weight: 400;
  font-family: freight-text-pro, serif;
  text-align: center;
}
.nutrition-facts .rule-btm {
  border-bottom: 0.25rem solid #1b3687;
}
.nutrition-facts .emphasize td, .nutrition-facts .emphasize th {
  font-weight: 700;
}
.nutrition-facts .indent {
  display: block;
}
@media (min-width: 60em) {
  .nutrition-facts .indent {
    text-indent: 3.15%;
  }
}

.nutrition-toggles {
  max-width: 986px;
}

.nutrition-toggle {
  margin: 0;
  padding: 2.25em 0 0 0;
  background: #fff;
  border: 1px solid #b8b0b6;
  border-radius: 0.375rem;
}
.nutrition-toggle__trigger {
  position: relative;
  text-align: left;
  width: 100%;
  color: #5a5666;
  padding: 0 4.5rem 0 2rem;
  background: none;
  border: none;
  font-family: serif;
}
.nutrition-toggle__trigger:focus {
  outline: none;
}
.nutrition-toggle__trigger:focus .icon-toggle {
  fill: #1b3687;
  transform: rotate(45deg);
}
.nutrition-toggle__trigger .icon-toggle {
  position: absolute;
  right: 1.5rem;
  top: 0;
  width: 1.5rem;
  height: 1.5rem;
  display: block;
  fill: #E42525;
  transform: rotate(45deg);
  transition: transform 0.2s ease-in-out;
}
.nutrition-toggle__trigger[aria-expanded=true] {
  color: #1b3687;
}
.nutrition-toggle__trigger[aria-expanded=true] .icon-toggle {
  fill: #E42525;
  transform: rotate(0);
}
@media (min-width: 60em) {
  .nutrition-toggle__trigger .icon-toggle {
    right: 2rem;
    top: 16px;
    width: 2rem;
    height: 2rem;
  }
}
.nutrition-toggle__hed {
  font-size: 1.75rem;
  text-align: left;
  padding-bottom: 2.25rem;
}
@media (min-width: 60em) {
  .nutrition-toggle__hed {
    font-size: 3rem;
  }
}
.nutrition-toggle__panel .controls {
  margin-top: 2rem;
}
.nutrition-toggle .post-body {
  max-width: 100%;
  border-top: 1px solid #b8b0b6;
}
.nutrition-toggle .nutrition-facts-panel {
  border-left: 1px solid #b8b0b6;
  border-right: 1px solid #b8b0b6;
  border-bottom: 1px solid #b8b0b6;
}

.pagination {
  display: flex;
  justify-content: center;
  max-width: 800px;
  margin: 5rem auto 0 auto;
}
.pagination li {
  list-style: none;
  border-right: 1px solid #b8b0b6;
  padding: 0rem 2.5%;
  text-align: center;
}
.pagination li svg {
  transition: fill 0.2s ease;
  transform: translateY(3px);
  fill: currentColor;
}
.pagination li:before {
  display: none;
}
.pagination li:first-child {
  margin-right: 1rem;
}
.pagination li:last-child {
  margin-left: 1rem;
}
.pagination li:first-child, .pagination li:nth-last-child(2), .pagination li:last-child {
  border: none;
}
.pagination li a {
  font-family: "Bureau Grot Cond Bd", sans-serif;
  color: #b8b0b6;
  font-size: 1.25em;
  line-height: 26px;
  transition: color 0.3s ease;
}
.pagination li a.active {
  color: #E42525;
}
.pagination li a:hover {
  color: #E42525;
}
.pagination li a:hover svg path {
  fill: #E42525;
}
@media (min-width: 60em) {
  .pagination li {
    padding: 0rem 1.7rem;
  }
}

.product-carousel {
  margin: 0 -2.60416665%;
}
.product-carousel .flickity-button {
  width: 2rem;
  height: 9.25rem;
  background: transparent;
  border-radius: 0;
}
.product-carousel .flickity-button .flickity-button-icon {
  fill: #fff;
  width: 6.25rem;
  height: 6.25rem;
  top: 0;
}
.product-carousel .flickity-button.flickity-prev-next-button {
  overflow: hidden;
}
.product-carousel .flickity-button.flickity-prev-next-button .flickity-button-icon {
  left: -2.125rem;
  top: 1.5rem;
}
.product-carousel .flickity-button.flickity-prev-next-button.previous {
  left: 0;
}
.product-carousel .flickity-button.flickity-prev-next-button.next {
  right: 0;
}
@media (min-width: 60em) {
  .product-carousel {
    margin: 0 -5.9%;
  }
  .product-carousel .flickity-button {
    width: 6.25rem;
    height: 6.25rem;
  }
  .product-carousel .flickity-button.flickity-prev-next-button {
    overflow: visible;
  }
  .product-carousel .flickity-button.flickity-prev-next-button .flickity-button-icon {
    left: 0;
  }
}
@media (min-width: 80em) {
  .product-carousel .flickity-button.flickity-prev-next-button.previous {
    left: 7.3385%;
  }
  .product-carousel .flickity-button.flickity-prev-next-button.next {
    right: 7.3385%;
  }
}

.product-carousel-item {
  width: 100%;
  opacity: 0;
  transition: opacity 0.2s linear;
}
.product-carousel-item.is-selected, .product-carousel-single .product-carousel-item {
  opacity: 1;
}
.product-carousel-item .img-wrap {
  position: relative;
  height: 0;
  width: 100%;
  padding-bottom: 100%;
}
.product-carousel-item .img-wrap img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
@media (min-width: 60em) {
  .product-carousel-item .img-wrap {
    height: auto;
    width: auto;
    padding: 0;
  }
  .product-carousel-item .img-wrap img {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
  }
}

.product-thumb-carousel {
  margin-bottom: 2rem;
}
.product-thumb-carousel.hide {
  display: none;
}
.product-thumb-carousel .flickity-button:disabled {
  display: none;
}

.product-thumb-carousel-item {
  width: 3rem;
  height: 3rem;
  border: 2px solid rgba(255, 255, 255, 0.25);
  border-radius: 0.375rem;
  overflow: hidden;
  margin: 0 0.25rem;
}
.product-thumb-carousel-item.is-nav-selected {
  border-color: rgb(255, 255, 255);
}
@media (min-width: 60em) {
  .product-thumb-carousel-item {
    border-radius: 0.75rem;
    width: 7.625rem;
    height: 7.625rem;
    margin: 0 0.75rem;
  }
}

.product-header {
  --hed-size: 28px;
  --subhed-size: 20px;
  background-color: #1b3687;
}
.product-header__inner {
  padding-top: 4.5rem;
}
.product-header .product-hed {
  font-size: var(--hed-size);
}
.product-header, .product-header h1, .product-header h2, .product-header h3 {
  color: #fff;
}
.product-header__media {
  padding-inline: 2rem;
  display: flex;
  align-items: flex-start;
}
.product-header__detail {
  text-align: left;
}
.product-header__content {
  margin-block: 2rem;
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
}
.product-header__content-b, .product-header__content-a {
  width: 100%;
}
.product-header__content-b {
  display: flex;
}
.product-header__content-b > * {
  width: 100%;
  max-width: 50%;
  padding-right: 16px;
}
.product-header__content-set h3 {
  font-size: var(--subhed-size);
  margin-bottom: 0.5rem;
}
.product-header__content-set ul {
  padding-left: 0.85em;
  line-height: 1.2;
}
.product-header__content-set ul li + li {
  margin-top: 0.5rem;
}
.product-header__content-set ul, .product-header__content-set p {
  font-size: 16px;
}
@media (min-width: 60em) {
  .product-header__content-set ul, .product-header__content-set p {
    font-size: 18px;
  }
}
.product-header .tablist input[type=radio] + label {
  font-family: "Bureau Grot Cond Bd", sans-serif;
  text-transform: uppercase;
  border-radius: 0.25em;
  letter-spacing: 0.04em;
  border: 1px solid rgba(255, 255, 255, 0.25);
  font-size: 12px;
  padding: 0.714286em 1em;
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.75);
  background: none;
  white-space: nowrap;
}
.product-header .tablist input[type=radio] + label:hover, .product-header .tablist input[type=radio] + label:focus {
  background: #fff;
  color: #1b3687;
}
.product-header .tablist input[type=radio] + label[disabled] {
  background: rgba(255, 255, 255, 0.25);
}
.product-header .control.active input[type=radio] + label {
  background: #fff;
  color: #1b3687;
}
.product-header__actions {
  padding-top: 1rem;
  text-align: center;
  margin-bottom: 3rem;
}
.product-header__actions .btn-secondary {
  margin-bottom: 10px;
  color: #E42525;
  background: #fff;
}
.product-header__actions .btn-secondary .icon {
  fill: #E42525;
}
.product-header__actions .btn-secondary .icon {
  transform: translate(4px, -4px);
}
.product-header__actions .btn-secondary.toggle-open .icon {
  transform: translate(4px, -4px) rotate(180deg);
}
.product-header__actions .btn-secondary:hover, .product-header__actions .btn-secondary:focus, .product-header__actions .btn-secondary.toggle-open {
  color: #fff;
  background: transparent;
}
.product-header__actions .btn-secondary:hover .icon, .product-header__actions .btn-secondary:focus .icon, .product-header__actions .btn-secondary.toggle-open .icon {
  fill: #fff;
}
.product-header__actions div[data-sc-id] {
  font-family: freight-text-pro, serif;
  color: #fff !important;
  margin-inline: 0;
  max-width: 100%;
  background-color: transparent !important;
  box-shadow: none !important;
  border: 1px solid #fff !important;
}
.product-header__actions .fa-chevron-right,
.product-header__actions .fa-chevron-left,
.product-header__actions .change-location-cta {
  color: #fff !important;
}
.product-header__actions .call-to-action {
  font-weight: normal !important;
}
.product-header__actions .sc-smartsite-footer {
  justify-content: center !important;
  font-family: "Bureau Grot Cond Bk", sans-serif !important;
  text-transform: uppercase;
  font-size: 8px;
  font-weight: 700;
}
.product-header__actions .retailer-wrapper {
  max-width: 80% !important;
  min-width: none !important;
}
.product-header .controls {
  text-align: center;
}
.product-header .controls:has(label) {
  border-bottom: 1px solid #fff;
  padding-bottom: 1rem;
}
.product-header .controls .control-label {
  font-family: "Bureau Grot Cond Bk", sans-serif;
  text-transform: uppercase;
  margin-right: 0.25rem;
  display: none;
}
@media (min-width: 60em) {
  .product-header {
    --hed-size: 48px;
  }
  .product-header__content {
    flex-wrap: nowrap;
  }
  .product-header__content-b, .product-header__content-a {
    width: 100%;
    max-width: 60%;
  }
  .product-header__content-b {
    max-width: 40%;
    display: block;
  }
  .product-header__content-b > * {
    max-width: none;
    padding-right: 0;
  }
  .product-header__content-set + .product-header__content-set {
    margin-top: 2rem;
  }
  .product-header .tablist input[type=radio] + label {
    font-size: 14px;
  }
  .product-header .controls {
    text-align: left;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  .product-header .controls:has(label) {
    border-bottom: 1px solid #fff;
    padding-bottom: 1rem;
  }
  .product-header .controls .control-label {
    display: inline-block;
  }
  .product-header__actions {
    text-align: left;
  }
  .product-header__inner {
    display: flex;
  }
  .product-header__inner > * {
    width: 100%;
    max-width: 50%;
  }
  .product-header__detail {
    padding: 0 2.5rem;
  }
}
.product-info__actions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  max-width: 440px;
  margin-inline: auto;
}
.product-info__actions > * {
  width: 100%;
}
.product-info__actions > * > * {
  width: 100%;
}
.product-info__actions .btn {
  color: #fff;
  background: #E42525;
  max-width: 300px;
  margin-inline: auto;
}
.product-info__actions .btn .icon {
  fill: #fff;
}
.product-info__actions .btn .icon {
  transform: translate(4px, -4px);
}
.product-info__actions .btn.toggle-open {
  margin-bottom: 1rem;
}
.product-info__actions .btn.toggle-open .icon {
  transform: translate(4px, -4px) rotate(180deg);
}
.product-info__actions .btn:hover, .product-info__actions .btn:focus, .product-info__actions .btn.toggle-open {
  color: #fff;
  background: #a31414;
}
.product-info__actions .btn:hover .icon, .product-info__actions .btn:focus .icon, .product-info__actions .btn.toggle-open .icon {
  fill: #fff;
}
.product-info__actions div[data-sc-id] {
  font-family: freight-text-pro, serif;
  min-width: 300px;
  background-color: transparent !important;
  box-shadow: none !important;
  border: 1px solid #E42525 !important;
  border-radius: 0.425em !important;
}
@media (min-width: 60em) {
  .product-info__actions div[data-sc-id] {
    min-width: 400px;
  }
}
.product-info__actions .fa-chevron-right,
.product-info__actions .fa-chevron-left,
.product-info__actions .change-location-cta {
  color: #E42525 !important;
}
.product-info__actions .call-to-action {
  font-weight: normal !important;
}
.product-info__actions .sc-smartsite-footer {
  justify-content: center !important;
  font-family: "Bureau Grot Cond Bk", sans-serif !important;
  text-transform: uppercase;
  font-size: 8px;
  font-weight: 700;
}
.product-slider {
  width: 100%;
  padding-inline: 30px;
  --btn-size: 4.5rem;
}
.product-slider .flickity-button {
  width: 2rem;
  height: 9.25rem;
  background: transparent;
  border-radius: 0;
}
.product-slider .flickity-button .flickity-button-icon {
  fill: #fff;
  width: var(--btn-size);
  height: var(--btn-size);
  left: 0;
  top: 0;
}
.product-slider .flickity-button.flickity-prev-next-button {
  overflow: hidden;
}
.product-slider .flickity-button.flickity-prev-next-button.previous {
  left: 0;
}
.product-slider .flickity-button.flickity-prev-next-button.next {
  right: 0;
}
@media (min-width: 60em) {
  .product-slider .flickity-button {
    width: var(--btn-size);
    height: var(--btn-size);
    bottom: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  .product-slider .flickity-button.flickity-prev-next-button {
    overflow: visible;
  }
  .product-slider .flickity-button.flickity-prev-next-button.previous, .product-slider .flickity-button.flickity-prev-next-button.next {
    right: auto;
    left: -30px;
  }
  .product-slider .flickity-button.flickity-prev-next-button.next {
    right: -30px;
    left: auto;
  }
}

.product-slide {
  width: 100%;
}

.product-teaser {
  height: 100%;
  border-radius: 0.75rem;
  background-color: #1b3687;
  background-size: cover;
  padding-bottom: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  position: relative;
}
.teaser-carousel .product-teaser {
  margin-left: 0.3rem;
  width: 100%;
  height: 30em;
}
@media (min-width: 43em) {
  .teaser-carousel .product-teaser {
    margin-left: 1.5rem;
    width: calc(50% - 0.75rem);
  }
}
@media (min-width: 60em) {
  .product-teaser {
    padding-bottom: 2rem;
  }
}
@media (min-width: 80em) {
  .product-teaser {
    padding-bottom: 4rem;
  }
  .teaser-carousel .product-teaser {
    width: 21.5625vw;
    height: 26.0417vw;
    max-width: 25.875rem;
    max-height: 31.25rem;
    margin-left: 1.5rem;
  }
}
.product-teaser p.h5 {
  margin: 0 0 0.5rem;
  color: white;
}
.product-teaser p {
  margin: 0;
  line-height: 1.5;
}
.product-teaser .teaser-copy {
  padding: 0 4rem;
  margin-bottom: 3rem;
  width: 100%;
}
.product-teaser .teaser-copy > * {
  color: #fff;
}
.teaser-carousel .product-teaser .teaser-copy {
  margin-bottom: 1rem;
}
.product-teaser .btn-secondary:hover {
  background-color: #E42525;
  border-color: #E42525;
}
@media (min-width: 60em) {
  .product-teaser {
    padding-bottom: 2rem;
  }
  .product-teaser .teaser-copy {
    padding: 0.5rem 2rem 0;
    margin-bottom: 1.5rem;
  }
}
@media (min-width: 80em) {
  .product-teaser {
    padding-bottom: 4rem;
  }
  .product-teaser .teaser-copy {
    padding: 0.5rem 4rem 0;
    margin-bottom: 3rem;
  }
}

a.product-teaser {
  transition: background-color 0.2s ease-in-out;
}
a.product-teaser::before, a.product-teaser::after {
  content: "";
  z-index: 0;
  background-color: #1b3687;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
a.product-teaser::before, a.product-teaser::after,
a.product-teaser .teaser-inner {
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}
a.product-teaser::after {
  opacity: 0;
  background-color: transparent;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75));
}
a.product-teaser .teaser-inner {
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
a.product-teaser img {
  transition: opacity 0.15s linear;
}
a.product-teaser:hover {
  background-color: transparent;
}
a.product-teaser:hover::before,
a.product-teaser:hover .teaser-inner img {
  opacity: 0;
}
a.product-teaser:hover::after {
  opacity: 1;
}
a.product-teaser:hover .teaser-inner {
  transition-delay: 0;
}

.product-info .lede ul {
  display: inline-block;
  list-style-type: none;
}
.product-info .lede li {
  text-align: left;
  position: relative;
  padding-left: 20px;
}
.product-info .lede li:before {
  content: "•";
  color: #e52e2e;
  display: inline-block;
  position: absolute;
  top: 1px;
  left: 0;
  margin-right: 0.5em;
  font-size: 0.8em;
}

.promotions-block__inner {
  padding-bottom: 0;
}
.promotions-block .filter-area {
  margin-top: 2em;
  padding-top: 2em;
}
.promotions-block .section-hed-sm {
  font-size: 2.125em;
  margin-bottom: 0;
}
@media (min-width: 60em) {
  .promotions-block .section-hed-sm {
    font-size: 4em;
  }
}

.recipe-carousel:not(.flickity-enabled) {
  border-radius: 0.75em;
  overflow: hidden;
}
.recipe-carousel .flickity-viewport {
  border-radius: 0.75em;
}
.recipe-carousel.central-primary, .central-primary .recipe-carousel {
  align-self: flex-start;
}
.recipe-carousel img {
  display: block;
  border-radius: 0;
  margin-right: 1rem;
}
.recipe-carousel .flickity-button {
  width: 3.5rem;
  height: 3.5rem;
  background: transparent;
  border-radius: 0;
}
.recipe-carousel .flickity-button .flickity-button-icon {
  fill: #fff;
  width: 3.5rem;
  height: 3.5rem;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
}
.recipe-carousel .flickity-button.flickity-prev-next-button {
  top: 50%;
}
.recipe-carousel .flickity-button.flickity-prev-next-button .flickity-button-icon {
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
}
.recipe-carousel .flickity-button.flickity-prev-next-button.previous {
  left: 0;
}
.recipe-carousel .flickity-button.flickity-prev-next-button.next {
  right: 0;
}
@media (min-width: 80em) {
  .recipe-carousel .flickity-button {
    width: 4.5rem;
    height: 4.5rem;
  }
  .recipe-carousel .flickity-button.flickity-prev-next-button .flickity-button-icon {
    width: 4.5rem;
    height: 4.5rem;
  }
}

.recipe-teaser {
  position: relative;
  overflow: hidden;
  border-radius: 0.5em;
  margin: auto auto 1.5em auto;
  height: 30em;
}
@media (min-width: 60em) {
  .recipe-teaser {
    margin: 0;
    max-width: 550px;
    height: 100%;
  }
  .recipe-teaser + .recipe-teaser {
    margin: 0 0 0 1.5em;
  }
}
.recipe-teaser .caption {
  opacity: 1;
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 10% 4em 10%;
  color: #fff;
  transition: opacity 0.25s ease-in-out;
}
@media (pointer: fine) {
  .recipe-teaser .caption {
    display: none;
  }
}
.recipe-teaser .caption p {
  font-size: 1.1em;
  line-height: 1.4;
}
.recipe-teaser .caption .recipe-hed {
  color: #fff;
  margin-bottom: 0.5em;
  margin-top: auto;
  font-size: 1.5em;
  letter-spacing: -0.000417em;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  font-smooth: antialiased;
}
.recipe-teaser .caption .recipe-hed + p {
  margin-top: 0;
}
@media (min-width: 60em) {
  .recipe-teaser .caption .recipe-hed {
    font-size: 2em;
    letter-spacing: -0.000625em;
  }
}
.recipe-teaser .caption .btn:hover, .recipe-teaser .caption .btn:focus {
  background: none;
  border: 1px solid #fff;
}
.recipe-teaser img {
  transition: transform 0.5s ease-in-out;
  margin: 0 auto;
  transform: scale(1.05);
  object-fit: cover;
  object-position: center;
  min-height: 35em;
  width: 100%;
  transform: scale(1.01);
}
@media (min-width: 60em) {
  .recipe-teaser img {
    transform: scale(1.05);
  }
  .recipe-teaser .caption {
    opacity: 0;
  }
  .recipe-teaser:hover img, .recipe-teaser:focus-within img {
    transform: scale(1.01);
  }
  .recipe-teaser:hover .caption, .recipe-teaser:focus-within .caption {
    opacity: 1;
    display: flex;
  }
}

[data-slide-indicator] {
  position: absolute;
  bottom: -0.125rem;
  height: 0.5rem;
  background-color: #E42525;
  transition: transform 0.45s cubic-bezier(0.17, 0.67, 0.72, 1.15);
}

.search-filters {
  position: relative;
  z-index: 1;
}
.search-filters fieldset {
  border: none;
  padding: 0;
  margin: 0;
  text-align: left;
  max-width: 1152px;
  margin: 0 auto;
}
.search-filters input,
.search-filters select,
.search-filters .filter-toggle,
.search-filters .col {
  width: 100%;
}
.search-filters input[type=search]::-webkit-search-decoration,
.search-filters input[type=search]::-webkit-search-results-button,
.search-filters input[type=search]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}
.search-filters input[type=search] {
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M19.572%2017.894l5.8%205.801a1%201%200%200%201%200%201.414l-.707.707a1%201%200%200%201-1.414%200l-5.861-5.861A10.95%2010.95%200%200%201%2011%2022C4.925%2022%200%2017.075%200%2011S4.925%200%2011%200s11%204.925%2011%2011c0%202.61-.91%205.008-2.428%206.894zM11%2019a8%208%200%201%200%200-16%208%208%200%200%200%200%2016z%22%20fill-rule%3D%22evenodd%22%20fill%3D%22%238d838f%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-size: 26px 26px;
  background-position: right 10px top 50%;
  padding-right: 48px;
}
.search-filters .filter-toggle {
  position: relative;
  display: block;
  font-size: 1.25em;
  letter-spacing: normal;
  line-height: 1.2;
  padding: 0;
  margin: 0 0 24px;
  text-align: left;
}
.search-filters .filter-toggle, .search-filters .filter-toggle:hover {
  background: transparent;
  color: #1b3687;
}
.search-filters .filter-toggle svg {
  fill: #E42525;
  transition: transform 0.2s ease-in-out;
  transform: rotateZ(0deg);
  position: absolute;
  right: 0;
  top: 7px;
}
.search-filters .filter-toggle.toggle-open svg {
  transform: rotateZ(180deg);
}
.search-filters .submit-wrap {
  border-top: 1px solid #e0d7d9;
  padding: 1.875em 0;
  text-align: center;
  /* Sometimes the submit container becomes a column on larger screens, 
   * and in some cases it doesn't. 
   *
   * .submit-wrap--sm-only attempts to bridge that gap.
   */
}
.search-filters .submit-wrap--sm-only {
  width: auto;
}
@media (min-width: 60em) {
  .search-filters .submit-wrap--sm-only {
    border: none;
    padding: 0;
    text-align: inherit;
    margin-bottom: 1.5rem;
  }
}
.search-filters .col {
  margin: 0 0 1.5rem;
}
.search-filters .row + .row {
  margin-top: 1.875em;
}
.search-filters .row + .row.submit-wrap {
  margin-top: 0;
}
@media (min-width: 60em) {
  .search-filters .a11y-only.a11y-override {
    clip: auto;
    height: auto;
    width: auto;
    margin: 0;
    overflow: visible;
    position: static;
  }
  .search-filters .row,
.search-filters .row-lrg-only {
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  .search-filters .row-lrg-only {
    width: 100%;
  }
  .search-filters .row-lrg-only.a11y-override {
    width: 100%;
  }
  .search-filters .col {
    max-width: 26rem;
  }
  .search-filters .col.minor {
    max-width: 16.75rem;
  }
  .search-filters .col.full-width {
    max-width: none;
  }
  .search-filters .col,
.search-filters .sm-screen-only,
.search-filters .row-lrg-only {
    /* 
     * Managing horizontal margins on columns across layout configurations, 
     * and in cases where a column is sometimes a row on smaller screens.
     *
     * This will likely need to be updated if the markup changes much, 
     * or the component becomes more modular.
     */
  }
  .search-filters .col + .col,
.search-filters .col + .row-lrg-only,
.search-filters .col + .submit-wrap--sm-only,
.search-filters .sm-screen-only + .col,
.search-filters .sm-screen-only + .row-lrg-only,
.search-filters .sm-screen-only + .submit-wrap--sm-only,
.search-filters .row-lrg-only + .col,
.search-filters .row-lrg-only + .row-lrg-only,
.search-filters .row-lrg-only + .submit-wrap--sm-only {
    margin-left: 1.5em;
  }
}

.social-feed a {
  display: block !important;
  position: relative;
  width: 98% !important;
}
@media (min-width: 960px) {
  .social-feed a {
    width: 50% !important;
  }
}
.social-feed .instaContain {
  display: block;
  overflow: hidden;
  padding-bottom: 100%;
  position: relative;
}
.social-feed img {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  object-fit: cover;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .social-feed {
    display: none !important;
  }
}
.social-nav {
  display: flex;
  list-style: none;
  padding-top: 1.5em;
  justify-content: center;
}
.social-nav__title {
  color: #1b3687;
  font-size: 1.25em;
  font-weight: 700;
  text-transform: uppercase;
}
.inverted .social-nav__title {
  color: #fff;
}
.social-nav li {
  margin: 0 0.5em;
  padding: 0;
  font-family: "Bureau Grot Cond Bk", sans-serif;
}
.social-nav a {
  display: flex;
}
.social-nav .icon {
  width: 1.5rem;
  height: auto;
  fill: #1b3687;
  max-height: 1.5em;
}
.inverted .social-nav .icon {
  fill: #fff;
}
.social-nav.social-nav-lrg li {
  margin: 0 0.75em;
}
.social-nav.social-nav-lrg .icon {
  width: 2rem;
  max-height: 2rem;
}
@media (min-width: 60em) {
  .social-nav {
    padding-top: 0;
  }
  .social-nav.social-nav-lrg li {
    margin: 0 1em;
  }
  .social-nav.social-nav-lrg .icon {
    width: 3rem;
    max-height: 3rem;
  }
}

.share-cta {
  padding-top: 0.5em;
  margin: 1em auto;
}
@media (min-width: 60em) {
  .share-cta {
    display: flex;
    margin-top: 1em;
    justify-content: center;
  }
}
.share-cta .share-hed {
  font-size: 1.1em;
}
@media (min-width: 60em) {
  .share-cta .share-hed {
    margin-top: 1em;
    margin-right: 1em;
    margin-bottom: 1em;
  }
}

.star-rating {
  --star-width: 26px;
  --star-height: 28px;
  --star-color: #ebeef2;
  --active-width: 0;
  position: relative;
  display: inline-block;
}
.star-rating__stars--bright {
  --star-color: #ffd34f;
  position: absolute;
  top: 0;
  left: 0;
  width: var(--active-width);
  overflow: hidden;
  white-space: nowrap;
}
.star-rating__star {
  display: inline-block;
  width: var(--star-width);
  height: var(--star-height);
  fill: var(--star-color);
}
@media (min-width: 60em) {
  .star-rating {
    --star-width: 31.2px;
    --star-height: 33.6px;
  }
}

.step-slide {
  border: 1px solid #e0d7d9;
  width: calc(100% + 5.208333vw);
  margin-left: calc(0px - 2.60416665vw);
}
.step-slide + .step-slide {
  margin-top: 1em;
}
.step-slide__inner {
  padding: 1em;
}
.step-slide__detail {
  text-align: left;
}
.step-slide__media img {
  max-width: 100%;
}
.step-slide__meta {
  font-family: "Bureau Grot Cond Bk", sans-serif;
  color: #0074B3;
}
.step-slide__hed {
  text-transform: none;
  font-family: freight-text-pro, serif;
  margin: 0;
}
.step-slide__copy {
  font-size: 1.25em;
}
@media (min-width: 60em) {
  .step-slide {
    border: none;
  }
  .step-slide + .step-slide {
    margin-top: 0;
  }
  .step-slide__inner {
    display: flex;
    padding: 2em;
  }
  .step-slide__inner > * {
    width: 100%;
  }
  .step-slide--vert .step-slide__inner {
    flex-direction: column;
  }
  .step-slide__detail {
    padding-right: 2em;
    max-width: 50%;
    margin: 0 auto;
  }
  .step-slide--flip-horiz .step-slide__detail {
    padding-right: 0;
    padding-left: 2em;
  }
  .step-slide--no-media .step-slide__detail {
    max-width: 75ch;
  }
  .step-slide__media {
    order: 1;
    padding-top: 1em;
    flex-shrink: 1.5;
  }
  .step-slide--flip-horiz .step-slide__media {
    order: 0;
  }
  .step-slide--no-media .step-slide__media {
    max-width: 70%;
  }
}

.steps-carousel {
  max-width: 71.5rem;
  margin: 0 auto;
  padding-top: 110px;
}
.steps-carousel .flickity-viewport {
  transition: height 0.3s ease;
}
.steps-carousel .flickity-prev-next-button {
  width: 100px;
  height: 100px;
  background-color: transparent;
  top: -105px;
  left: 50%;
  margin: 0;
}
.steps-carousel .flickity-prev-next-button .flickity-button-icon {
  width: 80%;
  height: 80%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #5a5666;
}
.steps-carousel .flickity-prev-next-button.previous {
  transform: translate(-100%, 0);
}
.steps-carousel .flickity-prev-next-button.next {
  transform: translate(0);
}
@media (min-width: 60em) {
  .steps-carousel__slide {
    width: 100%;
    margin: 0;
  }
  .steps-carousel__slides.flickity-enabled {
    border: 1px solid #e0d7d9;
  }
}

.superior-hero section.scooped::before {
  content: "*when compared to ordinary eggs.";
  display: block;
  font-family: freight-text-pro, serif;
  text-transform: none;
  color: black;
  font-weight: normal;
  font-size: 16px;
  position: relative;
  top: -100px;
}
@media (min-width: 60em) {
  .superior-hero section.scooped::before {
    top: -130px;
    font-size: 17px;
  }
}
@media (min-width: 80em) {
  .superior-hero section.scooped::before {
    top: -170px;
    font-size: 18px;
  }
}

html[lang|=es] .superior-nutrition-page .scooped.hero .scooped::before {
  content: "*Cuando se compara con huevos ordinarios.";
  display: block;
  font-family: freight-text-pro, serif;
  text-transform: none;
  color: black;
  font-weight: normal;
  font-size: 16px;
  position: relative;
  top: -100px;
}
@media (min-width: 60em) {
  html[lang|=es] .superior-nutrition-page .scooped.hero .scooped::before {
    top: -130px;
    font-size: 17px;
  }
}
@media (min-width: 80em) {
  html[lang|=es] .superior-nutrition-page .scooped.hero .scooped::before {
    top: -170px;
    font-size: 18px;
  }
}

.tablist fieldset {
  border: 0;
  padding: 0;
}
.tablist .control {
  display: inline-block;
}
.tablist input[type=radio] + label {
  padding: 0;
  margin: 0;
  display: block;
}
.tablist input[type=radio] + label::before, .tablist input[type=radio] + label::after {
  content: none;
}
.tablist input[type=radio] + label:hover {
  cursor: pointer;
}
.tablist .panel {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.tablist .panel.active {
  clip: auto;
  height: auto;
  overflow: visible;
  position: static;
  width: auto;
}
@media (max-width: 767px) {
  .tablist--stack .controls {
    display: none;
  }
  .tablist--stack .controls ~ div.panel {
    padding: 0;
    position: relative;
    clip: none;
    width: auto;
    height: auto;
    margin: 0;
  }
}

.tablist.basic-tabs {
  margin: 0;
}
@media (min-width: 768px) {
  .tablist.basic-tabs {
    margin: 4rem 0 0;
  }
  .tablist.basic-tabs .section-hed {
    margin-top: 0;
  }
  .tablist.basic-tabs .inner {
    display: flex;
    justify-content: space-between;
  }
}
.tablist.basic-tabs fieldset {
  border: 0;
  padding: 0;
}
.tablist.basic-tabs .controls {
  border-bottom: 2px solid #e0d7d9;
  position: relative;
}
.tablist.basic-tabs [data-slide-indicator] {
  width: calc(33.3333333333% - 0.833333rem);
}
@media (min-width: 60em) {
  .tablist.basic-tabs [data-slide-indicator] {
    width: calc(33.3333333333% - 0.833333rem);
  }
}
.tablist.basic-tabs .control {
  text-align: center;
  width: calc(33.3333333333% - 0.833333rem);
}
.tablist.basic-tabs .control label {
  font-size: 1.125em;
  font-weight: 700;
  line-height: 1.222222;
  padding-bottom: 1.5em;
  text-align: center;
}
@media (min-width: 60em) {
  .tablist.basic-tabs .control {
    width: calc(33.3333333333% - 0.833333rem);
  }
  .tablist.basic-tabs .control label {
    font-size: 1.5em;
  }
}
.tablist.basic-tabs input[type=radio] + label {
  color: #1b3687;
}
.tablist.basic-tabs a:focus,
.tablist.basic-tabs input:focus,
.tablist.basic-tabs input[type=radio]:focus + label {
  outline: none;
}
.tablist.basic-tabs .panel {
  text-align: left;
  padding: 3.125rem 0 0;
}
.tablist.basic-tabs .panel.active {
  animation: fadeup 0.45s ease-out;
  animation-fill-mode: forwards;
}
@media (min-width: 60em) {
  .tablist.basic-tabs .panel {
    padding-top: 6.25rem;
  }
}

.tablist.nutritional-info-tabs .control {
  display: block;
  max-width: 30.65rem;
  margin: 0 auto;
}

.tablist.product-info-tabs {
  margin: 4rem 0 0;
}
.tablist.product-info-tabs fieldset {
  border: 0;
  padding: 0;
}
.tablist.product-info-tabs .controls {
  border-bottom: 2px solid #e0d7d9;
  position: relative;
}
.tablist.product-info-tabs [data-slide-indicator] {
  width: 49.35%;
}
@media (min-width: 60em) {
  .tablist.product-info-tabs [data-slide-indicator] {
    width: 16.8125rem;
  }
}
.tablist.product-info-tabs .control {
  text-align: center;
  width: 49.35%;
}
.tablist.product-info-tabs .control label {
  font-size: 1.125em;
  font-weight: 700;
  line-height: 1.222222;
  padding-bottom: 1.5em;
  text-align: center;
}
.tablist.product-info-tabs .control label img,
.tablist.product-info-tabs .control label .title {
  display: block;
  margin: 0 auto;
}
.tablist.product-info-tabs .control label img {
  width: 100%;
}
@media (min-width: 60em) {
  .tablist.product-info-tabs .control {
    width: 16.8125rem;
  }
  .tablist.product-info-tabs .control label {
    font-size: 1.5em;
  }
}
.tablist.product-info-tabs input[type=radio] + label {
  color: #1b3687;
}
.tablist.product-info-tabs a:focus,
.tablist.product-info-tabs input:focus,
.tablist.product-info-tabs input[type=radio]:focus + label {
  outline: none;
}
.tablist.product-info-tabs .panel {
  text-align: left;
  padding: 3.125rem 0 0;
}
.tablist.product-info-tabs .panel.active {
  animation: fadeup 0.45s ease-out;
  animation-fill-mode: forwards;
}
@media (min-width: 60em) {
  .tablist.product-info-tabs .panel {
    padding-top: 6.25rem;
  }
  .tablist.product-info-tabs .panel .flex {
    align-items: flex-start;
  }
  .tablist.product-info-tabs .panel .flex .col {
    width: calc(25% - 6px);
  }
  .tablist.product-info-tabs .panel .flex .col.col-wide {
    width: calc(50% - 12px);
  }
  .tablist.product-info-tabs .panel .flex .col + .col {
    margin-left: 1.5rem;
  }
}

.teaser-carousel {
  --btn-size: 5rem;
  position: relative;
  padding-bottom: 5.75rem;
  z-index: 5;
}
@media (min-width: 60em) {
  .teaser-carousel {
    margin: 0 -5.2083333% 0;
    padding-left: 5.2083333%;
  }
}
.teaser-carousel .flickity-button:disabled {
  display: none;
}
.teaser-carousel .flickity-button {
  background: transparent;
  border-radius: 0;
  width: var(--btn-size);
  height: var(--btn-size);
}
.teaser-carousel .flickity-button .flickity-button-icon {
  fill: #1b3687;
  width: var(--btn-size);
  height: var(--btn-size);
  top: 0;
}
.teaser-carousel .flickity-button.flickity-prev-next-button {
  transform: none;
}
.teaser-carousel .flickity-button.flickity-prev-next-button.next, .teaser-carousel .flickity-button.flickity-prev-next-button.previous {
  top: auto;
  right: auto;
  bottom: 0;
  left: auto;
}
.teaser-carousel .flickity-button.flickity-prev-next-button.next {
  right: 50%;
  transform: translateX(100%);
}
.teaser-carousel .flickity-button.flickity-prev-next-button.previous {
  left: 50%;
  transform: translateX(-100%);
}
.teaser-carousel .flickity-button.flickity-prev-next-button .flickity-button-icon {
  left: 0;
}

.tint-gallery {
  padding-bottom: 0 !important;
}
.tint-gallery .tintup {
  max-width: 100% !important;
  margin-inline: auto !important;
}
@media (min-width: 45em) {
  .tint-gallery .tintup {
    max-width: 960px !important;
  }
}
@media (min-width: 60em) {
  .tint-gallery {
    padding-bottom: 3em !important;
  }
  .tint-gallery .tintup {
    max-width: 100% !important;
  }
}

.section:has(.tint-gallery),
.section.tint-gallery {
  padding-top: 0;
}
@media (min-width: 60em) {
  .section:has(.tint-gallery),
.section.tint-gallery {
    padding-top: inherit;
  }
}

.tint-submit-form {
  text-align: left;
}

.complex-content {
  margin-top: 3em;
}
.complex-content h1, .complex-content h2, .complex-content h3, .complex-content h4, .complex-content h5, .complex-content .product-teaser p.h5, .product-teaser .complex-content p.h5, .complex-content h6 {
  clear: both;
}
.complex-content .post-body, .complex-content .inset {
  margin-top: 3em;
}
.complex-content .post-body:first-child, .complex-content .inset:first-child {
  margin-top: 0;
}
.complex-content figure {
  display: table;
  margin-top: 20px !important;
}
.complex-content figure figcaption {
  display: table-caption;
  caption-side: bottom;
}
.complex-content p.intro-text {
  font-size: 1.2em;
}
@media (--small-medium) {
  .complex-content figure {
    display: table;
    width: auto;
  }
}
@media screen and (max-width: 500px) {
  .complex-content figure[style^="float:"] {
    float: none !important;
    margin: 20px 0 20px !important;
  }
}

.player {
  position: relative;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: contain;
}
.player svg {
  width: 96px;
  height: 96px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
.player svg path {
  fill: inherit;
}
.player svg:hover, .player svg:focus, .player svg:active {
  fill: red !important;
}

.where-to-buy .wtb-section {
  padding: 0 2.60416665% 2.5em !important;
}
.where-to-buy .wtb-header {
  padding: 2.5em 2.60416665% 0 !important;
}
.where-to-buy .complex-content {
  margin-top: 1em !important;
}
.where-to-buy .post-body {
  max-width: 100% !important;
}
.where-to-buy .content-area img {
  border-radius: 0.625em;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 71.5rem) {
  .where-to-buy .content-area img {
    width: 100%;
    max-width: 71.5rem;
  }
}
.where-to-buy .content-area div[data-basketful-product-locator] {
  border: 1px solid #e0d7d9;
}
.where-to-buy .content-area * + iframe {
  margin-top: 0;
}

@media (min-width: 80em) {
  html[lang|=es] .top-nav {
    display: none;
  }
  html[lang|=es] .masthead .nav-secondary .subnav.meta-nav a {
    font-size: 1.2em;
    font-weight: bold;
  }
  html[lang|=es] .masthead .nav-secondary .search-high-bp {
    padding-left: 10px;
  }
}
html[lang|=es] .superior-es {
  text-align: center;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .masthead .masthead-inner {
    display: block;
  }

  #home-video-bg {
    min-height: 100vh;
    width: auto;
  }

  #superior-nutrition-video-bg {
    min-height: 1000px;
    width: auto;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .product-teaser img {
    max-width: 400px;
    padding: 0 10px;
  }

  .recipe-teaser img {
    min-width: 550px;
  }
  .recipe-teaser .caption {
    display: block;
    padding: 200px 10px 0 10px;
  }

  .recipe-teaser:hover .caption {
    display: block;
  }

  .hero-art img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    min-width: 100%;
    max-width: none;
    min-height: 400px;
  }

  .content-card-media {
    height: auto !important;
  }
  .content-card-media img {
    height: auto;
  }
}