.content-header {
	
	padding-top: 0.75rem; 
	padding-bottom: 1.5625rem; 
	
	.lede {
		max-width: 71.5rem; // 1144px
		margin: 0 auto;

		&.narrow{
			max-width: 53.25rem; // 852px
		}
	}
	
	.page-intro {
		font-size: 21px;
		
		@media(min-width: 960px) {
			font-size: 24px;
		}
	}	

	@media(min-width: 60em) {
		padding-top: 5.625rem; 
	}
	
	
}