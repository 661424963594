.tablist.basic-tabs {
	$tab-width-sm: calc((100% / 3) - 0.833333rem);
	$tab-width-lrg: $tab-width-sm;

	margin: 0;

  @media(min-width: 768px) {
  	margin: 4rem 0 0;
		
		.section-hed {
			margin-top: 0;
		}

  	.inner {
  		display: flex;
  		justify-content: space-between;
  	}
  }

	fieldset {
	  border: 0;
	  padding: 0;
	}

	.controls {
		border-bottom: 2px solid $lt-grey;
		position: relative;
	}

	[data-slide-indicator] {
		width: $tab-width-sm;

		@media(min-width: 60em) {
			width: $tab-width-lrg;
		}
	}

	.control {
		text-align: center;
		width: $tab-width-sm;

	  label {
	    font-size: 1.125em; // 18px
	    font-weight: 700;
	    line-height: 1.222222;
      padding-bottom: 1.5em;
      text-align: center;
	  }

	  @media(min-width: 60em) {
	  	width: $tab-width-lrg;

	    label {
	      font-size: 1.5em; // 24px
	    }
	  }
	}

	input[type="radio"] + label {
		color: $eb-blue;
	}

	a:focus,
	input:focus,
	input[type="radio"]:focus + label {
		outline: none;
	}

	.panel {
		text-align: left;
	  padding: 3.125rem 0 0;
	  
	  &.active {
		  animation: fadeup .45s ease-out;
		  animation-fill-mode: forwards;
	  }

	  @media(min-width: 60em) {
			padding-top: 6.25rem;

		  // Uncomment if columnar layout is needed in panel
		  // .flex {
		  // 	align-items: flex-start;
		  // 	.col {
		  // 		width: calc((100% / 4) - 6px); // 24px gutters

		  // 		&.col-wide {
		  // 			width: calc((100% / 2) - 12px); // 24px gutters
		  // 		}

		  // 		+ .col {
		  // 			margin-left: 1.5rem;
		  // 		}
		  // 	}
		  // }
		}
	}
}