// Main Product Carousel
.product-carousel {
	margin: 0 -2.60416665%;

	// Flickity Style Overrides
	.flickity-button {
    width: 2rem;
    height: 9.25rem;
		background: transparent;
		border-radius: 0;

		.flickity-button-icon {
			fill: #fff;
			width: 6.25rem;
			height: 6.25rem;
			top: 0;
		}

		&.flickity-prev-next-button {
			overflow: hidden;

			.flickity-button-icon {
				left: -2.125rem;
				top: 1.5rem;
			}

			&.previous {
				left: 0;
			}
			
			&.next {
				right: 0;
			}
		}
	}

	@media(min-width: 60em) {
		margin: 0 -5.9%;

		.flickity-button {
	    width: 6.25rem;
	    height: 6.25rem;

			&.flickity-prev-next-button {
				overflow: visible;

				.flickity-button-icon {
					left: 0;
				}
			}
		}
	}

	@media(min-width: 80em) { 
		.flickity-button {
			&.flickity-prev-next-button {

				&.previous {
					left: 7.3385%;

				}
				
				&.next {
					right: 7.3385%;
				}
			}
		}
	}
}

.product-carousel-item {
	width: 100%;
	opacity: 0;
	transition: opacity .2s linear;

	&.is-selected,
	.product-carousel-single & {
		opacity: 1;
	}
	
	.img-wrap { 
		position: relative;
		height: 0;
		width: 100%;
		padding-bottom: 100%;

		img {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);
		}
	}

	@media(min-width: 60em) {
		.img-wrap {
			height: auto;
			width: auto;
			padding: 0;

			img {
				position: relative;
				top: 0;
				left: 0;
				transform: none;
			}
		}
	}
}


// Product Thumbnail Carousel
.product-thumb-carousel {
	margin-bottom: 2rem;

	&.hide {
		display: none;
	}

	// Flickity Style Overrides
	.flickity-button {
    &:disabled {
    	display: none;
    }
	}
}

.product-thumb-carousel-item {
	width: 3rem;
	height: 3rem;
	border: 2px solid rgba(255,255,255,.25);
	border-radius: .375rem;
	overflow: hidden;
	margin: 0 .25rem;

	&.is-nav-selected {
		border-color: rgba(255,255,255,1);
	}

	@media(min-width: 60em) {
		border-radius: .75rem;
		width: 7.625rem;
		height: 7.625rem;
		margin: 0 .75rem;
	}
}