.contact-page {
  .promo-block { 
    margin-top: 3em; 
  }

  @media(min-width: 60em) {
    .promo-block { 
      margin-top: 6em; 
    }
  }

  main {
    padding-top: 142px;
  }

  @media(min-width: 65em) {
    main {
      padding-top: 98px;
    }
  }

  @media(min-width: 80em) {
    main {
      padding-top: 112px;
    }
  }
}

.contact-form { 
  padding: 2em;
  .title {
    padding:1em 1em 0.9em 1em;
    width:100%;
  }

  hr {
    margin: 0 0 2em 0;
    height: 4px;
  }

  .row {
    padding: 0 1em;
    width: 100%;

    @media(min-width: 60em) {
      padding: 0 4em;
    }
  }

  &.search-filters {
    .col {
      max-width: none;
    }

    .submit-wrap {
      border-top: none;
    }
  }

  &__checkboxes {
    input[type="checkbox"] + label { 
      display: block; 
    }
  }
  @media(min-width: 60em) {
    padding: 5em;
  }
}
