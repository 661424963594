.find-a-recipe {
  .inset {
    padding: 0 5.625% 0 5.625%;
    
    div > * { 
      margin: 1em auto 0; 
    }
  }

  label {
    margin: 0 0.5em 0 auto;
    color: $white;
  }

  form { 
    display: block;

    input {
      width: 100%;
    }
  }

  @media(min-width: 60em) {
    .inset {
      padding: 3em 5.625% 1em 5.625%;

      div > *{ 
        margin: 0; 
      }
    }

    form{ 
      display: flex;
      
      input { 
        width: auto; 
      }
    }

    input{
      margin: 0 0.5em 0 0;
    }
  }
}