.video-wrap {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  
  object,embed, iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.bg-video-wrap {
	background-position: 50%;

	@media(min-width: 60em) {
		position: relative;

		> * {
			z-index: 4;
			position: relative;
		}


		&.fade-btm {
			position: relative;
		
			&::after {
				content: '';
				height: 30px;
				background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
			}
		}
	}
}

.bg-video {
	position: absolute;
	width: 100%;
	top: 0;
	left: 0;
	height: 100%;
	object-fit: cover;
	z-index: 0;
	display: none;

	@media(min-width: 60em) {
		display: block;
	}
}


.video-page {
	.content-forte {
		padding-top: 0 !important;
	}
}

// Lazy Loading
img[data-src] {
	opacity: 0;
	transition: opacity 0.25s ease-out;
}

img[data-src][src] {
	opacity: 1;
}

.no-js {
	img[data-src] {
		display: none;
	}
}
