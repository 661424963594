.pagination{
  display: flex;
  justify-content: center;
  max-width: 800px;
  margin: 5rem auto 0 auto;

  li{
    list-style: none;
    border-right: 1px solid $med-grey;
    padding: 0rem 2.5%;
    text-align: center;

    svg{
      transition: fill 0.2s ease;
      transform: translateY(3px);
      fill: currentColor;
    }

    &:before{ 
      display: none; 
    }

    &:first-child{
      margin-right: 1rem;
    }

    &:last-child{
      margin-left: 1rem;
    }

    &:first-child, &:nth-last-child(2), &:last-child{
      border: none;
    }

    a{
      font-family: $sans-bold;
      //font-weight: 700;
      color: $med-grey;
      font-size: 1.25em;
      line-height: 26px;
      transition: color 0.3s ease;

      &.active{
        color: $eb-red;
      }
      
      &:hover{
        color: $eb-red;
        svg path{
          fill: $eb-red;
        }
      }
    }

    @media(min-width: 60em) {
      padding: 0rem 1.7rem;
    }
  }
}
