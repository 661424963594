.social-feed {
	
	a {
		display: block !important;
		position: relative;
		width: 98% !important;
		
		@media(min-width: 960px) {
			width: 50% !important;
		}
	}
	
	.instaContain {
	    display: block;
		overflow: hidden;
		padding-bottom: 100%;
		position: relative;	
	}
	
	img {
		height: 100%;
	    left: 0;
	    position: absolute;
	    top: 0;
	    width: 100%;
	    object-fit: cover;
	}
	
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.social-feed {
	   display: none !important;
   }
}