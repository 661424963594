.hero-slide {
  --hed-size: 34px;
  --copy-size: 16px;
  --slide-height: 100vw;
  --bottom-padding: 3rem;

  position: relative;
  height: var(--slide-height);
  width: 100%;

  &__media {
    position: relative;

    .frame {
      --w: 6;
      --h: 6;
    }

    &:before {
      content: '';
      position: absolute;
      top:0;
      bottom:0;
      right:0;
      left:0;
      z-index: 1;
      background-image: 
        linear-gradient(to bottom, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0) 35%, rgba(0, 0, 0, 0.5)),
        linear-gradient(to right, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0) 75%);
    }
  }

  &__detail {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 4;
    padding-bottom: var(--bottom-padding);
    display: flex;
    justify-content: flex-start;
    align-items: stretch;

    &__copy {
      font-size: var(--copy-size);
    }
  }

  &__wrap {
    padding: 0.5em 5.2083333% 0 5.2083333%;
    margin-inline: auto;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
  }

  &__content {
    width: 100%;
    border-radius: 0.5rem;
    z-index: 5;
    text-align: center;
    padding: 0.5em 5.2083333% 0 5.2083333%;
  }

  &__hed {
    font-size: var(--hed-size);
    color: $white;
    text-transform: none;

    sup {
      position: relative;
      top: -1.2em;
      font-size: 0.4em;
      line-height: 0;
      vertical-align: baseline;
    }
  }

  &__actions {
    margin-top: 0px;
  }

  .btn {
    font-size: 16px;
  }

  @media(min-width: 60em) {
    --hed-size: 48px;
    --slide-height: min(43.75vw, 1920px);
    --bottom-padding: 4rem;

    &__media {
      .frame {
        --w: 16;
        --h: 7;
      }
    }

    &__content {
      max-width: 375px;
      padding: 0;
      text-align: left;
    }

    &__actions {
      margin-top: 32px;
    }
  }

  @media(min-width: 70em) {
    --hed-size: 64px;

    .btn {
      font-size: 24px;    
    }

    &__content {
      max-width: 475px;
    }
  }

  @media(min-width: 85em) {
    --hed-size: 72px;

    &__content {
      max-width: 650px;
    }
  }
}