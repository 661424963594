/* Global layout 
------------------------- */

.wrap-sm, 
.wrap-xsm, 
.wrap-med, 
.wrap-lrg {
	margin: 0 auto;
	width: 100%;
	max-width: 89.75rem; // 1436px
} 

.wrap-xsm {
	max-width: 60rem; // 960px
}

.wrap-sm {
	max-width: 71.5rem; // 1144px
}

.wrap-lrg {
	max-width: 108rem; // 1728px
}

.flex {
	list-style: none;
	@media(min-width: 60em) {
		display: flex;
		align-items: center;
		justify-content: center;

		&.promo-grid {
			align-items: stretch;
      margin-bottom: 6.5em;
      
			& > *:not(.scoop) {
				margin: 0 .5em;
				flex-grow: 2;

				&.wrap-med {
					margin-left: auto;
					margin-right: auto;
				}
			}

			&.grid-col-a,
			&.grid-col-b {
				padding: 10em;
        border-radius: .5em;
        
        &:not(.margin-bottom) {
      		margin-bottom: 0;
        }

				.promo-block {
					margin: auto 0;
					flex-grow: 0;
					max-width: 30em;
				}
			}

			&.grid-col-a {
				justify-content: flex-start;
			}

			&.grid-col-b {
				justify-content: flex-end;
			}
		}
	}

	@media(max-width: 59.9375em) {
		&.promo-grid {
			&.grid-col-a,
			&.grid-col-b {
				padding-bottom: 0;
				padding-top: 39%;
				display: flex;
				flex-direction: column;
				justify-content: flex-end;

				&.bg-upload {
					background-size: 100%;
					background-position: center top;
					background-repeat: no-repeat;
				}
			
				.promo-block {
					margin-bottom: 0;
				}
			}

			&.grid-col-a {
				border-radius: 0.5em 0.5em 0.6em 0.6em;
			}

			&.promo-collapse {
				margin: 2.5em 2.60416665%;
				.scoop {
					display: none;
				}
			}
		}
	}

	&.two-up {

		@media(min-width: 60em) {
			display: flex;
			justify-content: space-between; /* Fallback for IE11 */
			justify-content: space-evenly;
			max-width: 100%;
		}

		& > * {
			width: 48%;
			display: inline-block;
			@media(min-width: 60em) {
				min-width: auto;
				width: 24%;
			}
		}
	}

	@supports( display: grid ) {
		&.two-up {
			display: grid;
			grid-template-columns: 1fr 1fr;

			& > * {
				max-width: 100%;
				width: auto;
				margin: .75%;
			}

			@media(min-width: 60em) {
				display: flex;
			}
		}
	}

	&.flex-even {
		justify-content: space-between; /* Fallback for IE11 */
		justify-content: space-evenly;
	}

	&.flex-center {
		justify-content: center;
	}

	&.flex-stretch {
		justify-content: space-between;
	}

	&.flex-start {
		justify-content: flex-start;
	}
}

.flex-end{
  display: flex;
  justify-content: flex-end;
}

.section {
	padding: 2.5em 2.60416665%;

	.section {
		padding-left: 0;
		padding-right: 0;
	}

	@media(min-width: 60em ) {
		padding: 2.5em 5.2083333%;
	}

	margin: 0 auto;
	position: relative;

	&.inverted {
		background: $eb-blue;

		.promo-block:not(.isolated) {
			padding-top: 1.5em;

			p,
			[class*="-hed"] {
				color: #fff;
			}
		}

		&:not(.scooped-inv) .scoop {
			fill: $eb-blue;
		}
	}

	&.grey {
		background-color: $xlt-grey;

		&:not(.scooped-inv) .scoop {
			fill: $xlt-grey;
		}		
	}
}

.inset {
	margin: 2.5em auto 4.5em auto;
	max-width: 1400px;

	@media(min-width: 60em) {
		padding: 0 5.625%;
  }
}

.central {
	text-align: left;

	.section-hed,
	.content-hed {
		margin: 0;
	}

	@media(min-width: 60em) {
		display: flex;
		flex-direction: row;
		justify-content: stretch;
		align-items: stretch;
	}

	.central-secondary {
		padding: 2.5em 3.84615385% .5em 3.84615385%;

		@media(min-width: 60em) {
			min-width: 28em;
			padding: 5em 3.84615385%;
		}
	}

	.central-primary {
		padding: 2.5em 3.84615385%;

    .post-body{
      max-width: 100%;
    }
		@media(min-width: 60em) {
      padding: 5em 6.94444445%;
      width: 100%;
      max-width: 100em;
		}
	}

	&.faq-central .central-secondary {
		background: $eggshell;
		padding: 2.5em 3.84615385% 0 3.84615385%;
		border-bottom: 1px solid $med-grey;
	}
}