.compare-table{
  width: 100%;
  border-collapse: collapse;
  margin: 5em 0;

  tr{
    td, th{
      position: relative;
      padding:2em 1em 1.5em 1em;
      font-family: $sans-bold;
      font-size: 2em;
      color: #7E7277;
      text-transform: uppercase;
      //font-weight: bold;
      &:first-child{
        width: 0;
        padding: 0;
        font-size: 1em;
        span{
          font-family: $serif;
          text-align: left;
          color: $black;
          font-style: italic;
          text-transform: none;
          font-weight: normal;
  
          position: absolute;
          bottom: 96px;
          left: 22vw;
          right: 0;
          text-align: center;
          width: 300px;
          
        }
      }

      &:nth-child(2){
        color: $eb-red;
      }
    }
  }

  > tbody > tr {
    border-bottom: 1px solid $xlt-grey;
  }

  // header styles
  > thead{
    border-bottom: 1px solid $med-grey;
    > tr > th{
      font-size: 1.4em;
      padding: 0.75em 1em;
      width: 50%;
      &:first-child{
        font-weight: normal;
        span{
          top: -2em;
        }
      }
      &:nth-child(2){
        color: $eb-blue;
      }
    }
  }

  @media(min-width: 36em) {
    $padding:0.6em 1em 0.6em 1em;
    tr{
      td, th{
        padding:$padding;
        width: auto;
        &:first-child{
          width: 40%;
          padding: $padding;
          text-align: left;
          font-size: 1.35em;
          span{
            position: static;
          }
        }
      }
    }
    > tbody > tr {
      border-bottom: none;
      &:hover{
        background: $eggshell;
      }
    }
    > thead{
      > tr > th{
        width: 30%;
      }
    }
  }
}