.complex-content {
	margin-top: 3em;
		
	h1, h2, h3, h4, h5, h6 {
		clear: both;
	}
	
	.post-body, .inset {
		margin-top: 3em;
		
		&:first-child {
			margin-top: 0;
		}
	}
	
	figure {
		display: table;
		margin-top: 20px !important;
	
		figcaption {
			display: table-caption;
			caption-side: bottom;
		}		
	}
	
	p.intro-text {
		font-size: 1.2em;
	}	
	
	@media (--small-medium){
		figure{
			display: table;
			width: auto;
		}
	}
	
	
	figure[style^="float:"] {
		
		@media screen and (max-width: 500px) {
			float: none !important;
			margin: 20px 0 20px !important;
		}
		
	}
	
}