.product-slider {
  $this: &;
  width: 100%;
  padding-inline: 30px;
  
  --btn-size: 4.5rem;


  // Flickity Style Overrides
  .flickity-button {
    width: 2rem;
    height: 9.25rem;
    background: transparent;
    border-radius: 0;

    .flickity-button-icon {
      fill: #fff;
      width: var(--btn-size);
      height: var(--btn-size);
      // top: 0;
      left: 0;
      top: 0;
    }

    &.flickity-prev-next-button {
      overflow: hidden;

      // .flickity-button-icon {
      //   left: -2.125rem;
      //   top: 1.5rem;
      // }

      &.previous {
        left: 0;
      }
      
      &.next {
        right: 0;
      }
    }
  }

  @media(min-width: 60em) {
      .flickity-button {
        width: var(--btn-size);
        height: var(--btn-size);
        bottom: 0;
        top: 50%;
        transform: translateY(-50%);

        &.flickity-prev-next-button {
          overflow: visible;

          &.previous,
          &.next {
            right: auto;
            left: -30px;
           // transform: translateX(-50%);
          }

          &.previous {
            
          }

          &.next {
            right: -30px;
            left: auto;
          }

          // .flickity-button-icon {
          //   left: 0;
          // }
        }
      }
  }
}