@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {

  .masthead .masthead-inner{
    display: block;
  }

  // homepage video fix
  #home-video-bg{
    min-height: 100vh;
    width: auto;
  }
  #superior-nutrition-video-bg{
    min-height: 1000px;
    width: auto;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  // product carousel fix
  .product-teaser img{
    max-width: 400px;
    padding: 0 10px;
  }

  // 3-up recipe teaser fix
  .recipe-teaser {
    img{
      min-width: 550px;
    }
    .caption{
      display: block;
      padding: 200px 10px 0 10px;
    }
  }
  .recipe-teaser:hover .caption{
    display: block;
  }

  // hero-art fix
  .hero-art{
    img{
      position: absolute;
      top:50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: auto;
      min-width: 100%;
      max-width: none;
      min-height: 400px;
    }
  }

  // recipie list fix
  .content-card-media{
    height: auto!important;
    img{
      height: auto;
    }
  }
}
