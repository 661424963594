.filter-results {
	padding-top: 5.625em;
	position: relative;

	.panel-enter,
	.panel-exit {
		animation-fill-mode: forwards;
		animation-timing-function: ease-out;
	}
	
	.panel-enter {
		animation-name: fadeup;
		animation-duration: .35s;
		opacity: 0;
		transform: translateY(3.5em);
	}

	.panel-exit {
		animation-name: fadedown;
		animation-duration: .3s;
		position: absolute;
		left: 0;
		right: 0;
		top: 5.625em;
	}

	@media(min-width: 60em) {
		margin: 0 -1.5rem;
	}
}

.filter-results-title {
	margin-bottom: 1.09375em;
}

.filter-results-list {
	flex-wrap: wrap;
	align-items: stretch;
}

.filter-result {
	margin: 0 0 .5rem;
	display: block;

	&.hide {
		display: none;
	}

	@media(min-width: 60em) {
		flex-basis: calc(100% / 3);
		max-width: calc((100% / 3) - 1.5rem);
		margin: 0 0 1.5rem 1.5rem;
	}
}