.site-foot {
	position: relative;
	font-size: .8em;
  padding-top: 3.5em;
  padding-bottom: 3em;
  background: $xlt-grey;

	@media(min-width: 60em) {
		font-size: .9em;
	}

	.eb-logo {
		max-width: 17.5em;
	}
	
	.sub-hed {
		font-size: 3.2em;
		margin: 0 1em .75em 1em;
	}

	& > * {
		margin: 16px 0;
	}

	& > .foot-lede {
		margin: 24px 0;
	}

	.social-nav {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		padding-left: 1em;
		padding-right: 1em;
		margin: 0 auto;
		row-gap: 32px;

		@media(min-width: 60em) {
			margin: 0 0 0 2.5em;
			row-gap: 0;
			display: flex;
		}

		li {
			width: 100%;
			display: flex;
			justify-content: center;

			@media(min-width: 60em) {
				margin: 0 1em;
				max-width: none;
				width: auto;
				display: block;
			}

			svg {
				width: 3.25em;
				max-height: 100%;
			}
		}
	}

	.primary-footnav,
	.secondary-footnav {
		a {
			text-decoration: none;
			color: $eb-red-on-gray;
			transition: color .125s linear;
			&:hover {
				color: $maroon;
			}


			&.invert {
				color: $white;
				background: $eb-red;
				border-radius: 0.25em;
				padding: 0.25em 0.5em 0.2em 0.5em;

				&:hover, &:focus {
					color: $white;
					background: $maroon;
				}
			}
		}
	}

	.primary-footnav {
		font-size: 1.2em;
		font-weight: bold;

		ul {
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			max-width: 500px;
			margin-inline: auto;

			@media(min-width: 60em) {
				max-width: none;
				margin-inline: unset;
			}
		}

		li {
			margin: 1em 0;
			max-width: 50%;
			width: 100%;

			@media(min-width: 60em) {
				margin: 0 1.5em;

				max-width: none;
				width: auto;
			}

			a {
				font-size: 1.4em;
				text-transform: uppercase;
				font-family: $sans-bold;
			}
		}
	}

	.secondary-footnav {
		ul {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			padding: 0 1.5em;
			max-width: 35em;
			margin: 0 auto;

			@media(min-width: 60em) {
				flex-wrap: no-wrap;
				margin: 0;
				max-width: 100%;
			}

			li {
				width: 50%;

				@media(min-width: 60em) {
					width: auto;
				}
			}
		}
	}

	.secondary-footnav,
	.site-meta {
		li {
			margin: 1em 0;

			@media(min-width: 60em) {
				margin: 1em 1em 0 1em;
			}

			a {
				font-family: $serif;
				font-size: 16px;

				@media(min-width: 60em) {
					font-size: 18px;
				}
			}
		}
	}

	.subfoot {
		display: flex;
		flex-direction: column;
		margin-top: 0;
		padding: 0 2.5em 0 2.5em;

		@media(min-width: 60em) {
			padding-top: .75em;
			margin: 2.5em auto 0 auto;
			border-top: 1px solid $xlt-grey;
		}

		.subfoot-about {
			color: $eb-blue;
			font-family: $sans;
			text-transform: uppercase;
			font-size: 1.4em;
			max-width: 45em;
			width: 100%;
			margin: 0 auto;

			h3, p.h3 {
				font-size: 1em;
				font-weight: bold;
			}
		}

		.legal {
			color: $drk-grey;
			font-size: 1em;

			p {
				margin: .25em 0;
				display: inline;
				@media(min-width: 60em) {
					display: block;
				}
			}
		}

		.subfoot-phone {
			color: $eb-red-on-gray;
			font-weight: normal;
		}

		.site-meta {
			list-style: none;
			font-size: 1.2em;
			margin: 0 1em;

			a {
				color: $eb-red-on-gray;
				text-decoration: none;
				transition: color .125s linear;

				&:hover {
					color: $maroon;
				}
			}
		}
	}

	.top-jumplink {
		background: $drk-grey;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 2.5em;
		font-family: $sans;
		text-transform: uppercase;
		color: #fff;
		text-decoration: none;
		width: 2.5em;
		font-size: 1.2em;
		border-radius: .25em;
		margin: 0 auto;
		
		@media(min-width: 60em) {
			position: absolute;
			bottom: 0;
			right: 1em;
		}
	}
}
