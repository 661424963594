$slide-width-sm: 9rem;
$slide-width-lrg:15rem;

.carousel-filters {	
	position: relative;
	background-image: linear-gradient(to top, $lt-grey 2px, transparent 2px);
	height: 9.75rem;

	@media(min-width: 60em) {
		height: 13.625rem;
	}

	.section & {
		margin: 0 -2.60416665% 0 0;

		@media(min-width: 60em) {
			margin: 0 -5.2083333% 0 0;
		}
	}

	input[type="radio"] + label {
	  padding: 0;
	  margin: 0;
	  display: block;

	  &::before,
	  &::after {
	    content: none;
	  }
	}

	a:focus,
	input:focus,
	input[type="radio"]:focus + label {
		outline: none;
	}

	[data-slide-indicator] {
		width: $slide-width-sm;
		transition: transform .45s cubic-bezier(.17,.67,.72,1.15);

		@media(min-width: 60em) {
			width: $slide-width-lrg;
		}
	}

	.carousel-filter {
		display: inline-block;
		width: $slide-width-sm;
		height: 100%;

		@media(min-width: 60em) {
			width: $slide-width-lrg;
		}

		.filter-hed {
			font-weight: 700;
			display: block;
			line-height: 1.222222;
			text-align: center;
			@media(min-width: 60em) {
				font-size: 1.111111em;
				line-height: 1.4;
			}
		}
	}

	// Flickity Style Overrides
	.flickity-button {
    width: 2.5rem;
    height: calc(100% - 1.2rem);
    top: 50%;
    transform: translateY(-50%);
    background-color: #fff;
    border-radius: 0;

    &:disabled {
    	display: none;
    }

    &:active {
    	opacity: 1;
    }

    color: $eb-red;
    
		&.next {
	    right: 0;
	    border-left: 2px solid $lt-grey;
	  }
  	
  	&.previous {
      left: 0;
      border-right: 2px solid $lt-grey;
    }
	}
}