.where-to-buy {
	
	.wtb-section {
		padding:  0 2.60416665% 2.5em !important;
	}
	
	.wtb-header {
		padding:  2.5em 2.60416665% 0 !important;
	}
	
	.complex-content {
		margin-top: 1em !important;
	}
	
	.post-body {
		max-width: 100% !important;
	}

	.content-area{
	  
	  img { 
	  	border-radius: .625em; 
	  	display: block;
	  	margin-left: auto;
	  	margin-right: auto;

			@media(min-width: 71.5rem) {
				// Not 100% sure about this
				width: 100%;
				max-width: 71.5rem; // 1144px
			}
	  }

	  div[data-basketful-product-locator] {
	  	border: 1px solid #e0d7d9;
	  }

	  * + iframe {
	  	margin-top: 0;
	  }
	}	
}