.product-detail-page, 
.recipe-detail-page {
  img { border-radius: 0.75em; }
	.inner-header {
		padding-top: 124px;
		padding-bottom: 0;

		.section {
			padding-bottom: 20px;
		}

		+ .section.scooped {
			padding-top: 0;
		}
		
		@media(min-width: 60em ) {
			padding-top: 174px;
		}
	}
}