* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

a img {
	border: none;
}

body {
	width: 100%;
	overflow-x: hidden;

	> img {
		border: 0;
		clip: rect(0 0 0 0);
		height: 1px;
		margin: -1px;
		overflow: hidden;
		padding: 0;
		position: absolute;
		width: 1px;
	}
}

main {
	display: block;
	// overflow: hidden;
}

ul,
ol {
	padding: 0;
	margin: 0;
}

img {
	max-width: 100%;
	height: auto;
}

section {
	display: block;
}

figure {
	display: block;
	margin: 0;
}

nav ul {
	list-style: none;
}

.overflow {
	overflow: hidden;
}


/* Global Micropatterns 
------------------------- */

.promo-grid {
	padding: 1.25em 0 2.5em 0;

	@media(min-width: 60em) {
		padding: 5em 0 0 0;
	}
}
.promo-double {
	flex-grow: 2;
	margin-left: 5%;
	align-self: flex-end;

	@media(min-width: 60em) {
		max-width: 66%;
	}
}
.promo-block {
  display: flex;
  justify-content: center;
  align-items: center;
	border-radius: 0.5em;
	margin: .5em 0;
	padding: 3.5em;
  width: 100%;
 
  .sub-hed {
    color: $eb-blue;
    font-size: 2em;
  }

  .sub-hed--lrg {
    font-size: 2.5em;
  }

  &.bg-blue {
    * { color: $white; }
  }

  .btn {
  	min-width: 225px;

  	+ .btn {
  		margin-left: 0;
  	}

  	@media(min-width: 34.375em) {
  		min-width: 0;

  		+ .btn {
  			margin-left: 0.75em;
  		}
  	}
  }

	@media(min-width: 60em) {
		border-radius: .425em;

		&--sm {
			max-width: 31.25rem;
		}
	}

	.lede {
		padding-top: .35em;
		margin-left: auto;
		margin-right: auto;
		width: 85%;
		max-width: 620px;
	}
	p {
		line-height: 1.4;
		font-size: 1.125em;
		margin: .25em 0;
		padding: 0 1.5em;
		// color: $xdrk-grey;
		color: $black;

		+ * { 
			margin-top: 1em; 
		}

		@media(min-width: 60em) {
			font-size: 1.25em;
			line-height: 1.3;
			margin-bottom: 0;
    }

    .promo-grid-triple & {
	    @media(min-width: 60em) and (max-width: 80em) {
	    	padding: 0;
	    }
	  }
	}

	&--small-copy {
		p {
			font-size: 18px;
		}

		@media(min-width: 60em) {
			p {
				font-size: 22px;
			}
		}
	}

	p.lede {
		font-size: 1.125em; // 18px

		&--lrg {
			font-size: 1.5em;
		}

		@media(min-width: 60em) {
			font-size: 1.5em; // 24px

			&--lrg {
				font-size: 1.75em;
			}
    } 
	}

	& > .btn {
		margin-top: 1.25em;
	}

	&.isolated {
		background: $eggshell;
		padding: 3.5em 1.25em 3.5em 1.25em;
		
		.btn-sm {
			margin-top: 1.7em;
		}

		.sub-hed {
			margin-bottom: .3125em;
		}

		a:not([class]) {
			font-weight: 700;
		}

		&.flex {
			max-width: 1700px;
			padding: 1.75em 1.25em 1.75em 1.25em;
		}
		
		&.revert {
			background: #1b3687;
			color: white;
			
			.sub-hed {
				color: white;
				font-size: 2.5em;
			}
			
			p {
				color: white;
			}
		}
	}

	&.promo-highlight {
		margin: 1em 0 2.5em 0;
		font-size: 1.2em;
		border-radius: 0 0 0.5em 0.5em;

		@media(min-width: 60em) {
			border-radius: 0.5em;
		}

		.callout-hed {
			font-size: 1.770833em; // 34px
			
			@media(min-width: 60em) {
				font-size: 3.333333em; // 64px
			}	
		}

		.callout-hed-sm {
			font-size: 28px;

			@media(min-width: 60em) {
				font-size: 48px;
			}
		}
	}

	& > * {
		justify-content: space-evenly;
    padding: 0 .5em;
    width: 100%;
	}

	&.inline-promo {
		padding: 2em 0;
		flex-direction: column;

		p + * {
			margin-top: 0;
		}

		.sub-hed {
			font-size: 1.75em;
		}

		@media(min-width: 60em) {
			.sub-hed {
				font-size: 3em;
			}

			.lede {
				font-size: 2rem;
				margin-bottom: 1.75em;
			}
		}

		@media(min-width: 80em) {
			padding: 4.5em 0;
			flex-direction: row;
			justify-content: space-between;

			& > * {
				margin-top: 0;
				margin-bottom: 0;
				padding: 0;
				width: auto;
			}

			.sub-hed {
				flex-shrink: 0;
			}

			.lede {
				max-width: 35rem;
				margin: 0 1.5rem;
			}
		}
  }

  .join-newsletter{
  	background: $eggshell;

    .flex-component__item input{
      margin:0.5em;
    }
  }
}

.btn,
.btn-naked,
button#addtocartbutton {
	height: unset;
  cursor: pointer;
	appearance: none;
	display: inline-block;
	text-decoration: none;
	font-weight: 700;
	margin-top: .75em;

	&+.btn {
		margin-left: .75em;
	}

	&:focus {
		outline: none;
		border: 1px solid transparent;
		box-shadow: 0 0 0 4px rgba($lt-blue, .35);
	}

	@media(min-width: 60em) {
		margin-top: 0;
	}
}

.btn,
button#addtocartbutton {	
	color: #fff;
	background: $eb-red;
	//font-family: $sans;
	font-family: $sans-bold;
	text-transform: uppercase;
	border-radius: .25em;
	letter-spacing: .04em;
	border: 1px solid rgba(255,255,255,.25);
	font-size: 18px;
	padding: .825em 1.2em .65em 1.2em;

	&:hover {
		background: $maroon;
		color: #fff;
	}

	&:visited,
	&:focus {
		color: #fff;
	}

	&[disabled] {
		background: $eggshell;
  }
  
  &-none {
    cursor: pointer;
    border: none;
    background: transparent;
  }

  &.btn-grey {
    background: $lt-grey;
    color: $xdrk-grey;
    transition: background 0.3s ease;

    &:hover {
      background:$med-grey;
    }
  }

	svg {
		fill: #fff;
		margin-bottom: -1px;
	}

	&.btn-sm {
		font-size: 14px;
		padding: 0.714286em 1em;
		letter-spacing: 0.05em;
		
		&.invert {
			color: #1b3687;
			border: 1px solid #1b3687;
			transition: background 0.3s ease;
			
			&:hover, &:focus {
				background: $eb-red;
				border: 1px solid $eb-red;
				color: white;				
			}
		}
	}

	&.btn-lrg {
		font-size: 22px;
		
		@media(min-width: 60em) {
			font-size: 24px;
		}
	}

	&.btn-icon {
		padding-left: 2.4em;
		position: relative; 

		.icon {
			position: absolute;
			left: 0.71em;
			top: 50%;
			transform: translateY(-50%);
		}
	}

	&.btn-secondary {
		border: 1px solid rgba(255,255,255,.75);
		background: none;

		&:hover {
			background: #fff;
			// color: $eb-red;
		}

		&[disabled] {
			background: rgba(255,255,255, .25)
		}
	}
}

.btn-naked {
	font-family: $serif;
	font-size: 1em;
	color: $eb-red;
	border: none;

	&:hover {
		color: $drk-red;
	}
}

#Botsonic-wrapper {
	max-height: 500px !important;
}

.tagged,
.more {
	text-decoration: none;
	font-family: $sans;
	text-transform: uppercase;
	margin: .5em 0;
	font-size: 1.2em;
}
.tagged {
	color: $lt-blue;
	font-family: $sans;

	.section.inverted & {
		color: $xlt-blue;
	}
}
.more {
	color: $eb-red;
	font-size: 1.4em;
	font-weight: bold;
}

.detail-cta {
	color: $eb-red;
	font-size: 1.1em;
	font-weight: bold;
	margin: 0;
	text-decoration: none;
}

.scooped {
	position: relative;
	padding-bottom: 3em;
	display: block;
	border-top: 1px solid transparent;

	.scoop {
		display: block;
		overflow: hidden;
		fill:$white;
		height: 4vw; /* IE11 can’t handle `height: auto` properly here. */;
		max-height: 4em;
		left: 0;
		position: absolute;
		right: 0;
		top: 99.9%;
		width: 100%;
		z-index: 2;
  }
}

.scooped-inv {
	padding-bottom: 2.5%;

	.scoop {
		top: auto;
		bottom: -1px;
		height: 4vw; /* See above. */
		max-height: 4em;
		left: 0;
		transform: rotate(180deg);
	}
}

.scooped-grey .scoop {
		fill:$xlt-grey;
}

.bg-upload {
	background-size: cover;
	background-position: center center;
}

.post-date,
.post-sample {
	font-size: 1.125em; // 18px
	line-height: 1.5;
	color: $xdrk-grey;
}


.post-date {
	@media(min-width: 60em) {
		font-size: 1.5em; // 24px
		line-height: 1.08;
	}
}

.post-sample {	
	letter-spacing: 0.005em;
	
	@media(min-width: 60em) {
		font-size: 1.25em; // 20px
	}
}

.faq-list {
	list-style: none;

	.faq {
		margin: 2em 0;
		padding: 2.25em 0 0 0;
		border-top: 1px solid $med-grey;

		.faq-q {
			position: relative;
			text-align: left;
			width: 100%;
			color: $xdrk-grey;
			font-size: 1.3em;
			padding: 0 2em 0 0;
			background: none;
			border: none;
			font-family: serif;

			&:focus {
				outline: none;			

			
				.icon-toggle {
					fill: $eb-blue;
					transform: rotate(45deg);
				}			
			}

			.icon-toggle {
				position: absolute;
				right: 0;
				top: 0;
				width: .75em;
				height: .75em;
				display: block;
				fill: $eb-red;
				transform: rotate(45deg);
				transition: transform .2s ease-in-out;
			}

			&[aria-expanded="true"] {
				color: $eb-blue;

				.icon-toggle {
					fill: $eb-red;
					transform: rotate(0);
				}
			}
    }
    .post-body{
      max-width: 100%;
    }
	}
}

.cooking {
	background: url(../img/home-bg.jpg) 0 0;
	background-size: 100%;
	background-repeat: no-repeat;
	padding-top: 8.5em;
	
	.scoop {
		fill: #fff;
	}

	.inner {
		max-width: 53.25rem; // 852px
		margin: 0 auto;
	}

	.cooking .recipe-thumbs {
		padding: 2.5em 0;
	}
}


.eggland-news {
	background-color: $xlt-grey;
	.scoop {
		fill: $xlt-grey;
	}
}

.result-teaser {
	margin: 2em 0;
	padding: 2em 0 0 0;

	img {
		border-radius: .35em;
		box-shadow: inset 0 0 0px 1px $lt-grey
	}

	@media(min-width: 60em) {
		display: block;

		& + & {
			border-top: 1px solid $lt-grey;
		}
	}

	@media(min-width: 80em) {
		display: flex;
	}

	.teaser-copy {
		margin-top: 1em;
		
		@media(min-width: 80em) {
			width: 100%;
			margin-top: 0;
		}
	}

	& > * + * {
		margin: 0 2.5em;

		@media(min-width: 60em) {
			margin: 0 0 0 2.5em;
		}
	}

	.teaser-title {
		text-transform: none;
		letter-spacing: normal;
		font-family: freight-text-pro,serif;
		font-size: 1.25em; // 20px
		line-height: 1.2;
		margin: .2em auto .5em auto;
		max-width: 47.25rem; // 756px

		a {
			text-decoration: none;
			color: $eb-blue;
		}

		@media(min-width: 60em) {
			font-size: 2.5em; // 40px
		}
	}

	.section.inverted & {
		margin: 3.7em 0 5em;

		.teaser-title a {
			color: #fff;
		}

		.post-date {
			color: $med-grey;
		}
	}
}

.grid-list {
	list-style: none;
	font-style: italic;
	line-height: 1.1;
	margin: 0 auto 17.5em auto;
	padding-top: 1em;
	text-transform: capitalize;
	position: relative;

	& > * {
		border: 2px solid $eb-red;
		color: #fff;
		font-size: 20px;

		background: rgba($eb-red, .7);
		border-radius: .35em;
		padding: 1em 0 .75em 0;
		font-family: $serif;

		@media(min-width: 60em) { 
			font-size: 22px;
		}

		@media(min-width: 80em) { 
			font-size: 32px;
		}
	}

	strong {
		font-family: $sans;
		text-transform: uppercase;
		font-weight: normal;
		display: block;
		font-style: normal;
	}
}

.home-header {
	padding: 45vh 0 40vh 0;
	background-repeat: no-repeat;
	background-position: top center;
  background-size: cover;
  
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right:0;
    bottom:0;
    background: rgba(0,0,0,0.4);
    background: radial-gradient(circle, rgba(0,0,0,0) 15%, rgba(0,0,0,0.5046219171262255) 82%, rgba(0,0,0,0.6502801804315477) 100%);
    z-index: 2;
  }

  @media(min-width: 80em ) {
    &:after{
      content: "";
      position: absolute;
      top: -29%;
      right: -20%;
      background: rgba(0,0,0,0);
      background: radial-gradient(ellipse at center, rgba(0,0,0,0.6) 0%,rgba(0,0,0,0) 50%,rgba(0,0,0,0) 70%);
      z-index: 2;
      height: 610px;
      width: 1500px;
      overflow: visible;
    }
  }

	.hero-hed {
		color:$eb-blue;
		max-width: 800px;

		@media(min-width: 66em) {
			width: 59.1145833%;
			padding: 1em 0 .065em 0;
		}
	}

	.home-hed {
		color: #fff;
		max-width: 960px;
		width: 90%;
		position: relative;
		z-index: 3;

		@media(min-width: 60em) {
			width: 80%;
			padding: 0;
			width: 73.893229125%;
		}
	}

	> * {
		z-index: 2;
	}

	.home-bg-video {
		position: absolute;
		width: 100%;
		top: 0;
		left: 0;
		height: 100%;
		object-fit: cover;
		z-index: 0;
		display: none;

		@media(min-width: 60em) {
			display: block;
    }
    
    
    @media screen and (prefers-reduced-motion: reduce), (update: slow) {
      display: none!important;  
    }
	}

	.scroll-trigger {
		width: 3.375rem;
		bottom: 11.644%;
		z-index: 5;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);

		svg {
			width: 100%;
			height: auto;
		}

		@media(min-width: 60em) {
			width: 5.375rem;
			bottom: 18.1209%;
		}
	}

	.btn-pause {
    display: none;
    @media(min-width: 60em) {
      display: block;
      position: absolute;
      bottom: 10vw;
      left: 24px;
      z-index: 4;
      width: 16px;
      height: 16px;
      border: none;
      background: none;
      overflow: hidden;

      &::before,
      &::after {
        content: '';
        width: 6px;
        border-radius: 2px;
        position: absolute;
        top: 0;
        bottom: 0;
        background-color: #fff;
      }

      &::before {
        left: 0;
      }

      &::after {
        right: 0;
      }

      &.paused {
        &::before {
          width: 0;
          height: 0;
          left: 4px;
          border: 8px solid transparent;
          border-left-color: #fff;
          background-color: transparent;
        }

        &::after {
          content: none;
        }
      }
    }
		
  }
  
  // homepage
  .home &{
    padding: 39vh 0 29vh 0;

    .scroll-trigger{
      bottom: 17.644%;
    }
    @media(min-width: 45em ) {
      padding:36vh 0 45vh 0;
      .scroll-trigger{
        bottom: 11.644%;
      }
    }
    @media(min-width: 60em) {
      .home-hed {
        font-size: 4.4em;
      }
      .scroll-trigger{
        width: 3.5rem;
        bottom: 20%;
      }
    }
  }
}

.hero {
	padding-bottom: 1px;
	padding-bottom: 0;
	position: relative;
	background-position: center top;
	background-repeat: no-repeat;
}

.hero .note {
	color: black;
	padding-top: .75em;
	opacity: .85;
	font-size: 16px;
	letter-spacing: .0125em;
	display: block;
	font-style: italic;
	position: relative;
	
	&::before {
		content: "*";
	}

	@media(min-width: 60em) {
		text-align: right;
	}
}

.superior-nutrition-page {	
	.hero .note {		
		@media(min-width: 960px) {
			top: -70px;			
		}	
	}	
}

.hero-lede {
	padding: .25em 1em 0 1em;

	@media(min-width: 60em) {
		padding-top: 30vh;
	}

	position: relative;
	z-index: 2;
}

.grid-list {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	& > * {
		width: 49%;
		margin: .5%;

		@media(min-width: 60em) {
			width: 24%;
			margin: .5%;
		}
	}
}

@supports( display: grid ) {
	.grid-shuffle {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 1fr 1fr;
		margin-bottom: 7.5em;
		margin: 1em auto 2.5em auto;

		& > * {
			width: auto;
		}

		& > *:nth-of-type(1) {
			grid-row: 1;
			grid-column: 1;
		}

		& > *:nth-of-type(2) {
			grid-row: 1;
			grid-column: 2;
		}

		& > *:nth-of-type(3) {
			grid-row: 2;
			grid-column: 1;
		}

		& > *:nth-of-type(4) {
			grid-row: 2;
			grid-column: 2;
		}
	}

	@media(min-width: 60em) {
		.grid-shuffle {
			grid-gap: 1.25%;
			grid-template-columns: repeat(5, 1fr);
			grid-template-rows: repeat(5, 1fr);
		}

		.grid-shuffle > *:nth-of-type(1) {
			grid-row: 3 / span 2;
			grid-column: 1;
		}

		.grid-shuffle > *:nth-of-type(2) {
			grid-row: 1 / span 2;
			grid-column: 2;
		}

		.grid-shuffle > *:nth-of-type(3) {
			grid-row: 4 / span 2;
			grid-column: 4;
		}

		.grid-shuffle > *:nth-of-type(4) {
			grid-row: 2 / span 2;
			grid-column: 5;
		}
	}
}

@media(min-width: 60em ) {
	.low-bp-only {
		display: none;
	}
}
@media(max-width: 59.9375em) {
	.high-bp-only {
		display: none;
	}
}

.page-intro {
	.lede {
		font-size: 1.125em; // 18px

		@media(min-width: 60em) {
			font-size: 1.5em; // 24px
		}
	}
}

.results-header {
	text-align: center;
}

.recipe-teasers {
	.inverted & {
		padding: 90px 0;
  }
  ul li img{
    border-radius: 0.75em;
  }

  .btn-secondary:hover {
  	color: $eb-blue;
  }
}

[data-scrollscale] {
	transform: scale(1);
	will-change: transform;
}

