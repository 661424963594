.tablist.product-info-tabs {
	$tab-width-sm: calc(98.7% / 2);
	$tab-width-lrg: 16.8125rem;

	margin: 4rem 0 0;

	fieldset {
	  border: 0;
	  padding: 0;
	}

	.controls {
		border-bottom: 2px solid $lt-grey;
		position: relative;
	}

	[data-slide-indicator] {
		width: $tab-width-sm;

		@media(min-width: 60em) {
			width: $tab-width-lrg;
		}
	}

	.control {
		text-align: center;
		width: $tab-width-sm;

	  label {
	    font-size: 1.125em; // 18px
	    font-weight: 700;
	    line-height: 1.222222;
      padding-bottom: 1.5em;
      text-align: center;
	    img,
	    .title {
	    	display: block;
	    	margin: 0 auto;
	    }

	    img {
	    	width: 100%;
	    }
	  }

	  @media(min-width: 60em) {
	  	width: $tab-width-lrg;

	    label {
	      font-size: 1.5em; // 24px
	    }
	  }
	}

	input[type="radio"] + label {
		color: $eb-blue;
	}

	a:focus,
	input:focus,
	input[type="radio"]:focus + label {
		outline: none;
	}

	.panel {
		text-align: left;
	  padding: 3.125rem 0 0;
	  
	  &.active {
		  animation: fadeup .45s ease-out;
		  animation-fill-mode: forwards;
	  }

	  @media(min-width: 60em) {
			padding-top: 6.25rem;

		  .flex {
		  	align-items: flex-start;
		  	.col {
		  		width: calc((100% / 4) - 6px); // 24px gutters

		  		&.col-wide {
		  			width: calc((100% / 2) - 12px); // 24px gutters
		  		}

		  		+ .col {
		  			margin-left: 1.5rem;
		  		}
		  	}
		  }
		}
	}
}