// Colors updated in 11/11/21 for a11y purposes.
// $eb-red: #e52e2e; 
$eb-red: #E42525;
$eb-red-on-gray: #DA1B1B;
$drk-red: #a31414;
$maroon: #7a0505;
// $xlt-blue: #73bee4;
$xlt-blue: #217DAB;
// $lt-blue: #0082ca;
$lt-blue: #0074B3;
$eb-blue: #1b3687;
$drk-blue: #01144d;
$white: #fff;
$eggshell: #f9f7f8;
$xxlt-grey: #EDEBEB;
$xlt-grey: #f5f2f3;
$lt-grey: #e0d7d9;
$med-grey: #b8b0b6;
// $drk-grey: #8d838f;
$drk-grey: #716873;
$xdrk-grey: #5a5666;
$black: #1d1d1d;

$serif: freight-text-pro,serif;

//$sans: bureau-grot-condensed,sans-serif;
$sans: "Bureau Grot Cond Bk",sans-serif;
$sans-bold: "Bureau Grot Cond Bd",sans-serif;
