.product-info {
  &__actions {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    max-width: 440px;
    margin-inline: auto;

    > * {
      width: 100%;

      > * {
        width: 100%;
      }
    }

    .btn {
      color: $white;
      background: $eb-red;
      max-width: 300px;
      margin-inline: auto;

      .icon {
        fill: $white;
      }

      .icon {
        transform: translate(4px, -4px);
      }

      &.toggle-open {
        margin-bottom: 1rem;

        .icon {
          transform: translate(4px, -4px) rotate(180deg);
        }
      }

      &:hover,
      &:focus,
      &.toggle-open {
        color: $white;
        background: $drk-red;

        .icon {
          fill: $white;
        }
      }
    }

    div[data-sc-id] {
      font-family: $serif;
      // color: $white !important;
      // margin-inline: 0;
      // max-width: 100%;
      min-width: 300px;
      background-color: transparent !important;
      box-shadow: none !important;
      border: 1px solid $eb-red !important;
      border-radius: .425em !important;

      @media(min-width: 60em) {
        min-width: 400px;
      }
    }

    .fa-chevron-right,
    .fa-chevron-left,
    .change-location-cta {
      color: $eb-red !important;
    }

    .call-to-action {
      font-weight: normal !important;
    }

     .sc-smartsite-footer {
      justify-content: center !important;
      font-family: $sans !important;
      text-transform: uppercase;
      font-size: 8px;
      font-weight: 700;
     }

    .retailer-wrapper {
      // max-width: 80% !important;
      // min-width: none !important;
      // margin-inline: 0 !important;
    }
  }
}