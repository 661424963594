.star-rating {
	--star-width: 26px;
	--star-height: 28px;
	--star-color: #ebeef2;
	--active-width: 0;

	position: relative;
	display: inline-block;

	&__stars--bright {
		--star-color: #ffd34f;

		position: absolute;
		top: 0;
		left: 0;
		width: var(--active-width);
		overflow: hidden;
		white-space: nowrap;
	}

	&__stars--dim {}

	&__stars {
		// display: flex;
	}

	&__star {
		display: inline-block;
		width: var(--star-width);
		height: var(--star-height);
		fill: var(--star-color);
	}

	@media(min-width: 60em) {
		--star-width: 31.2px;
		--star-height: 33.6px;
	}
}