html[lang|=es] {
	
	@media (min-width: 80em) {
		
		.top-nav {
			display: none;
		}		
		
		.masthead {
			
			.nav-secondary {
				
				.subnav.meta-nav a {
					font-size: 1.2em;
					font-weight: bold;
				}
				
				.search-high-bp {
					padding-left: 10px;
				}
			}			
		}		
	}
	
	
	.superior-es {
		text-align: center;
	}
}