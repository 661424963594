.search-filters {
  position: relative;
  z-index: 1;

	fieldset {
		border: none;
		padding: 0;
		margin: 0;
		text-align: left;
		max-width: 1152px;
		margin: 0 auto;
	}
	
	input, 
	select,
	.filter-toggle, 
	.col {
		width: 100%;
	}
	
	input[type="search"]::-webkit-search-decoration,
	input[type="search"]::-webkit-search-results-button,
	input[type="search"]::-webkit-search-results-decoration {
		// Removes Safari search input styling
	  -webkit-appearance:none;
	}

	input[type="search"] {
		background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M19.572%2017.894l5.8%205.801a1%201%200%200%201%200%201.414l-.707.707a1%201%200%200%201-1.414%200l-5.861-5.861A10.95%2010.95%200%200%201%2011%2022C4.925%2022%200%2017.075%200%2011S4.925%200%2011%200s11%204.925%2011%2011c0%202.61-.91%205.008-2.428%206.894zM11%2019a8%208%200%201%200%200-16%208%208%200%200%200%200%2016z%22%20fill-rule%3D%22evenodd%22%20fill%3D%22%238d838f%22%2F%3E%3C%2Fsvg%3E');
		background-repeat: no-repeat;
		background-size: 26px 26px;
		background-position: right 10px top 50%;
		padding-right: 48px;
	}
	
	// The show/hide button (visible on mobile)
	.filter-toggle { 
		position: relative;
		display: block;
		font-size: 1.25em;
		letter-spacing: normal;
		line-height: 1.2;
		padding: 0;
		margin: 0 0 24px;
		text-align: left;

		&, &:hover {
			background: transparent;
			color: $eb-blue;
		}

		svg {
			fill: $eb-red;
			transition: transform .2s ease-in-out;
			transform: rotateZ(0deg);
			position: absolute;
			right: 0;
			top: 7px;
		}

		&.toggle-open {
			svg {
				transform: rotateZ(180deg);
			}
		}
	}

	.submit-wrap {
		border-top: 1px solid $lt-grey;
		padding: 1.875em 0;
		text-align: center;
		
		/* Sometimes the submit container becomes a column on larger screens, 
		 * and in some cases it doesn't. 
		 *
		 * .submit-wrap--sm-only attempts to bridge that gap.
		 */

		&--sm-only {
			width: auto;
			
			@media(min-width: 60em ) {
				border: none;
				padding: 0;
				text-align: inherit;
				margin-bottom: 1.5rem;
			}
		}
	}

	.col {
		margin: 0 0 1.5rem;
	}

	.row + .row {
		margin-top: 1.875em;

		&.submit-wrap {
			margin-top: 0;
		}
	}

	@media(min-width: 60em) {

		.a11y-only.a11y-override {
			clip: auto;
			height: auto;
			width: auto;
			margin: 0;
			overflow: visible;
			position: static;
		}

		.row,
		.row-lrg-only {
			display: flex;
			justify-content: center;
			align-items: flex-end;
		}

		.row-lrg-only {
			width: 100%;

			&.a11y-override {
				width: 100%;
			}
		}

		.col {
			max-width: 26rem;

			&.minor {
				max-width: 16.75rem;
			}

			&.full-width {
				max-width: none;
			}
		}

		.col,
		.sm-screen-only,
		.row-lrg-only {

		/* 
		 * Managing horizontal margins on columns across layout configurations, 
		 * and in cases where a column is sometimes a row on smaller screens.
		 *
		 * This will likely need to be updated if the markup changes much, 
		 * or the component becomes more modular.
		 */

			+ .col,
			+ .row-lrg-only,
			+ .submit-wrap--sm-only {
				margin-left: 1.5em;
			}
		}
	}
}
