.masthead {
	$this: &;

	@media( max-width: 79.9375em ) {
		&.masthead-open {
			background: $eggshell;
			& ~ * {
				display: none;
			}
		}
	}

	z-index: 9;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 5;

	@media(min-width: 65em) {
		top: -15px;
	}

	&.masthead-detail {
		@media(min-width:80em) {
			border-bottom: 1px solid $lt-grey;

			&.masthead-no-border {
				border-bottom: none;
			}
		}
	}

	@media(min-width: 80em ) {
		background: transparent;
	}

	.masthead-inner {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-around;
		margin: 0 auto;

		@media(min-width: 80em) {
			padding: .5em 5.2083333% 0 5.2083333%;
		}
	}

	.sitehead {
		padding: 0;
		top: 0;
		position: absolute;
		bottom: 0;
		min-height: 100%;
		display: none;
		z-index: 9999;
		background: $eggshell;
		width: 100%;

		@media(min-width: 80em) {
			position: relative;
		}

		.sitehead-top {
			padding-bottom: 5em;
			position: relative;
			background: #fff;

			@media(min-width: 80em) {
				display: none;
				background: none;
			}
		}

		.small-bp-search {
			position: absolute;
			bottom: .25em;
			left: 0;
			right: 0;
		}

		@media(min-width: 50em) {
			// padding-top: 1.75em;

			.sitehead-top {
				padding-bottom: 1.5em;
			}
			.small-bp-search {
				position: static;
				flex-grow: 2;
				z-index: 3;
			}
		}

		@media(min-width: 80em) {
			background: none;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: row;
		}
	}

	.sitehead-top {
		display: flex;
		left: 0;
		top: 0;
		width: 100%;
		padding-top: 1em;
		justify-content: space-between;
		align-items: center;
		flex-direction: row-reverse;

		@media(min-width: 80em) {
			position: static;
			flex-direction: row;
			width: auto;
			margin: 0;
		}

		.menu-toggle {
			color: $drk-grey;
			margin-right: 1em;
			border: 1px solid $med-grey;
			border-radius: .25em;

			@media(min-width: 80em) {
				display: none;
			}

			svg {
				margin-left: .75em;
			}

			.open-text,
			.closed-text {
				align-items: center;
				display: flex;
			}

			.open-text {
				color: $drk-grey;

				svg {
					margin-left: .75em;
					fill: $drk-grey;
				}
			}

			.closed-text {
				color: $xlt-grey;
				svg {
					fill: $xlt-grey;
				}
			}

			&[aria-expanded="false"] .open-text {
				display: none;
			}

			&[aria-expanded="true"] .closed-text {
				display: none;
			}	
		}
	}

	.flyout.search-flyout {
		position: absolute;
		background: #fff;
		top: 100%;
		right: 5%;
		border: 1em solid rgba(255,255,255,1);
		border-radius: .5em;

		input {
			border-radius: .25em 0 0 .25em;
		}
		button {
			border-radius: 0 .25em .25em 0;
			svg.icon-search {
				margin-bottom: -2px;
				fill: #ccc;
			}
		}

		&[aria-hidden="false"] {
			display: flex;
		}
	}

	@media(min-width: 80em ) {
		.sitehead {
			display: flex;
			min-height: 7.5em;
			align-items: center;
			padding: 1.5em 0 0 0;
			flex-direction: row;
			justify-content: space-between;
		}
	}

	.eb-logo {
		display: flex;
		margin: 0;
		
		a:focus {
			outline: none;
		}

		img,
		svg {
			width: 100%;
			max-width: 200px;
			align-items: center;
			@media(min-width: 80em ) {
				max-width: 250px;
			}
		}
	}

	a,
	button {
		font-family: $sans;
		font-size: 16px;
		color: #fff;
		text-decoration: none;
		letter-spacing: .0125em;
		padding: .75em 1em .65em 1em;
		text-transform: uppercase;

		.icon {
			fill: #fff;
		}

		&:hover,
		&:visited,
		&:focus,
		&:active	{
			color: #fff;
		}

		&.btn-search{
      transition: background 0.3s ease, box-shadow 0.3s ease;
      box-shadow: 0 0 10px 0 rgba(0,0,0,0);
      &:hover,
      &:focus {
        background: rgba(255,255,255, 0.2);
        box-shadow: 0 0 10px 0 rgba(0,0,0,1);
      }
    }
	}

	button {
		background: none;
		border: none;
		appearance: none;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;

		svg {
			margin-left: .35em;
			margin-bottom: .125em
		}
	}

	.top-nav {
		width: 100%;

		@media(min-width: 80em) {
			width: auto;
			display: flex;
			flex-direction: row;

			button svg {
				pointer-events: none;
			}
		}
	}
	.masthead-nav {
		display: flex;
		flex-direction: column;
	}

	.nav-primary {
		order: 1;
		background: $eb-blue;

		@media(min-width: 80em ) {
			background: none;
		}
	}

	.nav-secondary {
		order: 2;
		display: flex;
		flex-direction: column;
		padding-bottom: 1em;

		@media( max-width: 79.9375em ) {
			.search-high-bp {
				display: none;
			}
		}

		.header-promo {
			order: 2;
			margin: .25em 2.5em 2em 2.5em;

			p {
				margin: 1em 0;
			}

			@media(min-width: 80em) {
				display: none;
			}
		}

		.subnav {
			order: 3;
			display: flex;

			li {
				margin: 0;
			}

			&.meta-nav {
				display: block;
				padding: .5em 0 1.5em 0;
				justify-content: center;
				text-align: left;
				
				@media(min-width: 60em) {
					display: flex;
				}

				@media(min-width: 80em) {
					padding: 0;

					li {
						margin: 0 1em 0 0;
					}
				}

				a {
					color: $eb-red;
					font-size: 1.2em;
					display: inline-block;
					padding: 0.25em 1em 0.25em 1em;

					@media(min-width: 60em) {
						display: inline;
						padding: 0.75em 1em 0.65em 1em;
					}

					@media(min-width: 80em) {
						color: #fff;
            font-size: 1em;
            position: relative;
            &:after{
              content:'';
              display: block;
              opacity: 0;
              height: 2px;
              width: 0;
              background: $white;
              position: absolute;
              bottom: 0.15em;
              left: 50%;
              right: 0;
              transform: translateX(-50%);
              transition: width 0.3s ease, opacity 0.3s ease;
            }
            
            &:hover, &:focus{
            	outline: none;
              
              &:after{
                opacity: 1;
                width: 90%;
              }
            }
					}
				}
			}

			&.social-nav {
				align-items: center;
				justify-content: center;

				@media( max-width: 79.9375em ) {
				//	padding: 2em 1em 1em 1em;
					order: 1;
				}
         a{
           transition: opacity 0.1s ease;
           opacity: 1;
           &:hover,
           &:focus{
            opacity: 0.7;
           }
         }
				svg {
					width: 1.25em;
					fill: #fff;

					@media( max-width: 79.9375em ) {
						fill: $eb-blue;
						width: 2em;
					}
				}
			}
		}
	}

	.masthead-inner .subnav.meta-nav a::after {
		background: $drk-blue;
	}

	@media(min-width: 80em ) {
		.masthead-nav {
			display: block;
		}

		.nav-secondary,
		.nav-primary {
			flex-direction: row;
			justify-content: flex-end;
		}
	}

	.nav-primary a,
	.nav-primary button,
	.nav-secondary a,
	.nav-secondary button {
		@media(min-width: 80em ) {
			padding: .25em .5em .2em .5em;
		}
	}
	.nav-primary a,
	.nav-primary button {
		font-size: 19px;  // changed to 19 when adding wtb to the nav
		font-family: $sans-bold;
		display: block;
		width: 100%;
		text-align: left;
		border-top: 1px solid $drk-blue;
    position: relative;

		&[aria-expanded="true"] {
			@media( max-width: 79.9375em ) {
				background: $drk-blue;
			}

			svg {
				@media( max-width: 79.9375em ) {
					transform: rotate(180deg);
					margin-bottom: .16em
				}
			}
		}

		&:hover,
		&:focus {
			outline: none;
		}

		@media(min-width: 80em ) {
      border-top: none;
    
      &:after{
        content:'';
        display: block;
        opacity: 0;
        height: 2px;
        width: 0;
        background: $eb-red;

        position: absolute;
        bottom: -0.2em;
        left: 50%;
        right: 0;
        transform: translateX(-50%);
        transition: width 0.375s ease, opacity 0.375s ease;
      }
      
      &.is-hovered,
      &:hover, 
      &:focus{
        &:after{
          opacity: 1;
          width: 90%;
        }
      }
    }
	}

	.btn-search {
		margin-left: .65em;
	}

	.has-flyout {
		position: relative;

		@media(min-width: 80em ) {
			cursor: pointer;
		}

		button {
      cursor: pointer;
     
			&::before {
				content: "";
				position: absolute;
				top: 175%;
				height: .5em;
				left: .5em;
				right: .5em;
				background: $eb-red;
				z-index: 3;
				opacity: 0;
				transition: opacity .25s linear;
			}

			@media(min-width: 80em ) {
				//pointer-events: none;

				&.toggle-explanded {
					background: $drk-blue;
					&::before {
						opacity: 1;
					}
				}
			}
		}

		.nav-flyout {
			text-align: left;
			overflow: hidden;

			@media(min-width: 80em ) {
				border-radius: 1em;
				position: absolute;
				box-shadow: 0 0 1em rgba(0,0,0,.25);
				top: 175%;
				left: 50%;
				width: 600px;
				margin-left: -300px;
			}

			.flyout-subwrap {
				@media(min-width: 80em ) {
					align-items: stretch;
					justify-content: flex-start;
					& > * {
						width: 50%;
						padding: 1.5em 2.25em;
					}
				}

				.flyout-subnav {
					font-size: 1.2em;
					background: $drk-blue;
					width: 100%;

					@media(min-width: 80em ) {
						width: 50%;
						padding: 1.5em 1em 1em 1em;
						background: #fff;
					}

					a {
						font-family: $sans;
						font-weight: normal;
						display: block;
						font-size: 1em;
						padding: .75em 1.15em;

						&:after {
							content: none;
						} 

						@media(min-width: 80em ) {
							font-size: .95em;
							margin: .75em 0;
							padding: .25em 1.5em;
							color: $eb-red;

							&:hover,
							&:focus {
								color: $drk-red;
							}
						}
					}
				}

				.flyout-detail {
					@media( max-width: 79.9375em ) {
						display: none;
					}

					@media(min-width: 80em ) {
						background: $xlt-grey;

						.nav-hed {
							font-size: 1.4em;
							margin-bottom: .25em;
							margin-top: 1em;
							& + p {
								margin-top: .5em;
							}
						}
					}

					p {
						color: $xdrk-grey;
						font-size: 1.2em;
					}
				}
			}
		}
	}

	&.masthead-detail {
		.menu-toggle {
			.closed-text {
				color: $eb-blue;
				svg {
					fill: $eb-blue;
				}
			}
    }
		@media(min-width: 80em) {
			.nav-primary {
				a,
				button {
					color: $eb-red;
					svg.icon {
						fill: $eb-red;
					}

					&.invert {
						color: $white;
						background: $eb-red;
						border-radius: 0.25em;
						margin-left: 4px;

						&::after {
							content: none;
						}

						&:hover, &:focus {
							background: $maroon;
						}
					}
				}
			}

			.nav-secondary {
				a,
				button {
					color: $eb-blue;
					border-color: $eb-blue;
					svg.icon {
						fill: $eb-blue;
					}

					&.invert {
						span {
							color: $white;
							background: $eb-blue;
							border-radius: 0.25em;
							padding: 0.25em 0.5em 0.2em 0.5em;
						}

						&::after {
							content: none;
						}

						&:hover, &:focus {
							span {
								background: $drk-blue;
							}
						}
					}
				}

				.meta-nav {
					a,
					button {
						color: $eb-blue;
					}
				}
      }
      
      .inner-header{
        padding-top: 17.5em;
      }
		}
	}

	.small-bp-search {
		display: flex;

		form {
			flex-grow: 2;
		}

		.seamless-input {
			width: 100%;
			padding: 1em;

			input {
				height: 100%;
				width: 100%;
			}
		}

		label {
			flex-grow: 2;
		}

		@media(min-width: 80em ) {
			display: none;
		}
	}
}

.inner-header {
	padding: 10em 0 4em;
	overflow: hidden;
	z-index: 1;
	position: relative;
  width: 100%;
  
  .general-detail-page &{
    padding: 10em 0 0;
  }

	[class*="-hed"] {
		margin: 0 auto;
	}

	&.inverted {
		background: $eb-blue;

		[class*="-hed"], p {
      color: rgba(255,255,255,.975);
      text-shadow: 0 0 25px rgba(0,0,0,.4);
		}

		.breadcrumb a {
			color: rgba(255,255,255,.95);
		}
  }
  
  @media(min-width: 80em ) {
    padding: 14em 0 6em 0;
  }
}