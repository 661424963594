.tint-gallery {
  padding-bottom: 0 !important;

  &__inner {

  }

  .tintup {
    max-width: 100% !important;
    margin-inline: auto !important;
  }

  @media(min-width: 45em) {
    .tintup {
      max-width: 960px !important;
    }
  }

  @media(min-width: 60em) {
    padding-bottom: 3em !important;

    .tintup {
      max-width: 100% !important;
    }
  }
}

.section:has(.tint-gallery),
.section.tint-gallery {
  padding-top: 0;

  @media(min-width: 60em) {
    padding-top: inherit;
  }
}