.breadcrumb {
	list-style: none;
	margin: 1.5em;
	display: none;

	@media(min-width: 60em) {
		display: flex;
	}

	li + li {
		border-left: 2px solid rgba($xlt-grey, .65);
		margin-left: 1em;
		padding-left: 1em;
	}
	
	a {
		color: $xdrk-grey;
		text-transform: uppercase;
		font-family: $sans;
		font-size: 1.15em;
		text-decoration: none;
		letter-spacing: 0.05em;
		&[aria-current="page"] {
			color: $lt-blue;
		}
	}
}