.innernav {
	font-size: 1em;
	background: $eb-blue;
	position: relative;
  z-index: 1;
  margin-top: -3px;
	@media(min-width: 60em) {
    margin-top:0;
		padding: .5em;
    
    .a11y-only.a11y-override {
			clip: auto;
			height: auto;
			overflow: visible;
			position: static;
			width: auto;
		}
  }
  
	@media(max-width: 59.9375em) {
		.inset {
			margin-top: 0;
			margin-bottom: 0;
		}
  }
  
	.innernav-toggle {
		@media(min-width: 60em) {
			display: none;
		}
	}

	ul,
	ol {
		list-style: none;
		margin: 0-auto !important;
	}

	.inset {
		margin: 0 auto;
	}

	li {
		padding: 0;

		@media(min-width: 60em) {
			padding: 0 .5em;
		}
	}

	a,
	button {
		padding: 1em 0 .7em 1em;
		text-align: left;
		display: block;

		@media(min-width: 60em) {
			padding: 18px 0;
		}
	}

	&.faq-innernav {
		background: none;

		@media(min-width: 60em) {
			border-top: 2px solid $lt-grey;
		}

		li + li {
			border-top: 1px solid $lt-grey;
		}

		a,
		button {
			color: $eb-blue;
			text-align: left;
			padding: 1em 1.5em 1em 0;
		}
	}

	.innernav-toggle {
		font-size: 1.2em;
	}

	a,
	button {
		color: #fff;
		letter-spacing: 0.0125em;
		text-decoration: none;
		font-size: 1.2em;
		font-family: $sans-bold;
		//font-weight: bold;
		text-transform: uppercase;

		@media(	min-width: 60em ) {
			font-size: 1.4em;
		}
	}

	button {
		background: none;
		border: none;
		position: relative;
		appearance: none;
		width: 100%;
		
		svg {
			fill: #fff;
			position: absolute;
			right: 1em;
			margin-top: -3px;
			top: 50%;

			@media(min-width: 60em) {
				display: none;
			}
		}

		&.toggle-open {
			svg {
				transform: rotate(180deg);
			}
		}
	}
	
	.nav-active a {	
		position: relative;

		&::after {
			position: absolute;
			right: 0;
			bottom: -8px;
			left: 0;
			height: 8px;
			background: $eb-red;
		}

		@media(min-width: 60em) { 
			&::after {
				content: '';
			}
		}
	}

	&.faq-innernav {
		button {
			color: $eb-red;
			
			svg {
				right: 0;
				fill: $eb-red;
      }
      

			@media(min-width: 60em) {
        display: none;
      }
		}
	
		.nav-active,
		.nav-active a {
			color: $eb-red;
		}
  }
  
  &.bg-grey {
    background: $xlt-grey;
    a {
      color: $eb-blue;
    }

    button.innernav-toggle {
    	color: $eb-red;
			
			svg {
				fill: $eb-red;
			}
    }
  }
}
