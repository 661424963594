.frame {
  --w: 16;
  --h: 9;
  padding-bottom: calc(var(--h) / var(--w) * 100%);
  position: relative;
}

.frame > *,
.frame > picture > *  {
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.frame > img,
.frame > video, 
.frame > iframe,
.frame > picture > * {
  width: 100%;
  height: 100%;
  object-fit: cover;
}