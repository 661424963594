.nutrition-info  {
	max-width: 768px;
	margin-inline: auto;
}

.nutrition-facts-panel {
	background-color: #fff;
	border-radius: .375rem;
	margin-top: 3.375rem;
	padding: 0 1.5rem;
	color: $xdrk-grey;
	text-align: left;

	.sub-hed {
		text-align: center;
		margin: 0 -1.5rem;
		padding: 0;
		font-size: 1.5em;
	}

	.toggle-hed {
		position: relative;
		background: transparent;
		display: block;
		padding: 1.5rem;
		margin: 0;
		width: 100%;
		font-size: 1em;
		letter-spacing: 0;
		text-align: left;

		&:hover,
		&:active,
		&:focus {
			color: currentColor;
		}

		svg {
			fill: $eb-red;
			transition: transform .2s ease-in-out;
			transform: rotateZ(0deg);
			position: absolute;
			right: 1.5rem;
			top: 50%;
	    transform: translateY(-50%);
		}

		&.toggle-open {
			svg {
				transform: rotateZ(180deg);
			}
		}
	}

	@media(min-width: 60em) { 
		// padding: 2.5rem 9.125rem;

		.sub-hed {
			// margin: 0 -9.125rem 3rem;
			// padding: 0.125em 0 0.666667em;
			font-size: 3em;
			// border-bottom: 2px solid $lt-grey;
		}

		// .a11y-only.a11y-override {
		// 	clip: auto;
		// 	height: auto;
		// 	width: auto;
		// 	margin: 0;
		// 	overflow: visible;
		// 	position: static;
		// }
	}
}

.nutrition-facts-detail {
	position: relative;

	&::after {
		content: '';
		height: 2px;
		background-color: $lt-grey;
		position: absolute;
		top: 0;
		right: -1.5rem;
		left: -1.5rem;
	}

	> :first-child {
		padding-top: 2.375rem;
	}

	@media(min-width: 60em) {  
		&::after {
			content: none;
		}

		> :first-child {
			// padding-top: 0;
		}
	}
}

.serving-details {
	font-size: 1.125em; // 18px
	line-height: 1.5;
	margin-bottom: 1.375rem;

	p {
		margin: 0;
	}
	@media(min-width: 60em) {
		font-size: 1.25em; // 20px
	}
}

.nutrition-facts {
	border-collapse: collapse;
	caption, 
	thead th {
		text-align: left;
		margin: 0;
		padding-bottom: .55em;
		text-transform: uppercase;
		border-bottom: .25rem solid $eb-blue;
	}
	width: 100%;

	caption,
	thead th,
	tr td {
		font-family: $sans-bold;
		color: $eb-blue;
		font-size: 1rem; // 16px
		//font-weight: 700;
	}

	caption {
		@media(min-width: 60em) {
			font-size: 1.25rem; // 20px
		}
	}

	tr {
		th,
		td {
			padding: .5em 0;
		}
		td {
			text-align: right;
		}
		th {
			text-align: left;
		}
		&:not(:first-child) {
			td, th {
				border-top: 1px solid $lt-grey;
			}
		}
	}

	thead th {
		font-size: 1.25rem; // 20px
		font-weight: 700;

		&[scope="col"] {
			text-align: right;
		}
	}

	tbody {
			th {
			font-weight: normal;
			font-size: 1.125rem; // 18px
			line-height: 1.111111;

			@media(min-width: 60em) {
				font-size: 1.25rem; // 20px
				line-height: 1.5;
			}
		}

		td {
			line-height: 1.5;
			font-weight: 400;
			
			@media(min-width: 60em) {
				font-size: 1.125rem; // 18px
			}
		}
	}

	tfoot {
		td {
			padding: .75rem 0;
			color: $xdrk-grey;
			font-size: 1em;
			line-height: 1.5;
			font-weight: 400;
			font-family: $serif;
			text-align: center;
		}
	}

	.rule-btm {
		border-bottom: .25rem solid $eb-blue;
	}

	.emphasize {
		td, th {
			font-weight: 700;
		}
	}

	.indent {
		display: block;

		@media(min-width: 60em) {
			text-indent: 3.15%;
		}
	}
}