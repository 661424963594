.hero-art {
	position: absolute;
	bottom: 0;
	z-index: 0;
	top: 0;
	right: 0;
	left: 0;
	overflow: hidden;
  background-size: cover;

  .inner-header & {
    &:before {
      content: '';
      position: absolute;
      top:0;
      bottom:0;
      right:0;
      left:0;
      z-index: 1;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0) 35%, rgba(0, 0, 0, 0) 65%, rgba(0, 0, 0, 0.5));
    }
  }

  &--texture {
    display: none;
    
    .inner-header & {
      &:before{ 
        background-image: linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
       }
    }
  }
  
	div {
		background-position: center top;
		background-size: cover;
  }
  
	video {
		min-width: 100%;
  }
  
  img {
    object-fit: contain;
    object-position: center;
    height: 100%;
    width: 100%;
  }
  
  .inner-header & {
    z-index: 0;
		overflow: hidden;

		div {
			height: 100%;
			width: 100%;
		}

		& + div {
			position: relative;
			z-index: 1;
    }
    
    img{
      object-fit: cover;
    }
  }
}