.recipe-teaser {
	position: relative;
	overflow: hidden;
	border-radius: .5em;
	margin: auto auto 1.5em auto;
	height: 30em;

	@media(min-width: 60em) {
		margin: 0;
		max-width: 550px;
		height: 100%;
		&+.recipe-teaser {
			margin: 0 0 0 1.5em;
		}
	}

	.caption {
    	opacity: 1;
		background: rgba(0,0,0,.6);
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 0 10% 4em 10%;
		color: #fff;
		transition: opacity .25s ease-in-out;

		@media (pointer: fine) {
			display: none;
		}

		p {
			font-size: 1.1em;
			line-height: 1.4;
		}

		.recipe-hed {
			color: #fff;
			margin-bottom: .5em;
			margin-top: auto;
			font-size: 1.5em; // 24px
			letter-spacing: -0.000417em;
			font-weight: 600;
			-webkit-font-smoothing: antialiased;
			font-smooth: antialiased;

			& + p {
				margin-top: 0;
			}

			@media(min-width: 60em) {
				font-size: 2em; // 32px
				letter-spacing: -0.000625em;
			}
		}
		.btn {
			&:hover,
			&:focus {
				background: none;
				border: 1px solid #fff;
			}
		}
	}

  img {
    transition: transform .5s ease-in-out;
    margin: 0 auto;
    transform: scale(1.05);
    object-fit: cover;
    object-position: center;
    min-height: 35em;
    width: 100%;
    transform: scale(1.01);
  }
  
  @media(min-width: 60em) {
    
    img {
      transform: scale(1.05);
    }
    
    .caption {
      opacity: 0;
    }

    &:hover,
    &:focus-within {
      img {
        transform: scale(1.01);
      }
      .caption {
        opacity: 1;
        display: flex;
      }
    }
  }
}