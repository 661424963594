.nutrition-toggles {
	max-width: 986px;
}

	.nutrition-toggle {
		margin: 0;
		padding: 2.25em 0 0 0;
		background: $white;
		border: 1px solid $med-grey;
		border-radius: 0.375rem;

		&__trigger {
			position: relative;
			text-align: left;
			width: 100%;
			color: $xdrk-grey;
			// font-size: 1.3em;
			padding: 0 4.5rem 0 2rem;
			background: none;
			border: none;
			font-family: serif;

			&:focus {
				outline: none;			

			
				.icon-toggle {
					fill: $eb-blue;
					transform: rotate(45deg);
				}			
			}

			.icon-toggle {
				position: absolute;
				right: 1.5rem;
				top: 0;
				width: 1.5rem;
				height: 1.5rem;
				display: block;
				fill: $eb-red;
				transform: rotate(45deg);
				transition: transform .2s ease-in-out;
			}

			&[aria-expanded="true"] {
				color: $eb-blue;

				.icon-toggle {
					fill: $eb-red;
					transform: rotate(0);
				}
			}

			@media(min-width: 60em) {
				.icon-toggle {

					right: 2rem;
					top: 16px;
					width: 2rem;
					height: 2rem;
				}
			}
    }

    &__hed {
    	font-size: 1.75rem; // 28px
    	text-align: left;
    	padding-bottom: 2.25rem;

    	@media(min-width: 60em) {
	    	font-size: 3rem; // 48px
	    }
    }

    &__panel {
    	.controls {
    		margin-top: 2rem;
    	}
    }
    
    .post-body{
      max-width: 100%;
			border-top: 1px solid $med-grey;
    }

    .nutrition-facts-panel {
    	border-left: 1px solid $med-grey;
    	border-right: 1px solid $med-grey;
    	border-bottom: 1px solid $med-grey;
    }
	}