.superior-hero {
	
	section.scooped {
		
		&::before {
			content: "*when compared to ordinary eggs.";
			display: block;
			font-family: $serif;
			text-transform: none;
			color: black;
			font-weight: normal;
			font-size: 16px;
			position: relative;
			top: -100px;
			
			@media(min-width: 60em) {
				top: -130px;
				font-size: 17px;
			}			
			
			@media(min-width: 80em) {
				top: -170px;
				font-size: 18px;
			}
		}		
	}	
}


html[lang|=es] {

	.superior-nutrition-page {
		
		.scooped.hero {
			
			.scooped {
				
				&::before {
					content: "*Cuando se compara con huevos ordinarios.";
					display: block;
					font-family: $serif;
					text-transform: none;
					color: black;
					font-weight: normal;
					font-size: 16px;
					position: relative;
					top: -100px;
					
					@media(min-width: 60em) {
						top: -130px;
						font-size: 17px;
					}			
					
					@media(min-width: 80em) {
						top: -170px;
						font-size: 18px;
					}
				}			
			}		
		}
	}
}