.content-forte {
  padding: 8em 0 6em;
  margin: 0 auto;

  .inverted & {
    color: $white;
  }

  > *:not(.flex) {
    display: block;
    text-align: center;
    margin-bottom: 1.25rem;
  }

  .social-nav a {
    width: 31px;
  }
}

