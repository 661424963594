.awards{
  margin-top: 4em;
  @media(min-width: 36em) {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: center;
    align-items: baseline;
    .award {
      width: 50%;
    }
  }
  @media(min-width: 80em) {
    .award {
      width: 29%;
    }
  }
}
.award{
  padding: 0 2% 4em 2%;
  img{
    max-width: 100%;
    width: auto;
  }
  time{
    font-size: 1.4em;
    color: $eb-red;
    font-family: $sans-bold;
  }
}