[aria-hidden="false"].flyout {
	display: block;
}
[aria-hidden="true"].flyout {
	display: none;
}

.flyout.fadeup {
	animation: reveal .5s ease-in-out;
	animation-fill-mode: forwards;
}
.flyout.fadeup-reverse {
	animation: unreveal .5s ease-in-out;
	animation-fill-mode: forwards;
}

[data-shufflein] > * {
	opacity: 0;
}
[data-shufflein].fadeup-fallbk > * {
	opacity: 1;
}
.fadeup {
	animation: fadeup 1s ease-in-out;
	animation-fill-mode: forwards;
	animation-delay: .25s;
}


.masthead {
	@media( max-width: 79.9375em ) {
		.flyout.fadeup {
			animation: none;
		}
		.flyout.fadeup-reverse {
			animation: none;
		}
	}
}


@keyframes fadeup {
	0% {
		opacity: 0;
		transform: translateY(3.5em);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes fadedown {
	0% {
		opacity: 1;
		transform: translateY(0);
	}
	100% {
		opacity: 0;
		transform: translateY(3.5em);
	}
}


@keyframes reveal {
	0% {
		opacity: 0;
		margin-top: 15px;
	}
	100% {
		opacity: 1;
		margin-top: 0;
	}
}

@keyframes unreveal {
	0% {
		opacity: 1;
		margin-top: 0;
	}
	100% {
		opacity: 0;
		margin-top: 15px;
	}
}