.recipe-carousel {

	&:not(.flickity-enabled) {
		border-radius: 0.75em;
		overflow: hidden;
	}

	.flickity-viewport {
		border-radius: 0.75em;
	}

	&.central-primary,
	.central-primary & {
		align-self: flex-start;
	}

	img {
		display: block;
		border-radius: 0;
		margin-right: 1rem;
	}

	// Flickity Style Overrides
	.flickity-button {
    width: 3.5rem;
    height: 3.5rem;
		background: transparent;
		border-radius: 0;

		.flickity-button-icon {
			fill: $white;
			width: 3.5rem;
			height: 3.5rem;
			left: 50%;
			top: 50%;
			transform: translate3d(-50%, -50%, 0);
		}

		&.flickity-prev-next-button {
			top: 50%;

			.flickity-button-icon {
				left: 50%;
				top: 50%;
				transform: translate3d(-50%, -50%, 0);
			}

			&.previous {
				left: 0;
			}
			
			&.next {
				right: 0;
			}
		}
	}

	@media(min-width: 80em) {

		.flickity-button {
	    width: 4.5rem;
	    height: 4.5rem;

			&.flickity-prev-next-button {

				.flickity-button-icon {
					width: 4.5rem;
					height: 4.5rem;
				}
			}
		}
	}
}
