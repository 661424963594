.product-teaser {
	height: 100%;
	border-radius: 0.75rem;
	background-color: $eb-blue;
	background-size: cover;
	padding-bottom: 4rem;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	overflow: hidden;
	position: relative;

	.teaser-carousel & {
		margin-left: .3rem;
		width: 100%;
		height: 30em;
	}

	@media(min-width: 43em) {
		.teaser-carousel & {
			margin-left: 1.5rem;
			width: calc((100% / 2) - .75rem);
		}
	}

	@media(min-width: 60em) {
		padding-bottom: 2rem;
	}

	@media(min-width: 80em) {
		padding-bottom: 4rem;

		.teaser-carousel & {
			width: 21.5625vw;
			height: 26.0417vw;
			max-width: 25.875rem;
			max-height: 31.25rem;
			margin-left: 1.5rem;
		}
	}

	p.h5 {
		@extend h5;
		margin: 0 0 0.5rem;
		color: white;
	}

	p {
		margin: 0;
		line-height: 1.5;
	}

	.teaser-copy {
		padding: 0 4rem;
		margin-bottom: 3rem;
    width: 100%;
    
		> * {
			color: #fff;
		}

		.teaser-carousel & { 
			margin-bottom: 1rem;
		}
	}

	.btn-secondary {
		&:hover {
			background-color: $eb-red;
			border-color: $eb-red;
		}
	}

	@media(min-width: 60em) {
		padding-bottom: 2rem;

		.teaser-copy {
			padding: 0.5rem 2rem 0;
			margin-bottom: 1.5rem;
		}
	}

	@media(min-width: 80em) {
		padding-bottom: 4rem;

		.teaser-copy {
			padding: 0.5rem 4rem 0;
			margin-bottom: 3rem;
		}
	}
}

a.product-teaser {
	transition: background-color .2s ease-in-out;

	&::before,
	&::after {
		content: '';
		z-index: 0;
		background-color: $eb-blue;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}

	&::before,
	&::after,
	.teaser-inner {
		opacity: 1;
		transition: opacity .2s ease-in-out;
	}

	&::after {
		opacity: 0;
		background-color: transparent;
		background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75));
	}

	.teaser-inner {
		z-index: 1;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		height: 100%;
	}

	img {
		transition: opacity .15s linear;
	}

	&:hover {
		background-color: transparent;

		&::before,
		.teaser-inner img {
			opacity: 0;
		}

		&::after {
			opacity: 1;
		}

		.teaser-inner {
			transition-delay: 0;
		}
	}
}

.product-info {
	
	.lede {
		
		ul {
			display: inline-block;
			list-style-type: none;
		}
		
		li {
			text-align: left;
			position: relative;
			padding-left: 20px;
			&:before {
				content: "•";
			    color: #e52e2e;
			    display: inline-block;
			    position: absolute;
			    top: 1px;
			    left: 0;
			    margin-right: 0.5em;
			    font-size: 0.8em;
			}
		}
	}
}