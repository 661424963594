.home-hero {
	height: 0;
	padding-bottom: 150%;
	position: relative;
	z-index: 4;


	figure {
		height: 0;
		padding-bottom: 100%;
		position: absolute;
		right: 0;
		left: 0;
		top: -12.1909%;

		.carton-btm,
		.carton-top {
			//position: absolute;
			display: block;
			width: 100%;
		}

		.carton-btm {
			// Lots of precision to avoid weird gaps
			top: 9.3189964157706%;
		}

		figcaption {
			position: absolute;
			top: 10%;
			left: 0;
			right: 0;
			padding-left: 06.6667%;
			padding-right: 06.6667%;
		}

		.grid-list {
			grid-gap: .75rem;
		}

		@media(min-width: 60em) {			
			.carton-btm {
				top: 14.7722535389969%;
			}
		}
	}

	.detail {
		position: relative;
		z-index: 5;
		position: absolute;
		top: 42.5%;
		left: 50%;
		transform: translateX(-50%);
		width: 100%;

		.inner {
			max-width: 60rem;
			margin: 0 auto;
		}

		.section-hed {
			margin-top: 0;
		}

		.lede {
			margin-bottom: 2rem;
		}

		.note {
			position: absolute;
			top: -3em;
			right: 50%;
			transform: translateX(50%);
			font-style: italic;
		}
	}

	
	@media(max-width: 22.5em) {
		.detail {
			.section-hed {
				font-size: 2em;
			}

			.lede {
				font-size: 1.2em;
			}
		}
	}


	@media(min-width: 30em) {
		padding-bottom: 100%;

		.detail {
			top: 50%;
		}
	}	

	@media(min-width: 42.5em) {
		padding-bottom: 80%;

		.detail .section-hed {
			font-size: 4em;
		}
	}
	@media(min-width: 60em) {
		figure {
			padding-bottom: 63.125%;

			figcaption {
				top: 10.7656%;
				padding-left: 0;
				padding-right: 0;
			}

			.grid-list {
					grid-gap: 1.5rem;
			}
		}

		.detail {
			top: 55%;

			.section-hed {
				font-size: 6em;
			}

			.note {
				right: 5%;
				transform: none;
			}
		}
	}
	
	@media(min-width: 80em) {
		padding-bottom: 63.125%;

		figure {
			.grid-list {
				padding-left: 0;
				padding-right: 0;
			}
		}

		.detail {
			top: 63.125%;
		}
	}
	
	
	.section-hed {
		&::before {
			content: "*when compared to ordinary eggs.";
			display: block;
			font-family: $serif;
			text-transform: none;
			color: black;
			font-weight: normal;
			font-size: 15px;
			position: relative;
			top: -35px;
			
			@media(min-width: 60em) {
				top: -35px;
				font-size: 17px;
			}
		}
	}
}



html[lang|=es] {
	
	.home-hero {
	
		.section-hed {
			
			&::before {
				content: "*Cuando se compara con huevos ordinarios.";				
			}		
		}	
	}
}


