/* Typography Styles
 ------------------------------------------------------------------------------
*/

html {
	-ms-text-size-adjust: 100%;
  	-webkit-text-size-adjust: 100%;
  	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body,
html {
	text-rendering: optimizeLegibility;
	font-family: $serif;
	font-weight: 400;
	font-style: normal;
	margin: 0;
	padding: 0;
}

body {
	text-align: center;
}

/* default header styles */
h1, h2, h3, h4, h5, h6 {
	padding: 0;
    margin: 0;
	color: $eb-blue;
	line-height: 1;
	text-transform: uppercase;
	//font-family: $sans;
	font-family: $sans-bold;
    line-height: 1;
  
  .inverted & {
    color: $white;
  }
}

h1, .h1 {
	font-size: 2.5em; // 40px
    letter-spacing: -0.01em;

	@media(min-width: 60em) {
		font-size: 6em; // 96px
	}
}

h2, .h2 {
	font-size: 2.125em; // 34px
  letter-spacing: -0.01em;

	@media(min-width: 60em) {
		font-size: 4em; // 64px
	}
}
		
h3, .h3 {
  font-size: 1.75em; // 28px
  letter-spacing: -0.005em;

	@media(min-width: 60em) {
		font-size: 3em; // 48px
	}
}

h4, .h4 {
	font-size: 1.5em; // 24px
    letter-spacing: -0.000417em;

	@media(min-width: 60em) {
		font-size: 2em; // 32px
		letter-spacing: -0.000625em;
	}
}

h5, .h5 {
	font-size: 1.25em; // 20px
    line-height: 1.2;

	@media(min-width: 60em) {
		font-size: 1.5em; // 24px
		line-height: 1.166667;
	}
}

h6, .h6 {
		font-size: 1.125em; // 18px
    line-height: 1.222222;

    @media(min-width: 60em) { 
			font-size: 1.25em; // 20px
			line-height: 1.2;
    }
}

a {
	text-decoration: none;
	cursor: pointer;
	color: $eb-red;

	&:visited {
		color: $maroon;
	}

	&:active, &:focus, &:hover {
		color: $drk-red;
		text-decoration: none;
		cursor: pointer;
	}
}

b, strong {
  font-weight: 700;
}

blockquote {
	color: $eb-blue;
	line-height: 1.1;
	text-align: center;
	border: solid $xlt-grey;
	border-width: 4px 0;
	padding: 1em 0;
	margin: 1.5em 0;
	text-transform: uppercase;
	font-family: $sans-bold;
	font-size: 28px;

	@media(min-width: 60em) {
		font-size: 32px;
	}

	@media(min-width: 80em) {
		.post-body & { 
			margin-left: -9.125rem;
			margin-right: -9.125rem;
		}
	}
}

hr {
	margin: 3em 0;
  height: 2px;
  background: $xlt-grey;
  border: 0;
}

p.important-text {
  margin-top: 0;
  color: $eb-red;
  font-family: $sans-bold;
  font-size: 2.4em;
  //font-weight: 800;
}

.blue-text, .promo-block strong, .promo-block b {
  color: $eb-blue;
  font-family: $sans-bold;
  //font-weight: 800;
  text-transform: uppercase;
}

.title {
  @extend .h2;
  font-family: $sans-bold;
  //font-weight: 800;
  color: $eb-blue;
}
/*
 * ------------------
 * START POST BODY 
 */
.content,
.post-body {

	/* wysiwyg styles here */
	color: $xdrk-grey;
	line-height: 1.5;
  text-align: left;
  max-width: 53.25rem;
	margin: 0 auto;

	p, ul, ol,
	.post-lede {
		font-size: 1.125em; // 18px

		@media(min-width: 60em) {
			font-size: 1.25em; // 20px
		}
	}

	p, ul, ol,	
	.post-lede,
	.content-lede, {
		margin: 1.25rem auto;

		+ h1, + h2, + h3, + h4, + h5, + h6 {
			@media(min-width: 60em) {
				margin-top: 4rem; // 64px
			}
		}
	}

	.content-lede p,
	.post-lede p {
		font-size: 1.166667em; // 21px
		line-height: 1.4;
		margin: 1.5em 0;

		@media(min-width: 60em) {
			font-size: 1.2em; // 24px
		}
	}

	ul,
	ol {
		list-style: none;
		line-height: 1.3;

		li {
			margin: 1.25em 0;
			position: relative;
			padding-left: .85em;
			&::before {
				content: "•";
				color: $eb-red;
				display: inline-block;
				position: absolute;
				top: 1px;
				left: 0;
				margin-right: .5em;
				font-size: .8em;
			}
			li {
				font-size: .75em;
			}
		}
	}

	a:not([class]) {
		font-weight: 700;
	}
}
/*
 * END POST BODY 
 * ------------------
 */

.content-area{
  
  img { 
  	border-radius: .625em; 
  	display: block;
  	margin-left: auto;
  	margin-right: auto;

		@media(min-width: 71.5rem) {
			// Not 100% sure about this
			width: 100%;
			max-width: 71.5rem; // 1144px
		}
  }

  * + * {
    margin-top: 1em;
  }
  
  > hr { 
    margin: 2em auto;
    max-width: 1144px; 
    height: 8px;
  }

  @media(min-width: 60em) {
    > hr { 
      margin: 4em auto;
    }
  }
}

.content img,
.round-corners {
	border-radius: .625em;
}

/* Global type classes
------------------------- */
.hero-hed,
.home-hed {
	font-size: 39px;
	
	@media(min-width: 45em) {
		font-size: 82px;
		margin: 0 auto;
	}

	@media(min-width: 70em) {
		font-size: 128px;
		margin: 0 auto;
	}

	margin: -100px auto 0;
	line-height: 1;
}

/*
.hero-hed,
.home-hed {
	font-size: 39px;
	
	@media(min-width: 45em) {
		font-size: 71px;
		margin: -1.3em auto 0;
	}

	@media(min-width: 70em) {
		font-size: 102px;
		margin: -.75em auto;
	}

	margin: -100px auto 0;
	line-height: 1;
}
*/

.section-hed,
.lede {
		margin: 0 5%;
}
.section-hed {
	font-size: 2.5em; // 40px
	margin-top: 1.25em;
	line-height: 1.1;
	margin-bottom: .175em;
	letter-spacing: -.01em;

	.content-header & {
		margin-top: 0;
	}

	@media(min-width: 60em) {
		font-size: 6em; // 96px
		max-width: 87.5rem; // 1400px
		margin-left: auto;
		margin-right: auto;

		.content-header & {
			font-size: 4em; // 64px
		}
	}

	@media(min-width: 80em) {
		.content-header & {
			font-size: 6em; // 96px
		}
	}
	
	sup {
		position: relative;
		top: -1.8em;
		font-size: .3em;
		line-height:0;
		vertical-align: baseline;
	}
}

.hero-hed {
	color: $eb-blue;
}

.callout-hed,
.content-hed,
.section-hed-sm {
	font-size: 2.125em; // 34px
	letter-spacing: -0.01em;
	margin-bottom: .5em;

	@media(min-width: 60em) {
		font-size: 4em; // 64px
		margin-bottom: .25em;
	}
}

.callout-hed,
.content-hed {
	margin-top: 0;
}

.section-hed-sm {
	margin: 0;
	
	@media(min-width: 60em) {
		margin-top: 1.65em;
		margin-bottom: 1em;
	}
}

.sub-hed {
	font-size: 1.75em; // 28px
	font-weight: 700;
	letter-spacing: -0.005em;
	margin: .5em 0 0 0;

	@media(min-width: 60em) {
		font-size: 3em; // 48px
	}
	
	sup {
		position: relative;
		top: -1.2em;
		font-size: .4em;
		line-height:0;
		vertical-align: baseline;
	}
}

.article-hed,
.product-hed,
.recipe-hed {
	letter-spacing: -0.01em;
	max-width: 22em;
	margin: .275em auto;
}


.article-hed {
	font-size: 2.125em; // 34px
	letter-spacing: -0.01em;
	max-width: 22em;
	margin: .275em auto;

	@media(min-width: 60em) {
		font-size: 4em; // 64px
	}
}

.product-hed,
.recipe-hed {
	font-size: 2.125em; // 34px

	@media(min-width: 60em) {
		font-size: 4em; // 64px
	}
}


.innernav-hed,
.result-hed,
.results-hed {
	font-size: 1.5em; // 24px
  letter-spacing: -0.000417em;
	text-align: center;
	margin: 1em 0;
	
	@media(min-width: 60em) {
		font-size: 2em; // 32px
		letter-spacing: -0.000625em;
		text-align: left;
		margin-right: -.75em; // Prevents wrapping due to percentage padding
	}
}

.result-hed,
.results-hed {
	@media(min-width: 60em) { 
		text-align: center;
		margin-right: 0;
	}
}

.result-hed {
	@media(min-width: 60em) { 
		margin: .45em 0 .5625em;
	}
}

.results-hed {
	@media(min-width: 60em) { 
		margin: 1.5em 0 .5625em;
	}
}

.label-hed,
.legend-hed {
	font-size: 1.125em; // 18px
	font-weight: bold;
	@media(min-width: 60em) {
		font-size: 1.25em; // 20px
	}
}

.title-label {
  color: $xlt-blue;
	text-transform: uppercase;
	font-family: $sans;
  font-size: 1.2em;
  font-weight: 400;
  margin-bottom: 0.45em;
  @media(min-width: 60em) {
    font-size: 1.5em; // 24px
  }
}

.lede {
	font-size: 1.5em; // 24px
	line-height: 1.3;
	color: $xdrk-grey;
	
	@media(min-width: 60em) {
		font-size: 2em; // 32px

		&.lede-sm {
			font-size: 1.5em; 
		}
	}
}
.hero {
	background-color: #fff;

	svg.scoop {
		fill: #fff;
	}

	.lede {
		margin-bottom: 20px;
		margin: 0 auto .8em auto;
		@media(min-width: 60em) {
			max-width: 750px;
		}
	}
}
.product-info {
	.lede {
		font-size: 1.125em; // 18px
		line-height: 1.5;

		@media(min-width: 60em) {
			font-size: 1.5em; // 24px
		} 
	}
}
[class*="-hed"],
[class*="-hed"] a,
.lede strong {
	color: $eb-blue;
	line-height: 1;
	text-transform: uppercase;
	font-family: $sans-bold;
}

[class*="-hed"],
[class*="-hed"] a {
	em {
		font-style: normal;
		text-transform: none;
	}
}

.lede strong {
	font-size: 0.875em;
}

/* Utility classes for paragraph type sizes */
/* (not sure if these will be used, they're here for reference) */

p.copy-xl, .copy-xl p {
	font-size: 1.5em; // 24px

	@media(min-width: 60em) {
		font-size: 2em; // 32px
	}
}

p.copy-lrg, .copy-lrg p {
	font-size: 1.3125em; // 21px

	@media(min-width: 60em) {
		font-size: 1.5em; // 24px
	}
}

.copy-normal {
	font-size: 1.125em; // 18px

	@media(min-width: 60em) {
		font-size: 1.25em; // 20px
	}
}

.copy-sm {
	font-size: 0.9375em; // 15px

	@media(min-width: 60em) {
		font-size: 1em; // 16px
	}
}