.disqus {

	&__intro {
		margin-bottom: 1rem;

		p {
			margin-top: 0;
		}
	}

	.recipe-hed {
		margin-top: 1.25em;
	}
	
	#disqus_thread { 
		a {
			color: $eb-blue;
		}
	}

	@media(min-width: 60em) {

		&__intro {
			font-size: 18px;
		}

		&__inner {
			display: flex;
			max-width: 960px;

		 > * {
				width: 100%;
			}
		}

		&__main {
		//	max-width: 60%;
		}

		&__extra {
	 //		max-width: 40%;
		}
	}
}

