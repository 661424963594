.bg-white {
  background: $white;
}

.bg-grey {
  background: $xlt-grey;
}

.bg-blue {
  background: $eb-blue;
  color: $white;
}

// .bg-red{
//   background: $eb-red;
// }