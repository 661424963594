input, textarea {
	font-family: $serif;
	font-size: 1.125em;
	padding: .625em 1em .6em .888em;
	border: 1px solid $drk-grey;
  border-radius: .25em;
  
  &:focus{
    border: 2px solid $lt-blue;
    box-shadow: 0 0 8px 1px rgba(0, 130, 202, .25);
  }
}

input::placeholder {
	color:  $drk-grey;
	font-size: .85em;
}

input[type="search"],
input[type="email"],
input[type="text"] {
	min-width: 250px;
	appearance: none;
}

label+input[type="submit"] {
	margin-left: .5em;
}


.freeform-row {
	input[type="checkbox"],
	input[type="radio"] {
	position: relative;
	left: auto;
	}
	
}


input[type="checkbox"],
input[type="radio"] {
	position: absolute;
	left: -99999px;
	
	+ label {
		padding-left: 1.4em;
		position: relative;
		color: $xdrk-grey;
		color: $black;
		display: inline-block;
		margin-bottom: 1em;

		@media(min-width: 60em) {
			font-size: 1.125em;
		}

		&::before {
			content: '';
			position: absolute;
			top: 0.2em;
			left: 0;
			width: 12px;
			height: 12px;
			border: 2px solid $drk-grey;

			@media(min-width: 60em) {
				width: 14px;
				height: 14px;
			}
		}
	}
 
	&:checked {
		+ label {
			color: $eb-blue;

			&::before {
				background-color: $eb-blue;
			}
		}
	}

	&:focus {
		+ label {
	    outline: -webkit-focus-ring-color auto 5px;
		}
	}
}

input[type="checkbox"] + label::before {
	border-radius: 4px;
}

input[type="radio"] + label::before {
	border-radius: 50%;
}

input[type="checkbox"]:checked + label::before {
	border-color: $eb-blue;
	background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2211%22%20height%3D%2210%22%20viewBox%3D%220%200%2011%2010%22%3E%0A%20%20%3Cpath%20id%3D%22Path_630%22%20data-name%3D%22Path%20630%22%20d%3D%22M1554.352%2C76a1%2C1%2C0%2C0%2C1-.707-.293l-3-3a1%2C1%2C0%2C0%2C1%2C1.414-1.414l2.185%2C2.184%2C5.308-7.077a1%2C1%2C0%2C1%2C1%2C1.6%2C1.2l-6%2C8a1%2C1%2C0%2C0%2C1-.729.4C1554.4%2C76%2C1554.376%2C76%2C1554.352%2C76Z%22%20transform%3D%22translate(-1550.353%20-66)%22%20fill%3D%22%23fff%22%2F%3E%0A%3C%2Fsvg%3E%0A');
	background-repeat: no-repeat;
	background-size: 11px 11px;
	background-position: 50% 50%; 
}

input[type="radio"]:checked + label::before {
	box-shadow: inset 0 0 0 2px #fff;
}

textarea {
  width: 100%;
}

.label-hed {
	display: block;
	margin-bottom: .25rem;
}

label {
	display: block;
	font-size: 1em;
	font-weight: 700;
	line-height: 1.5;
	color: $eb-blue;
	margin-bottom: .375em;
	text-align: left;
}

label.label-reset {
	margin-bottom: 0;
	font-weight: 400;
	line-height: normal;
}

.seamless-input {
	display: flex;

	label:first-of-type {
		border-right-width: 0;

		input {
			border-radius: .25em 0 0 .25em;

			&:focus {
				box-shadow: inset 0 0 3px $drk-grey;
				border: 1px solid $drk-grey;
				outline: none;
			}
		}

		&+input[type="submit"],
		&:first-of-type+button {
			border: solid $drk-grey;
			border-width: 1px 1px 1px 0;
			margin: 0 0 0 -1px;
			background: #fff;
		}
	}
}

.seamed-input {
	display: flex;
	justify-content: stretch;
	align-self: flex-end;

	input {
		margin-top: auto;
		flex-grow: 2;
		margin-right: .5em;
	}
}

fieldset.formshift {
	border: none;
	padding: 0 5%;
	margin: 1.5em auto;
	text-align: left;

	legend {
		margin-bottom: .75em;
	}

	> *:not(legend) {
		display: block;
		padding-right: 1em;
		margin: .25em 0;
		text-align: left;
  }

	@media(min-width: 60em) {
		padding-left: 310px;
		position: relative;
		display: inline-block;

		legend {
			width: 300px;
			margin-bottom: .35em;
			position: absolute;
			left: 0;
			top: .35em;
		}

		label {
      margin-bottom: .25em;
		}

		> *:not(legend) {
			display: inline-block;
		}
	}
}

/*
 * Custom Select Element
 * https://github.com/filamentgroup/select-css
 */

/* class applies to select element itself, not a wrapper element */
.select-css {
	display: block;
	font-size: 1.125em;
	font-family: $serif;
	color: $drk-blue;
	line-height: 1.3;
	padding: .625em 2em .6em .888em;
	width: 100%;
	max-width: 100%; /* useful when width is set to anything other than 100% */
	box-sizing: border-box;
	margin: 0;
	border: 1px solid $drk-grey;
	border-radius: 0.25em;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-color: #fff;
	background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%227.09%22%20viewBox%3D%220%200%2012%207.09%22%3E%0A%20%20%3Cpath%20id%3D%22arrow%22%20d%3D%22M12%2C1.09%2C10.91%2C0%2C6%2C4.91%2C1.09%2C0%2C0%2C1.09l6%2C6Z%22%20fill%3D%22%235a5666%22%2F%3E%0A%3C%2Fsvg%3E%0A');
	background-repeat: no-repeat;
	/* arrow icon position (1em from the right, 50% vertical) */
	background-position: right .7em top 50%;
	/* icon size */
	background-size: .65em auto;
}

/* Hide arrow icon in IE browsers */
.select-css::-ms-expand {
	display: none;
}

/* Hover style */
.select-css:hover {
	border-color: #888;
}

/* Focus style */
.select-css:focus {
	border-color: #aaa;
	/* It'd be nice to use -webkit-focus-ring-color here but it doesn't work on box-shadow */
	box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
	box-shadow: 0 0 0 3px -moz-mac-focusring;
	color: #222; 
	outline: none;
}

/* Disabled styles */
.select-css:disabled, .select-css[aria-disabled=true] {
	color: $drk-grey;
	background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%227.09%22%20viewBox%3D%220%200%2012%207.09%22%3E%0A%20%20%3Cpath%20id%3D%22arrow%22%20d%3D%22M12%2C1.09%2C10.91%2C0%2C6%2C4.91%2C1.09%2C0%2C0%2C1.09l6%2C6Z%22%20fill%3D%22%235a5666%22%2F%3E%0A%3C%2Fsvg%3E%0A');
}

.select-css:disabled:hover, .select-css[aria-disabled=true] {
	border-color: $drk-grey;
}

/*
 * Multi-Select Dropdown
 */

.multiselect-dropdown {
	position: relative;
	z-index: 10;

	&.multiselect-open {
	  filter: drop-shadow(0 4px 8px rgba(0, 31, 96, 0.15));
	}
}

.multiselect-toggle {
	appearance: none;
	border: none;
	display: block;
	border: 1px solid $drk-grey;
	border-radius: 6px;
	font-family: $serif;
	font-size: 1.125em;
	padding: 0.625em 1em 0.6em 0.888em;
	width: 100%;
	text-align: left;
	background-color: #fff;
	background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%227.09%22%3E%3Cpath%20d%3D%22M12%201.09L10.91%200%206%204.91%201.09%200%200%201.09l6%206z%22%20fill%3D%22%235a5666%22%2F%3E%3C%2Fsvg%3E');
	background-repeat: no-repeat;
	/* arrow icon position (1em from the right, 50% vertical) */
	background-position: right .7em top 50%;
	/* icon size */
	background-size: .65em auto;

	&.toggle-open {
		border-radius: 6px 6px 0 0;
		border-bottom-color: $xlt-grey;
		background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2012%207.09%22%3E%3Cpath%20d%3D%22M0%206l1.09%201.09L6%202.18l4.91%204.91L12%206%206%200z%22%20fill%3D%22%235a5666%22%2F%3E%3C%2Fsvg%3E');


		+ .multiselect-options {
			height: auto;
		}
	}
}

.multiselect-inner {
	background-color: #fff;
	border: 1px solid $drk-grey;
	border-top: none;
	border-radius: 0 0 6px 6px; 
	overflow: hidden;

	@media(min-width: 60em) {
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
	}
}

.multiselect-options {
	height: 0;
}

.multiselect-option {

	input + label {
		margin-bottom: 0;
		display: block;
		padding: 1em 1em 1em 2.8em;
		font-weight: 400;
		line-height: normal;

		&::before {
			top: 1.1875em;
			left: 1em;
		}
	}

	input:focus + label {
		background-color: $xlt-grey;
	}

	& + & {
		border-top: 1px solid $xlt-grey;
	}

	&:hover {
		background-color: $xlt-grey;
	}	
}
































































