.steps-carousel {
	$this: &;

	max-width: 71.5rem;
	margin: 0 auto;
	padding-top: 110px;

	.flickity-viewport {
	  transition: height 0.3s ease;
	}

	.flickity-prev-next-button {
		width: 100px;
		height: 100px;
		background-color: transparent;
		top: -105px;
		left: 50%;
		margin: 0;

		.flickity-button-icon {
			width: 80%;
			height: 80%;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			color: $xdrk-grey;
		}
	}

	.flickity-prev-next-button.previous{
		transform: translate(-100%, 0);
	}

	.flickity-prev-next-button.next{
		transform: translate(0);
	}

	@media(min-width: 60em) {
		&__slide {
			width: 100%;
			margin: 0;
		}

		&__slides {
			&.flickity-enabled {
				border: 1px solid $lt-grey;
			}
		}
	}
}

