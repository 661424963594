.teaser-carousel {
	--btn-size: 5rem;

	position: relative;
	padding-bottom: 5.75rem;
	z-index: 5;	

	@media(min-width: 60em) {
		margin: 0 -5.2083333% 0;
		padding-left: 5.2083333%;
	}

	// Flickity Style Overrides
	.flickity-button {
    &:disabled {
    	display: none;
    }
	}

	.flickity-button {
		background: transparent;
		border-radius: 0;
    width: var(--btn-size);
    height: var(--btn-size);

    .flickity-button-icon {
    	fill: $eb-blue;
    	width: var(--btn-size);
    	height: var(--btn-size);
    	top: 0;
    }

		&.flickity-prev-next-button {
			transform: none;

			&.next,
			&.previous {
				top: auto;
				right: auto;
				bottom: 0;
				left: auto;
			}

			&.next {
				right: 50%;
				transform: translateX(100%);
			}

			&.previous {
				left: 50%;
				transform: translateX(-100%);
			}

			.flickity-button-icon {
				left: 0;
			}
		}
	}
}