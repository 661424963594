.player {
	position: relative;
	background-position: top center;
	background-repeat: no-repeat;
	background-size: contain;
	
	svg {
		width: 96px;
	    height: 96px;
	    position: absolute;
	    top: 50%;
	    left:50%;
	    transform: translate(-50%, -50%);
	    cursor: pointer;
	    
	    path {
		    fill: inherit;
	    }
	    
	    &:hover,
	    &:focus,
	    &:active {
		    
		    fill: red !important;
	    }
		
	}
}