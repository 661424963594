
.tablist {
  fieldset {
    border: 0;
    padding: 0;
  }

  .control {
    display: inline-block;
  }

  input[type="radio"] + label {
    padding: 0;
    margin: 0;
    display: block;

    &::before,
    &::after {
      content: none;
    }
  }

  input[type="radio"] + label:hover {
    cursor: pointer;
  }

  .panel {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;

    &.active {
      clip: auto;
      height: auto;
      overflow: visible;
      position: static;
      width: auto;
    }
  }
  
  // Modifier to stack panels on mobile
  &--stack {
    @media(max-width: 767px) {
      .controls {
        display: none;

        ~ div.panel { // This selector is overspecified so it overrides the theme class without resorting to !important.
          padding: 0;
          position: relative;
          clip: none;
          width: auto;
          height: auto;
          margin: 0;
        }
      }
    }
  }
}






