/*
 * this is the flex box holy albatross: http://www.heydonworks.com/article/the-flexbox-holy-albatross-reincarnated
 */
.flex-component {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  --margin: 1rem;
  --modifier: calc(60rem - 100%);
  margin: calc(var(--margin) * -1);

  &.join-newsletter {
    margin: 0;

    @media(min-width: 60em) {
      margin: calc(var(--margin) * -1);
    }
  }

  > * {
    flex-grow: 1;
    flex-basis: calc(var(--modifier) * 999);
    margin: var(--margin);
  }

  .sub-hed {
    margin-top: 0;
  }

  .formshift {
    display: block;
    padding: 1em 1em 0;
    margin: 0;
    text-align: center;

    .legend-hed {
      width: 100%;
      position: static;
      display: block;
      margin-bottom: 0;
      white-space: nowrap;
    }

    @media(min-width: 60em) {
      padding: 1em;

      > :not(legend) {
        text-align: center;
        margin: 0;
      }

      > div {
        display: inline-block;
      }
    }
  }

  label {
    display: inline-block;
  }
}