.footer-social {
	max-width: 500px;
	margin-inline: auto;
	margin-top: 3rem;

	&__hed {
		margin-bottom: 1.5rem;
		font-size: 32px;
	}

	@media(min-width: 60em) {
		max-width: 600px;
	}
}