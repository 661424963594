
.filter-area-title {
  margin: 4rem 0 4.5rem;
}

.content-card {
  margin-bottom: 2rem;
  
  > * {
    width: 100%;
    display: block;
    text-align: center;
    margin-bottom: 0.5em;
  }

  &--article {
    .content-card, h3, h4, p {
      text-align: left;
    }

    .content-card-title {
      font-family: $serif;
      text-transform: none;
      margin-top: .5em;
      margin-bottom: .25em;
      padding-inline: 0;
    }

    p {
      margin-top: 0;
      margin-bottom: .5rem;
    }

    .more {
      font-size: 1em;
      text-align: left;
    }

    @media(min-width: 60em) {
      margin-bottom: 0;
    }
  }
}

.content-card-media{
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  height: 44vw;

  &:before {
    content: '';
    background: $black;
    position: absolute;
    top:0; 
    bottom:0; 
    left:0; 
    right: 0;
    opacity: 0;
    transition: opacity 0.25s ease-out;
  }

  &:hover {
    &:before {
      opacity: 0.25;
    }
  }

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
  
  .icon {
    width: 96px;
    height: 96px;
    position: absolute;
    top: 50%;
    left:50%;
    transform: translate(-50%, -50%);
  }

  .content-card--article & {
    height: unset;
    border: 1px solid $lt-blue;

    .frame {
      --w: 1120;
      --h: 666;
    }
  }
}

.content-card-title {
  padding: 0 .78rem;
}


@media(min-width: 60em) {
  .filter-area {
    max-width: 1728px;
    margin: 0 auto;
    &__results {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: space-around;
      
      > * {
        margin: 0 0 3em;
        width: calc((100% / 3) - 16px); // 24px gutters
      }
    }
  }
  .content-card-media {
    height: 325px;
  }
}

@media(min-width: 80em) { 
  .filter-area__results--4-up { 
    justify-content: space-around;

    > * {
      width: calc((100% / 4) - 18px); // 24px gutters
    }

    .content-card-media {
      height: 240px;
    }
  }
}
