.news-alert {
  order: 0;
  position: relative;
  z-index: 10;
  color: $white;
  display: block;
  background: #E42525;

  @media(min-width: 65em) {
    padding: .5em 0;
  }
  
  .news-hed {
    color: #fff;
    display: block;
    margin: 0;
    padding-top: 1em;
    font-size: 1.1em;

    @media(min-width: 65em) {
      font-size: 1.4em;
      padding-top: 0;
    }
  }

  p {
    margin: .25em 1.5em;
    font-weight: 600;

    @media(min-width: 65em) {
      font-size: 1.15em;
    }
  }

  .btn {
    font-size: 14px;
    margin: .3em 0 .75em 0;

    @media(min-width: 65em) {
      margin: 0 100px 0 0;
    }
  }

  .news-close {
    position: absolute;
    right: .5em;
    top: .5em;
    height: 22px;
    width: 22px;
    background: none;
    padding: 0;
    border: none;

    svg {
      width: 100%;
      fill: #fff;
    }
  }

  + .masthead{
    top: 125px;
  }

  @media(min-width: 65em) {
    display: flex;

    .news-close {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 7.5em;
      background: none;
      border: none;
    }
    
    + .masthead{
      top: 40px;
    }
  }
}