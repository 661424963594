.product-header {
  $this: &;

  --hed-size: 28px;
  --subhed-size: 20px;

  background-color: $eb-blue;

  &__inner {
    padding-top: 4.5rem;
    //padding-bottom: 6.5rem;
  }

  .product-hed {
    font-size: var(--hed-size);
  }

  &, h1, h2, h3 {
    color: $white;
  }

  &__media {
    padding-inline: 2rem;
    display: flex;
    align-items: flex-start;
  }

  &__detail {
    text-align: left;
  }

  &__content {
    margin-block: 2rem;
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
  }

  &__content-b,
  &__content-a {
    width: 100%;
  }

  &__content-b {
    display: flex;

    > * {
      width: 100%;
      max-width: 50%;
      padding-right: 16px;
    }
  }
  
  &__content-set {
    h3 {
      font-size: var(--subhed-size);
      margin-bottom: .5rem;
    }

    ul {
      padding-left: 0.85em;
      line-height: 1.2;

      li {

        + li {
          margin-top: .5rem;
        }
      }
    }

    ul, p {
      font-size: 16px;

      @media(min-width: 60em) {
        font-size: 18px;
      }
    }

    // &--inline {
    //   ul {
    //     list-style: none;
    //   }

    //   li {
    //     &::after {
    //       content: ', ';
    //       display: inline;
    //     }

    //     display: inline;
    //   }
    // }
  }

  .tablist input[type=radio] + label {
    font-family: $sans-bold;
    text-transform: uppercase;
    border-radius: .25em;
    letter-spacing: .04em;
    border: 1px solid rgba(255,255,255,.25);
    font-size: 12px;
    padding: 0.714286em 1em;
    color: $white;
    border: 1px solid rgba(255,255,255,.75);
    background: none;
    white-space: nowrap;

    &:hover,
    &:focus {
      background: #fff;
      color: $eb-blue;
    }



    &[disabled] {
      background: rgba(255,255,255, .25)
    }
  }

  .control.active input[type=radio] + label {
    background: #fff;
    color: $eb-blue;
  }

  &__actions {
    padding-top: 1rem;
    text-align: center;
    margin-bottom: 3rem;

    .btn-secondary {
      margin-bottom: 10px;
      color: $eb-red;
      background: $white;

      .icon {
        fill: $eb-red;
      }

      .icon {
        transform: translate(4px, -4px);
      }

      &.toggle-open {
        .icon {
          transform: translate(4px, -4px) rotate(180deg);
        }
      }

      &:hover,
      &:focus,
      &.toggle-open {
        color: $white;
        background: transparent;

        .icon {
          fill: $white;
        }
      }
    }

    div[data-sc-id] {
      font-family: $serif;
      color: $white !important;
      margin-inline: 0;
      max-width: 100%;
      background-color: transparent !important;
      box-shadow: none !important;
      border: 1px solid $white !important;
    }

    .fa-chevron-right,
    .fa-chevron-left,
    .change-location-cta {
      color: $white !important;
    }

    .call-to-action {
      font-weight: normal !important;
    }

     .sc-smartsite-footer {
      justify-content: center !important;
      font-family: $sans !important;
      text-transform: uppercase;
      font-size: 8px;
      font-weight: 700;
     }

    .retailer-wrapper {
      max-width: 80% !important;
      min-width: none !important;
      // margin-inline: 0 !important;
    }


  }

  .controls {
    text-align: center;

    &:has(label) {
      border-bottom: 1px solid $white;
      padding-bottom: 1rem;
    }

    .control-label {
      font-family: $sans;
      text-transform: uppercase;
      margin-right: .25rem;
      display: none;
    }
  }

  @media(min-width: 60em) {
    --hed-size: 48px;

    &__content {
      flex-wrap: nowrap;
    }

    &__content-b,
    &__content-a {
      width: 100%;
      max-width: 60%;
    }

    &__content-b {
      max-width: 40%;
      display: block;

      > * {
        max-width: none;
        padding-right: 0;
      }
    }

    &__content-set {
      + #{ $this }__content-set {
        margin-top: 2rem;
      }
    }

    .tablist input[type=radio] + label {
      font-size: 14px;
    }

    .controls {
      text-align: left;
      display: flex;
      align-items: center;
      gap: .5rem;

      &:has(label) {
        border-bottom: 1px solid $white;
        padding-bottom: 1rem;
      }

      .control-label {
        display: inline-block;
      }
    }

    &__actions {
      text-align: left;
    }

    &__inner {
      display: flex;

      > * {
        width: 100%;
        max-width: 50%;
      }
    }

    &__detail {
      padding: 0 2.5rem;
    }
  }

   @media(min-width: 80em) {

   }
}