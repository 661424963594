.social-nav {
	display: flex;
	list-style: none;
	padding-top: 1.5em;
	justify-content: center;

	&__title{
	  color: $eb-blue;
	  font-size: 1.25em;
	  font-weight: 700;
	  text-transform: uppercase;

	  .inverted & {
	  	color: $white;
	  }
	}

	li {
		margin: 0 .5em;
    padding: 0;
    font-family: $sans;
	}

	a {
		display: flex;
	}

	.icon {			
		width: 1.5rem;
		height: auto;
		fill: $eb-blue;
    max-height: 1.5em;

    .inverted &{
      fill: $white;
    }
	}

	&.social-nav-lrg {

		li {
			margin: 0 .75em;
		}

		.icon {
			width: 2rem;
			max-height: 2rem;
		}
	}

	@media(min-width: 60em) {
		padding-top: 0;

		&.social-nav-lrg {
			
			li {
				margin: 0 1em;
			}

			.icon {
				width: 3rem;
				max-height: 3rem;
			}
		}				
	}
}

.share-cta {
	padding-top: .5em;
	margin: 1em auto;

	@media(min-width: 60em) {
		display: flex;
		margin-top: 1em;
		justify-content: center;
	}

	.share-hed {
		font-size: 1.1em;

		@media(min-width: 60em) {
			margin-top: 1em;
			margin-right: 1em;
			margin-bottom: 1em;
		}
	}
}