.food-service-page {
	main {
		padding-top: 142px;
	}

	@media(min-width: 65em) {
		main {
			padding-top: 98px;
		}
	}

	@media(min-width: 80em) {
		main {
			padding-top: 112px;
		}
	}
}