.hero-slider {
	--btn-size: 3rem;

	&.scooped {
		padding-bottom: 0;
		
		 .scoop {
				transform: rotate(180deg);
				bottom: -1px;
				top: auto;
			}
	}

		// Flickity Style Overrides
	.flickity-button {
		display: none;

    &:disabled {
    	display: none;
    }

    @media(min-width: 60em) {
    	display: block;
    }
	}

	.flickity-button {
		background: transparent;
		border-radius: 0;
    width: var(--btn-size);
    height: var(--btn-size);

    .flickity-button-icon {
    	fill: $white;
    	width: var(--btn-size);
    	height: var(--btn-size);
    	top: 0;
    }

		&.flickity-prev-next-button {
			transform: none;

			&.next,
			&.previous {
				top: auto;
				right: auto;
				bottom: calc(var(--btn-size) / 1.5);
				left: auto;
			}

			&.next {
				right: 50%;
				transform: translateX(100%);
			}

			&.previous {
				left: 50%;
				transform: translateX(-100%);
			}

			.flickity-button-icon {
				left: 0;
			}
		}
	}

	@media(min-width: 31.25em) {
		--btn-size: 5rem;
	}
}