.a11y-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.a11y-only.focusable:active,
.a11y-only.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

.text-center {
  text-align: center;
}

/* Spacing Utility Classes - (uncomment and use as needed for overrides) */

.marg-top-0 {
	margin-top: 0 !important;
}

.marg-btm-0 {
	margin-bottom: 0 !important;
}

.marg-top-8 {
	margin-top: .5rem !important;
}

.marg-btm-8 {
	margin-bottom: .5rem !important;
}

// .marg-top-16 {
// 	margin-top: 1rem !important;
// }

// .marg-btm-16 {
// 	margin-bottom: 1rem !important;
// }

// .marg-top-24 {
// 	margin-top: 1.5rem !important;
// }

.marg-btm-24 {
	margin-bottom: 1.5rem !important;
}

// .marg-top-32 {
// 	margin-top: 2rem !important;
// }

// .marg-btm-32 {
// 	margin-bottom: 2rem !important;
// }

// .marg-top-40 {
// 	margin-top: 2.5rem !important;
// }

// .marg-btm-40 {
// 	margin-bottom: 2.5rem !important;
// }

.marg-top-48 {
	margin-top: 3rem !important;
}

// .marg-btm-48 {
//  margin-bottom: 3rem;
// }

.marg-btm-32 {
  margin-bottom: 2rem !important;
}

.marg-top-32 {
  margin-top: 2rem !important;
}

.marg-btm-48 {
  margin-bottom: 3rem !important;
}

.marg-btm-64 {
  margin-bottom: 4rem !important;
}

.marg-btm-96 {
  margin-bottom: 6rem !important;
}

.marg-top-200 {
  margin-top: 4rem;
  
  @media(min-width: 60em) {
  margin-top: 12.5rem;
  }
}

.marg-40-72 { 
  // Identital to vertical margins set by .inset
  // Can be combined with .wrap-[x] to replace inset classes 
  // that are purely for vertical spacing
  margin-top: 2.5rem !important;
  margin-bottom: 4.5rem !important;
}

.pad-top-0 {
  padding-top: 0 !important;
}

.pad-top-16 {
  padding-top: 1rem !important;
}

.pad-rt-0 {
  padding-right: 0 !important;
}

.pad-btm-0 {
  padding-bottom: 0 !important;
}


/* Visibility Classes Based on Screen Width */
/* (Should not be used if content needs to be available to assistive technology) */

@media screen and (max-width: 0em), screen and (min-width: 60em) {
  .sm-screen-only {
    display: none !important; 
  } 
}

@media screen and (max-width: 60em) {
  .lrg-screen-only {
    display: none !important; 
  } 
}

.center {
	text-align: center
}
